import React from "react";

export const IconAutoReader = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16L10.879 13.121L8 16ZM10.879 13.121C11.1567 13.4033 11.4876 13.6279 11.8525 13.7817C12.2174 13.9355 12.6092 14.0156 13.0052 14.0172C13.4012 14.0189 13.7936 13.9421 14.1599 13.7914C14.5261 13.6406 14.8588 13.4188 15.1388 13.1388C15.4189 12.8588 15.6408 12.5262 15.7916 12.16C15.9425 11.7938 16.0193 11.4014 16.0177 11.0054C16.0162 10.6094 15.9362 10.2176 15.7825 9.85265C15.6287 9.48768 15.4043 9.15677 15.122 8.879C14.5579 8.32389 13.7973 8.01417 13.0059 8.0173C12.2145 8.02043 11.4564 8.33615 10.8967 8.89571C10.337 9.45526 10.0211 10.2133 10.0178 11.0047C10.0145 11.7961 10.324 12.5568 10.879 13.121V13.121ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
        stroke="#C2CFE0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const IconBarCode = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7H7V17H5V7ZM14 7H15V17H14V7ZM10 7H13V17H10V7ZM8 7H9V17H8V7ZM16 7H19V17H16V7Z"
        fill="#2ED47A"
      />
      <path
        d="M4 5H8V3H4C2.897 3 2 3.897 2 5V9H4V5ZM4 21H8V19H4V15H2V19C2 20.103 2.897 21 4 21ZM20 3H16V5H20V9H22V5C22 3.897 21.103 3 20 3ZM20 19H16V21H20C21.103 21 22 20.103 22 19V15H20V19Z"
        fill="#2ED47A"
      />
    </svg>
  );
};

export const IconDashboard = () => {
  return (
    <svg
      className="stroke"
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 10.133a.3.3 0 01-.3-.3V3.5a.3.3 0 01.3-.3h4.667a.3.3 0 01.3.3v6.333a.3.3 0 01-.3.3H3.5zm0 6.667a.3.3 0 01-.3-.3v-3a.3.3 0 01.3-.3h4.667a.3.3 0 01.3.3v3a.3.3 0 01-.3.3H3.5zm8.333 0a.3.3 0 01-.3-.3v-6.333a.3.3 0 01.3-.3H16.5a.3.3 0 01.3.3V16.5a.3.3 0 01-.3.3h-4.667zm-.3-13.3a.3.3 0 01.3-.3H16.5a.3.3 0 01.3.3v3a.3.3 0 01-.3.3h-4.667a.3.3 0 01-.3-.3v-3z"
        stroke="#C2CFE0"
        strokeWidth="1.4"
      />
    </svg>
  );
};

export const IconBarometer = () => {
  return (
    <svg
      className="fill"
      width="21"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M.003 10.008c0-1.359.264-2.657.794-3.896.53-1.239 1.243-2.302 2.14-3.19A10.21 10.21 0 016.134.794 9.73 9.73 0 0110.01 0a9.76 9.76 0 013.889.794c1.234.53 2.298 1.241 3.19 2.134.893.893 1.604 1.956 2.134 3.19.53 1.235.794 2.531.794 3.89 0 2.212-.655 4.187-1.966 5.926-1.312 1.738-3.001 2.928-5.068 3.568V24H7.19v-4.446c-2.11-.615-3.836-1.803-5.177-3.562-1.34-1.76-2.011-3.755-2.011-5.985zm2.203 0c0 2.118.77 3.938 2.307 5.458 1.52 1.53 3.353 2.294 5.497 2.294 1.41 0 2.714-.346 3.915-1.038a7.731 7.731 0 002.85-2.826 7.538 7.538 0 001.051-3.889c0-1.05-.207-2.056-.621-3.017a7.84 7.84 0 00-1.672-2.486 7.95 7.95 0 00-2.499-1.666 7.59 7.59 0 00-3.024-.621c-1.05 0-2.057.207-3.018.621A7.97 7.97 0 004.5 4.504 7.836 7.836 0 002.828 6.99a7.544 7.544 0 00-.622 3.018zm.795.486v-.986h2.96v.986H3zm1.486-4.83l.718-.705L7.28 7.035l-.705.717-2.089-2.088zm3.46 6.74c0-.573.199-1.068.596-1.487a2.017 2.017 0 011.454-.653l3.729-6.138.897.474-2.755 6.6c.24.34.359.742.359 1.204 0 .598-.21 1.104-.628 1.518a2.087 2.087 0 01-1.525.621 2.05 2.05 0 01-1.506-.62 2.066 2.066 0 01-.621-1.52zm1.794-6.382v-2.96h.948v2.96h-.948zm4.01 4.46V9.469h2.96v1.013h-2.96z"
          fill="#C2CFE0"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h20.02v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconChart = () => {
  return (
    <svg
      className="fill"
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4h1v16h2V10h4v10h2V6h4v14h2v-6h4v7H2V4zm16 11v5h2v-5h-2zm-6-8v13h2V7h-2zm-6 4v9h2v-9H6z"
        fill="#C2CFE0"
      />
    </svg>
  );
};

export const IconEmailSidebar = () => {
  return (
    <svg
      className="fill"
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5h13a3 3 0 013 3v9a3 3 0 01-3 3H5a3 3 0 01-3-3V8a3 3 0 013-3zm0 1a1.99 1.99 0 00-1.283.466L11.5 11.52l7.783-5.054A1.992 1.992 0 0018 6H5zm6.5 6.712L3.134 7.28C3.045 7.51 3 7.754 3 8v9a2 2 0 002 2h13a2 2 0 002-2V8c0-.254-.047-.497-.134-.72L11.5 12.711v.001z"
        fill="#C2CFE0"
      />
    </svg>
  );
};

export const IconHelpCircle = () => {
  return (
    <svg
      className="fill"
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 4a8.5 8.5 0 10.001 17.001A8.5 8.5 0 0011.5 4zm0-1a9.5 9.5 0 110 19 9.5 9.5 0 010-19zM11 17h1v2h-1v-2zm.5-11A3.5 3.5 0 0115 9.5c0 .897-.699 1.519-1.439 2.176l-.935.903c-.588.674-.652 1.955-.627 2.392V15H11c-.004-.052-.102-1.964.874-3.079l1.022-.992C13.488 10.403 14 9.948 14 9.5a2.5 2.5 0 00-5 0H8A3.5 3.5 0 0111.5 6z"
        fill="#C2CFE0"
      />
    </svg>
  );
};

export const IconTable = () => {
  return (
    <svg
      className="fill"
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 5h11a3 3 0 013 3v9a3 3 0 01-3 3H6a3 3 0 01-3-3V8a3 3 0 013-3zM4 17a2 2 0 002 2h5v-3H4v1zm7-5H4v3h7v-3zm6 7a2 2 0 002-2v-1h-7v3h5zm2-7h-7v3h7v-3zM4 11h7V8H4v3zm8 0h7V8h-7v3z"
        fill="#C2CFE0"
      />
    </svg>
  );
};

export const IconPassword = () => {
  return (
    <svg width="15" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6a3 3 0 013 3v8a3 3 0 01-3 3H3a3 3 0 01-3-3V9a3 3 0 013-3V4.5a4.5 4.5 0 019 0V6zM3 7a2 2 0 00-2 2v8a2 2 0 002 2h9a2 2 0 002-2V9a2 2 0 00-2-2H3zm8-1V4.5a3.5 3.5 0 10-7 0V6h7zm-3.5 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm0-1a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
        fill="#C2CFE0"
        fillOpacity=".886"
      />
    </svg>
  );
};

export const IconUser = () => {
  return (
    <svg
      width="15"
      height="15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill"
    >
      <path
        d="M8.236 8.271h.7c-.03.346.042.638.192.877.222.352.552.478.672.512 1.167.33 2.39.8 3.31 1.336.462.269.825.54 1.067.802.245.266.323.473.323.62v.473c0 .207-.104.417-.436.638-.343.228-.868.422-1.553.573-1.362.3-3.187.398-4.99.398-1.798 0-3.635-.098-5.009-.399-.69-.15-1.223-.344-1.57-.573C.604 13.305.5 13.095.5 12.89v-.473c0-.135.074-.335.319-.6.24-.258.6-.53 1.054-.8.905-.537 2.1-1.017 3.213-1.36l.002-.001c.143-.045.488-.196.735-.531.279-.38.368-.91.082-1.548l-.045-.1-.083-.073C4.742 6.503 3.972 5.046 3.972 3.65c0-1.056.404-1.825 1.02-2.34C5.62.789 6.506.5 7.501.5c.993 0 1.882.288 2.513.812.619.514 1.024 1.283 1.024 2.338 0 1.388-.773 2.84-1.805 3.746l-.997.875z"
        stroke="#C2CFE0"
      />
    </svg>
  );
};

export const IconNotification = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      fill="none"
      viewBox="0 0 16 20"
      className="stroke"
    >
      <defs />
      <path
        fill="none"
        stroke="#C2CFE0"
        d="M15.5 16.2071V16.5H.5v-.2929l1.85355-1.8535.14645-.1465V9c0-2.90563 1.52219-5.21908 4.11528-5.83347L7 3.07538V2c0-.55386.44614-1 1-1s1 .44614 1 1V3.07506l.38434.09138C11.9681 3.78076 13.5 6.10482 13.5 9v5.2071l.1464.1465L15.5 16.2071zM9.41352 18.5c-.20747.5806-.76468 1-1.41352 1-.65567 0-1.20926-.4187-1.41464-1h2.82816z"
      />
    </svg>
  );
};

export const IconNotificationActive = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className="stroke circle-fill"
    >
      <defs />
      <path
        fill="none"
        stroke="#C2CFE0"
        d="M19.5 18.2071V18.5h-15v-.2929l1.85355-1.8535.14645-.1465V11c0-2.90563 1.52219-5.21908 4.1153-5.83347L11 5.07538V4c0-.55386.4461-1 1-1 .5539 0 1 .44614 1 1V5.07506l.3843.09138C15.9681 5.78076 17.5 8.10482 17.5 11v5.2071l.1464.1465L19.5 18.2071zM13.4135 20.5c-.2074.5806-.7647 1-1.4135 1-.6557 0-1.2093-.4187-1.4146-1h2.8281z"
      />
      <circle cx="17" cy="6" r="4.5" fill="#F7685B" stroke="none" />
    </svg>
  );
};

export const IconchevronDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className="fill"
    >
      <defs />
      <path
        fill="#C2CFE0"
        d="M5.84277 9.59299L11.4998 15.25l5.657-5.65701-.707-.707-4.95 4.95001-4.95003-4.95001-.707.707z"
      />
    </svg>
  );
};

export const IconTriangle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      fill="none"
      viewBox="0 0 18 17"
      className="fill"
    >
      <defs />
      <path fill="#F5F6F8" d="M18 16.2H0L9 0l9 16.2z" />
    </svg>
  );
};

export const IconLogout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
      className="fill"
    >
      <defs />
      <path
        fill="#000"
        d="M7 9V0h1v9H7zm8-.5c-.0003 1.30881-.3431 2.5948-.9944 3.73-.6512 1.1353-1.5882 2.0804-2.7178 2.7414s-2.41257 1.0149-3.72133 1.0265c-1.30876.0116-2.59777-.3195-3.73894-.9604-1.14116-.6408-2.09475-1.5692-2.76601-2.6927C.390267 11.2212.0247215 9.94153.00121048 8.63293-.0223006 7.32433.297042 6.03236.927502 4.88541 1.55796 3.73846 2.47759 2.77645 3.595 2.095l.731.731c-1.25521.70236-2.24201 1.80123-2.80582 3.12448-.563812 1.32324-.672792 2.79613-.30987 4.18792.36292 1.3918 1.17725 2.624 2.31542 3.5034 1.13818.8795 2.53591 1.3566 3.97427 1.3566 1.43836 0 2.8361-.4771 3.9743-1.3566 1.1381-.8794 1.9525-2.1116 2.3154-3.5034.3629-1.39179.2539-2.86468-.3099-4.18792-.5638-1.32325-1.5506-2.42212-2.8058-3.12448l.73-.73c1.0985.66922 2.0063 1.60985 2.6361 2.73141.6297 1.12156.9603 2.38631.9599 3.67259z"
      />
    </svg>
  );
};

export const IconAccount = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className="fill"
    >
      <defs />
      <path
        fill="#000"
        d="M11.5 14c4.142 0 7.5 1.567 7.5 3.5V20H4v-2.5c0-1.933 3.358-3.5 7.5-3.5zm6.5 3.5c0-1.38-2.91-2.5-6.5-2.5S5 16.12 5 17.5V19h13v-1.5zM11.5 5c.9283 0 1.8185.36875 2.4749 1.02513C14.6313 6.6815 15 7.57174 15 8.5c0 .92826-.3687 1.8185-1.0251 2.4749C13.3185 11.6313 12.4283 12 11.5 12c-.9283 0-1.8185-.3687-2.47487-1.0251C8.36875 10.3185 8 9.42826 8 8.5c0-.92826.36875-1.8185 1.02513-2.47487C9.6815 5.36875 10.5717 5 11.5 5zm0 1c-.663 0-1.2989.26339-1.76777.73223C9.26339 7.20107 9 7.83696 9 8.5c0 .66304.26339 1.29893.73223 1.7678C10.2011 10.7366 10.837 11 11.5 11s1.2989-.2634 1.7678-.7322C13.7366 9.79893 14 9.16304 14 8.5c0-.66304-.2634-1.29893-.7322-1.76777C12.7989 6.26339 12.163 6 11.5 6z"
      />
    </svg>
  );
};

export const IconLock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className="fill"
    >
      <defs />
      <path
        fill="#000"
        fillOpacity=".886"
        d="M16 8c.7956 0 1.5587.31607 2.1213.87868C18.6839 9.44129 19 10.2044 19 11v8c0 .7956-.3161 1.5587-.8787 2.1213C17.5587 21.6839 16.7956 22 16 22H7c-.79565 0-1.55871-.3161-2.12132-.8787C4.31607 20.5587 4 19.7956 4 19v-8c0-.7956.31607-1.55871.87868-2.12132C5.44129 8.31607 6.20435 8 7 8V6.5c0-1.19347.47411-2.33807 1.31802-3.18198C9.16193 2.47411 10.3065 2 11.5 2c1.1935 0 2.3381.47411 3.182 1.31802C15.5259 4.16193 16 5.30653 16 6.5V8zM7 9c-.53043 0-1.03914.21071-1.41421.58579C5.21071 9.96086 5 10.4696 5 11v8c0 .5304.21071 1.0391.58579 1.4142C5.96086 20.7893 6.46957 21 7 21h9c.5304 0 1.0391-.2107 1.4142-.5858S18 19.5304 18 19v-8c0-.5304-.2107-1.03914-.5858-1.41421C17.0391 9.21071 16.5304 9 16 9H7zm8-1V6.5c0-.92826-.3687-1.8185-1.0251-2.47487C13.3185 3.36875 12.4283 3 11.5 3c-.9283 0-1.8185.36875-2.47487 1.02513C8.36875 4.6815 8 5.57174 8 6.5V8h7zm-3.5 6c-.3978 0-.7794.158-1.0607.4393S10 15.1022 10 15.5c0 .3978.158.7794.4393 1.0607S11.1022 17 11.5 17c.3978 0 .7794-.158 1.0607-.4393S13 15.8978 13 15.5c0-.3978-.158-.7794-.4393-1.0607S11.8978 14 11.5 14zm0-1c.663 0 1.2989.2634 1.7678.7322.4688.4689.7322 1.1048.7322 1.7678s-.2634 1.2989-.7322 1.7678C12.7989 17.7366 12.163 18 11.5 18s-1.2989-.2634-1.76777-.7322C9.26339 16.7989 9 16.163 9 15.5s.26339-1.2989.73223-1.7678C10.2011 13.2634 10.837 13 11.5 13z"
      />
    </svg>
  );
};

export const IconPdf = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#2ED47A"
        d="M8 6.5h1v3H8v-3zm-5 1h1v-1H3v1zM16 2v12c0 .5304-.2107 1.0391-.5858 1.4142S14.5304 16 14 16H2c-.53043 0-1.039141-.2107-1.414214-.5858C.210714 15.0391 0 14.5304 0 14V2C0 1.46957.210714.960859.585786.585786.960859.210714 1.46957 0 2 0h12c.5304 0 1.0391.210714 1.4142.585786C15.7893.960859 16 1.46957 16 2zM5.5 6.5c0-.39782-.15804-.77936-.43934-1.06066C4.77936 5.15804 4.39782 5 4 5H1.5v6H3V9h1c.39782 0 .77936-.15804 1.06066-.43934C5.34196 8.27936 5.5 7.89782 5.5 7.5v-1zm5 0c0-.39782-.158-.77936-.4393-1.06066C9.77936 5.15804 9.39782 5 9 5H6.5v6H9c.39782 0 .77936-.158 1.0607-.4393.2813-.2813.4393-.66288.4393-1.0607v-3zm4-1.5h-3v6H13V9h1.5V7.5H13v-1h1.5V5z"
      />
    </svg>
  );
};

export const IconPayBill = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      fill="none"
      viewBox="0 0 20 15"
    >
      <path
        fill="red"
        d="M18-1H2C.89-1 .00999999-.11.00999999 1L0 13c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V1c0-1.11-.89-2-2-2zm0 14H2V7h16v6zm0-10H2V1h16v2z"
      />
    </svg>
  );
};

export const IconCompany = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M22 15h-2v2h2v-2zm0-4h-2v2h2v-2zm2 8h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zM14 7h-2V5h2v2zm0 4h-2V9h2v2zm0 4h-2v-2h2v2zm0 4h-2v-2h2v2zM10 7H8V5h2v2zm0 4H8V9h2v2zm0 4H8v-2h2v2zm0 4H8v-2h2v2zm6-12V3H6v18h20V7H16z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconEmail = () => {
  return (
    <svg width="19" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 0h13a3 3 0 013 3v9a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3zm0 1a1.99 1.99 0 00-1.283.466L9.5 6.52l7.783-5.054A1.992 1.992 0 0016 1H3zm6.5 6.712L1.134 2.28C1.045 2.51 1 2.754 1 3v9a2 2 0 002 2h13a2 2 0 002-2V3c0-.254-.047-.497-.134-.72L9.5 7.711v.001z"
        fill="#fff"
      />
    </svg>
  );
};

export const IconNumber = () => {
  return (
    <svg width="20" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 10V2H0V0h4v10H2zm18-2a2 2 0 01-2 2h-4V8h4V6h-2V4h2V2h-4V0h4a2 2 0 012 2v1.5A1.5 1.5 0 0118.5 5 1.5 1.5 0 0120 6.5V8zm-8 0v2H6V6a2 2 0 012-2h2V2H6V0h4a2 2 0 012 2v2a2 2 0 01-2 2H8v2h4z"
        fill="#fff"
      />
    </svg>
  );
};

export const IconReveal = () => {
  return (
    <svg width="21" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.36 12c3.989 0 7.458-2.224 9.235-5.5A10.498 10.498 0 0010.36 1a10.498 10.498 0 00-9.235 5.5A10.498 10.498 0 0010.36 12zm0-12a11.5 11.5 0 0110.36 6.5A11.5 11.5 0 0110.36 13 11.5 11.5 0 010 6.5 11.5 11.5 0 0110.36 0zm0 2a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm0 1a3.5 3.5 0 100 7 3.5 3.5 0 000-7z"
        fill="#fff"
      />
    </svg>
  );
};

export const IconRequests = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.275 6.975L14.025 1.725C13.875 1.575 13.725 1.5 13.5 1.5H6C5.175 1.5 4.5 2.175 4.5 3V21C4.5 21.825 5.175 22.5 6 22.5H18C18.825 22.5 19.5 21.825 19.5 21V7.5C19.5 7.275 19.425 7.125 19.275 6.975ZM13.5 3.3L17.7 7.5H13.5V3.3ZM18 21H6V3H12V7.5C12 8.325 12.675 9 13.5 9H18V21Z"
        fill="#C2CFE0"
      />
      <path d="M7.5 16.5H16.5V18H7.5V16.5Z" fill="#C2CFE0" />
      <path d="M7.5 12H16.5V13.5H7.5V12Z" fill="#C2CFE0" />
    </svg>
  );
};

export default function renderIcon(name) {
  switch (name) {
    case "IconDashboard":
      return <IconDashboard></IconDashboard>;
    case "IconTable":
      return <IconTable></IconTable>;
    case "IconChart":
      return <IconChart></IconChart>;
    case "IconBarometer":
      return <IconBarometer></IconBarometer>;
    case "IconEmailSidebar":
      return <IconEmailSidebar></IconEmailSidebar>;
    case "IconUser":
      return <IconUser></IconUser>;
    case "IconPassword":
      return <IconPassword></IconPassword>;
    case "IconHelpCircle":
      return <IconHelpCircle></IconHelpCircle>;
    case "IconLogout":
      return <IconLogout></IconLogout>;
    case "IconNotification":
      return <IconNotification></IconNotification>;
    case "IconNotificationActive":
      return <IconNotificationActive></IconNotificationActive>;
    case "IconchevronDown":
      return <IconchevronDown></IconchevronDown>;
    case "IconTriangle":
      return <IconTriangle></IconTriangle>;
    case "IconAccount":
      return <IconAccount></IconAccount>;
    case "IconLock":
      return <IconLock></IconLock>;
    case "IconCompany":
      return <IconCompany></IconCompany>;
    case "IconEmail":
      return <IconEmail></IconEmail>;
    case "IconNumber":
      return <IconNumber></IconNumber>;
    case "IconReveal":
      return <IconReveal></IconReveal>;
    case "IconRequests":
      return <IconRequests></IconRequests>;
    case "IconAutoReader":
      return <IconAutoReader />;
    case "IconBarCode":
      return <IconBarCode />;

    default:
      return null;
  }
}
