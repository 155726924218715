import { Types } from "../actions/keks.actions";

const INITIAL_STATE = {
  qrCode: null,
  error: "",
  loaded: false,
  loading: false,
  payAllLoading: false,
  payAllLoaded: false,
  payAllError: false,
  payAllResponse: null,
};

export default function keks(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GENERATE_KEKS_QR_CODE_REQUEST:
      return {
        ...state,
        qrCode: null,
        loading: true,
        loaded: false,
      };

    case Types.GENERATE_KEKS_QR_CODE_SUCCESS:
      return {
        ...state,
        qrCode: action.payload,
        loading: false,
        loaded: true,
      };

    case Types.GENERATE_KEKS_QR_CODE_ERROR:
      return {
        ...state,
        qrCode: null,
        loading: null,
        loaded: null,
      };

    case Types.PAY_ALL_REFERENCE_KEKS_REQUEST:
      return {
        ...state,
        payAllLoading: true,
        payAllLoaded: false,
        payAllError: null,
        payAllResponse: null,
      };

    case Types.PAY_ALL_REFERENCE_KEKS_SUCCESS:
      return {
        ...state,
        payAllLoading: false,
        payAllLoaded: true,
        payAllResponse: action.payload,
      };

    case Types.PAY_ALL_REFERENCE_KEKS_ERROR:
      return {
        ...state,
        payAllLoading: false,
        payAllLoaded: false,
        payAllError: action.payload,
      };

    case Types.RESET_QR_CODE:
      return {
        ...state,
        qrCode: null,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
