import { combineReducers } from "redux";
import user from "./user";
import meters from "./meters.reducer";
import invoices from "./invoices.reducer";
import notification from "./notification.reducer";
import twoDCode from "./TwoDCode.reducer";
import payAll from "./payAll.reducer";
import eInvoice from "./eInvoice.reducer";
import keks from "./keks.reducer";
import keksTransaction from "./keksTransaction.reducer";

export default combineReducers({
  user,
  meters,
  invoices,
  notification,
  twoDCode,
  payAll,
  eInvoice,
  keks,
  keksTransaction,
});
