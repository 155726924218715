import {
  call,
  fork,
  takeLatest,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { logoutUser } from "../actions/user/user.actions";
import * as actions from "../actions/invoice.actions";
import * as KeksActions from "../actions/keks.actions";
import * as api from "../api/invoice.api";

// Get invoices
function* getInvoices({ payload }) {
  try {
    const state = yield select();
    const response = yield call(
      api.getInvoices,
      state.user.userData.data.userId,
      payload
    );
    yield put(actions.getInvoicesSuccess(response.data));
  } catch (e) {
    if (e.response.status === 401) yield put(logoutUser());
    yield put(
      actions.invoiceError({
        error: "An error occurred when trying to get invoices",
      })
    );
  }
}

function* watchGetInvoicesRequest() {
  yield takeLatest(actions.Types.GET_INVOICES, getInvoices);
}

// Get PDF
function* getInvoicePdf({ payload }) {
  try {
    const state = yield select();
    const response = yield call(
      api.getInvoicePdf,
      state.user.userData.data.userId,
      payload.invoiceId
    );

    const file = new Blob([response.data], { type: "application/pdf" });
    const pdfURL = window.URL.createObjectURL(file);

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(
        file,
        `Invoice-${payload.invoiceId}.pdf`
      );
    } else {
      let fileLink = document.createElement("a");
      fileLink.href = pdfURL;
      fileLink.download = `Invoice-${payload.invoiceId}.pdf`;
      document.body.appendChild(fileLink);
      fileLink.click();

      setTimeout(() => {
        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(pdfURL);
      }, 100);
    }

    yield put(actions.getInvoicePdfSuccess(payload.invoiceId));
  } catch (e) {
    if (e.response.status === 401) yield put(logoutUser());
    yield put(
      actions.getInvoicePdfError(
        payload.invoiceId,
        "An error occurred when trying to get invoice PDF"
      )
    );
  }
}

function* watchGetInvoicePDFRequest() {
  yield takeEvery(actions.Types.GET_INVOICE_PDF, getInvoicePdf);
}

// Mark invoice payed
function* markInvoicePayed({ payload }) {
  const state = yield select();
  yield call(
    api.markInvoiceAsPayed,
    state.user.userData.data.userId,
    payload.ShoppingCartId,
    payload.WsPayOrderId
  );
  yield call(getInvoices, {
    payload: {
      MeterSiteCode: null,
      dateFrom: null,
      dateTo: null,
    },
  });
}

function* watchMarkInvoicePayed() {
  yield takeLatest(actions.Types.MARK_INVOICE_PAYED, markInvoicePayed);
}

// Pay all online WSPAY
function* payAllOnline({ payload }) {
  const state = yield select();
  yield call(
    api.payAllOnline,
    state.user.userData.data.userId,
    payload.ShoppingCartId,
    payload.WsPayOrderId
  );
  yield call(getInvoices, {
    payload: {
      MeterSiteCode: null,
      dateFrom: null,
      dateTo: null,
    },
  });
}

function* watchPayAllOnline() {
  yield takeLatest(actions.Types.PAY_ALL_ONLINE, payAllOnline);
}

// Pay all reference KEKS

function* payAllReferenceKeks({ payload }) {
  try {
    const state = yield select();
    const response = yield call(
      api.payAllReferenceKeks,
      state.user.userData.data.userId,
      payload
    );

    yield put(KeksActions.payAllReferenceKeksSuccess(response.data));
  } catch (err) {
    if (err.response.status === 401) yield put(logoutUser());
    yield put(KeksActions.payAllReferenceKeksError(err));
  }
}

function* watchPayAllReferenceKeks() {
  yield takeLatest(
    KeksActions.Types.PAY_ALL_REFERENCE_KEKS_REQUEST,
    payAllReferenceKeks
  );
}

// Keks QR

function* generateKeksQrCode({ payload }) {
  try {
    const state = yield select();
    const response = yield call(
      api.generateKeksQrCode,
      state.user.userData.data.userId,
      payload
    );

    yield put(KeksActions.generateKeksQrCodeSuccess(response.data));
  } catch (err) {
    if (err.response.status === 401) yield put(logoutUser());
    yield put(KeksActions.payAllReferenceKeksError(err));
  }
}

function* watchGenerateKeksQrCode() {
  yield takeLatest(
    KeksActions.Types.GENERATE_KEKS_QR_CODE_REQUEST,
    generateKeksQrCode
  );
}

// Keks Transaction

function* checkTransactionStatusRequest({ payload }) {
  try {
    const state = yield select();
    const response = yield call(
      api.checkTransactionStatus,
      state.user.userData.data.userId,
      payload
    );

    const status =
      response.data.status === 1
        ? true
        : response.data.status === 2
        ? false
        : null;
    yield put(KeksActions.checkTransactionStatusSuccess(status));
  } catch (err) {
    if (err.response.status === 401) yield put(logoutUser());
    yield put(KeksActions.checkTransactionStatusError(err));
  }
}

function* watchCheckTransactionStatusRequest() {
  yield takeLatest(
    KeksActions.Types.CHECK_TRANSACTION_STATUS_REQUEST,
    checkTransactionStatusRequest
  );
}

// Export

export default [
  fork(watchGetInvoicesRequest),
  fork(watchGetInvoicePDFRequest),
  fork(watchMarkInvoicePayed),
  fork(watchPayAllOnline),
  fork(watchPayAllReferenceKeks),
  fork(watchGenerateKeksQrCode),
  fork(watchCheckTransactionStatusRequest),
];
