import React from "react";
//import * as Styled from "./requests.styles";

const Requests = (props) => {
  return (
    <div>
      Requests page
    </div>
  );
}

export default Requests;
