import React, { useState, useEffect } from "react";
import Text from "../../components/Text";
import { FormGroup, Label, Col, Form, Input, Button, Alert } from "reactstrap";
import * as Styled from "./changePassword.styles";
import { connect } from "react-redux";
import {
  changePassword,
  changePasswordReset,
  changePasswordError
} from "../../redux/actions/user/changePassword.actions";
import validation from "./ChangePassword.validation";
import l10n from "../../localization";
import Loader from "../../components/Loader";

function ChangePassword(props) {
  const INITIAL_PASSWORD = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  const [password, setPassword] = useState(INITIAL_PASSWORD);

  const [validate, setValidation] = useState({
    ...password
  });

  useEffect(() => {
    return () => {
      props.changePasswordReset();
      props.changePasswordError();
    };
    //eslint-disable-next-line
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const validateResponse = validation(password);
    if (!validateResponse) {
      setValidation(INITIAL_PASSWORD);
      props.changePassword(password);
      return;
    }

    setValidation(validateResponse);
  };

  if (props.user.changePassword.success) {
    return (
      <React.Fragment>
        <Styled.Title>{l10n.changePassword.changePassword}</Styled.Title>
        <Styled.Card>
          <Styled.Header>{l10n.changePassword.changePassword}</Styled.Header>
          <Styled.Body>
            <Text>{l10n.changePassword.changeSuccess}.</Text>
          </Styled.Body>
        </Styled.Card>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Styled.Title>{l10n.changePassword.changePassword}</Styled.Title>

      <Styled.Card>
        <Styled.Header>{l10n.changePassword.changePassword}</Styled.Header>
        <Styled.Body>
          <Form onSubmit={handleSubmit}>
            <FormGroup row>
              <Label for="oldPassword" md={2}>
                {l10n.changePassword.oldPassword}
              </Label>
              <Col md={10}>
                <Input
                  type="password"
                  name="oldPassword"
                  id="oldPassword"
                  value={password.oldPassword}
                  invalid={!!validate.oldPassword}
                  onChange={e => {
                    setPassword({ ...password, oldPassword: e.target.value });
                  }}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="newPassword" md={2}>
                {l10n.changePassword.newPassword}
              </Label>
              <Col md={10}>
                <Input
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  value={password.newPassword}
                  invalid={!!validate.newPassword}
                  onChange={e => {
                    setPassword({ ...password, newPassword: e.target.value });
                  }}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="confirmPassword" md={2}>
                {l10n.changePassword.repeatNewPassword}
              </Label>
              <Col md={10}>
                <Input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  value={password.confirmPassword}
                  invalid={!!validate.confirmPassword}
                  onChange={e => {
                    setPassword({
                      ...password,
                      confirmPassword: e.target.value
                    });
                  }}
                />
              </Col>
            </FormGroup>

            {props.user.changePassword.error && (
              <FormGroup>
                <Alert color="danger">
                  {l10n.general.somethingWentWrong}:
                  <ul>
                    {(props.user.changePassword.error || []).map(
                      (err, index) => (
                        <li key={index}>{l10n.serverErrors[err]}</li>
                      )
                    )}
                  </ul>
                </Alert>
              </FormGroup>
            )}

            <FormGroup row>
              <Col md={12}>
                {props.user.changePassword.loading ? (
                  <Loader inline={true} />
                ) : (
                  <Button type="submit" color="primary">
                    {l10n.changePassword.updatePassword}
                  </Button>
                )}
              </Col>
            </FormGroup>
          </Form>
        </Styled.Body>
      </Styled.Card>
    </React.Fragment>
  );
}

export default connect(({ user }) => ({ user }), {
  changePassword,
  changePasswordReset,
  changePasswordError
})(ChangePassword);
