import React from "react";
import * as Styled from "./InvoiceListMobile.styles";
import PropTypes from "prop-types";
import { CardBody, CardHeader, Col, Row } from "reactstrap";
import l10n from "../../../../localization";
import MobileInvoiceEntry from "./_components/MobileInvoiceEntry/MobileInvoiceEntry";

const InvoiceListMobile = (props) => {
  return (
    <React.Fragment>
      <Styled.Card>
        <CardHeader>
          <Row>
            <Col md={8}>{props.title}</Col>
          </Row>
        </CardHeader>
        {props.invoices.length === 0 && (
          <CardBody>
            <Styled.noOpenInvoices>
              {props.openInvoices
                ? l10n.invoices.noOpenInvoices
                : l10n.invoices.noInvoices}
              <i className="far fa-check" />
            </Styled.noOpenInvoices>
          </CardBody>
        )}
      </Styled.Card>

      {(props.invoices || []).map((invoice) => (
        <MobileInvoiceEntry
          key={invoice.invoiceId}
          invoice={invoice}
          onOpenInvoice={props.onOpenInvoice}
          show2dCode={props.show2dCode}
          onPayClicked={props.onPayClicked}
          onKeksPayClicked={props.onKeksPayClicked}
        />
      ))}
    </React.Fragment>
  );
};

InvoiceListMobile.propTypes = {
  openInvoices: PropTypes.bool,
};

InvoiceListMobile.defaultProps = {
  openInvoices: false,
};

export default InvoiceListMobile;
