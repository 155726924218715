import styled from "styled-components";

export const ContentWrap = styled.div`
  background: ${(props) => props.theme.colors[19]};
  padding: 20px 80px 40px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  h2 {
    margin: 0 0 30px;
  }

  .form-group {
    margin-bottom: 3px;
  }

  @media (min-width: 1200px) {
    min-height: 600px;
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    padding: 20px 60px;
    min-height: 500px;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    min-height: 400px;
  }

  @media (max-width: 991px) {
    padding: 40px;
  }

  @media (max-width: 575px) {
    padding: 20px;

    h2 {
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 20px;
    }
  }
`;

export const icon = styled.div`
  text-transform: uppercase;
`;

export const CheckboxWrapper = styled.div`
  padding: 0 0 0 25px;
  margin: 0 0 10px;
  position: relative;

  > label {
    &::before {
      content: "";
      position: absolute;
      left: 1px;
      top: 5px;
      width: 18px;
      height: 18px;
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.colors[0]};
      border-radius: 2px;
    }
  }

  > input {
    opacity: 0;
    top: 2px;

    &:focus + label::before {
      border: 1px solid #66afe9;
    }
    &:checked + label::before {
      background-color: ${(props) => props.theme.colors[0]};
    }
  }
`;

export const BottomGap = styled.div`
  min-height: 15px;
  flex: 1;
`;

export const HintButton = styled.div`
  position: absolute;
  top: 5px;
  right: 30px;
  background: transparent;
  padding: 0;
  font-size: 0;
  line-height: 0;
  border: none;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

export const PasswordButton = styled.button`
  position: absolute;
  top: 12px;
  right: 30px;
  background: transparent;
  padding: 0;
  font-size: 0;
  line-height: 0;
  border: none;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

export const Link = styled.a`
  font-family: Oswald;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[32]};
  cursor: pointer;
  align-self: center;
  &:hover {
    color: ${(props) => props.theme.colors[32]};
  }
`;

export const HintImage = styled.img`
  height: auto;
  width: 100%;
`;

export const CategorySelected = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

export const RadioInput = styled.input`
  border: 2px solid #ddd;
  width: 80px;
  border-radius: 5px;
  padding: 5px 10px;
`;
