export const Types = {
  PASSWORD_RECOVERY: "user/password_recovery",
  PASSWORD_RECOVERY_SUCCESS: "user/password_recovery_success",
  PASSWORD_RECOVERY_RESET: "user/password_recovery_reset",
  PASSWORD_RECOVERY_ERROR: "user/password_recovery_error"
};

export const passwordRecovery = email => ({
  type: Types.PASSWORD_RECOVERY,
  payload: { email }
});

export const passwordRecoverySuccess = () => ({
  type: Types.PASSWORD_RECOVERY_SUCCESS
});

export const passwordRecoveryReset = () => ({
  type: Types.PASSWORD_RECOVERY_RESET
});

export const passwordRecoveryError = error => ({
  type: Types.PASSWORD_RECOVERY_ERROR,
  payload: error
});
