import { Card as RCard } from "reactstrap";
import styled from "styled-components";

export const Card = styled(RCard)`
  border: none;

  margin: 0 h2 {
    margin: 15px 0 0 25px;
  }

  canvas {
    height: 200px !important;
  }
`;

export const GraphLegend = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 15px;
`;

export const LegendItem = styled.div`
  align-items: center;
  display: flex;
  margin-right: 20px;
`;

export const LegendItemMark = styled.div`
  background-color: ${(props) => (props.color ? props.color : "white")};
  border: 2px solid ${(props) => (props.color ? props.color : "red")};
  border-radius: 5px;
  height: 8px;
  margin-right: 8px;
  width: 8px;
`;

export const LegendItemText = styled.p`
  color: ${(props) => props.theme.colors[28]};
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 0;
`;
