import React from "react";
import * as Styled from "./InvoicePdf.styles";
import { IconPdf } from "../../../../components/Icons/Icons";
import Loader from "../../../Loader";

export default function InvoicePdf(props) {
  if (props.downloading) return <Loader inline={true} size={29} />;

  return (
    <Styled.DownloadLink onClick={props.handleOpenInvoice}>
      <IconPdf></IconPdf>
    </Styled.DownloadLink>
  );
}
