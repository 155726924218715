import * as Joi from "joi-browser";

export default (formData, schema) =>
  Joi.validate(formData, schema, { abortEarly: false }, err => {
    if (!err) return null;
    let validationData = {
      meterCode: null,
      readingDate: null,
      newPosition: null,
      selectedMeter: null
    };

    err.details.map(error => (validationData[error.path[0]] = error.message));

    return validationData;
  });
