import { call, fork, put, takeLatest, select } from "redux-saga/effects";
import {
  getUserInfo,
  getUserError,
  getUserSuccess,
  userLoggedIn,
  Types
} from "../../actions/user/user.actions";
import * as api from "../../api/user.api";
import { getMetersReset } from "../../actions/meter.actions";
import { getInvoicesReset } from "../../actions/invoice.actions";

export function* getUser() {
  try {
    const state = yield select();
    const response = yield call(api.getUser, state.user.userData.data.userId);
    yield put(getUserSuccess(response.data));
  } catch (err) {
    if (err.response) {
      yield put(getUserError(err.response.data.Messages));
    } else {
      yield put(getUserError(["unknown_error"]));
    }
  }
}

function* watchGetUser() {
  yield takeLatest(Types.GET_USER, getUser);
}

function* loginUser({ payload }) {
  try {
    const result = yield call(api.loginUser, payload);
    yield put(
      userLoggedIn({
        token: result.data.token,
        userId: result.data.userId
      })
    );
    yield put(getUserInfo());
  } catch (err) {
    if (err.response && err.response.data && err.response.data.Messages) {
      yield put(getUserError(err.response.data.Messages));
    } else {
      yield put(getUserError(["unknown_error"]));
    }
  }
}

function* watchLoginRequest() {
  yield takeLatest(Types.LOGIN_USER, loginUser);
}

function* logout() {
  try {
    localStorage.removeItem("auth");
    yield put(getMetersReset());
    yield put(getInvoicesReset());
  } catch (err) {
    if (err.response && err.response.data && err.response.Messages) {
      yield put(getUserError(err.response.data.Messages));
    } else {
      yield put(getUserError(["unknown_error"]));
    }
  }
}

function* watchLogout() {
  yield takeLatest(Types.USER_RESET, logout);
}

export default [fork(watchGetUser), fork(watchLoginRequest), fork(watchLogout)];
