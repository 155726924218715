import { Types } from "../actions/keks.actions";

const INITIAL_STATE = {
  success: null,
  checking: true,
};

export default function keksTransaction(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CHECK_TRANSACTION_STATUS_REQUEST:
      return {
        ...state,
        checking: true,
        success: null,
      };

    case Types.CHECK_TRANSACTION_STATUS_SUCCESS:
      return {
        ...state,
        checking: false,
        success: action.payload,
      };

    case Types.CHECK_TRANSACTION_STATUS_ERROR:
      return {
        ...state,
        checking: false,
        success: false,
      };

    case Types.CHECK_TRANSACTION_STATUS_RESET:
      return {
        ...state,
        checking: false,
        success: null,
      };

    default:
      return state;
  }
}
