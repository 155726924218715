import React from "react";
import { connect } from "react-redux";
import l10n from "../../../../localization";
import { Row, Col } from "reactstrap";
import * as Styled from "./GraphList.styles";
import BarGraph from "../../../../components/BarGraph";

const GraphList = ({ meters, data, startDate, endDate }) => {
  const getMeter = (meterCode) => {
    if (!meters) return null;
    if (!meters.result) return null;

    const filtered = meters.result.filter(
      (meter) => meter.meterSiteCode === meterCode
    );

    return filtered.length > 0 ? filtered[0] : null;
  };

  const renderCards = (meter) => {
    return (
      <>
        <Col key={`${meter.meterCode}_1`} md="6">
          <Styled.Card>
            <Styled.CardHeader>
              <Styled.HeaderTop>
                <Styled.HeaderData>
                  <Styled.HeaderText>
                    {getMeter(meter.meterCode).siteAddress || "---"}
                  </Styled.HeaderText>
                </Styled.HeaderData>
                <Styled.HeaderLegend>
                  <Styled.HeaderLegendText>
                    {l10n.graphList.consumption}
                  </Styled.HeaderLegendText>
                </Styled.HeaderLegend>
              </Styled.HeaderTop>
              <Styled.HeaderBottom>
                <Styled.HeaderText>{`${l10n.graphList.meter} ${
                  meter.meterCode || ""
                }`}</Styled.HeaderText>
              </Styled.HeaderBottom>
            </Styled.CardHeader>
            <Styled.CardChart>
              <BarGraph
                startDate={startDate}
                endDate={endDate}
                readings={meter.readings}
                type="readings"
              />
            </Styled.CardChart>
          </Styled.Card>
        </Col>
        <Col key={`${meter.meterCode}_2`} md="6">
          <Styled.Card>
            <Styled.CardHeader>
              <Styled.HeaderTop>
                <Styled.HeaderData>
                  <Styled.HeaderText>
                    {getMeter(meter.meterCode).siteAddress || "---"}
                  </Styled.HeaderText>
                </Styled.HeaderData>
                <Styled.HeaderLegend>
                  <Styled.HeaderLegendText>
                    {l10n.graphList.value}
                  </Styled.HeaderLegendText>
                </Styled.HeaderLegend>
              </Styled.HeaderTop>
              <Styled.HeaderBottom>
                <Styled.HeaderText>{`${l10n.graphList.meter} ${
                  meter.meterCode || ""
                }`}</Styled.HeaderText>
              </Styled.HeaderBottom>
            </Styled.CardHeader>
            <Styled.CardChart>
              <BarGraph
                startDate={startDate}
                endDate={endDate}
                readings={meter.invoices}
                type="invoices"
              />
            </Styled.CardChart>
          </Styled.Card>
        </Col>
      </>
    );
  };

  return (
    <Row>
      {data && data.length > 0 ? (
        data.map((meter) => renderCards(meter))
      ) : (
        <Col>
          <p>{l10n.reports.noResults}</p>
        </Col>
      )}
    </Row>
  );
};

export default connect(({ meters }) => ({ meters }), null)(GraphList);
