import React from "react";
import * as Styled from "./NotificationBlock.styles";
import moment from "moment/moment";
import { connect } from "react-redux";
import { markReadNotification } from "../../../../../../../redux/actions/notification.actions";

function NotificationBlock(props) {
  const removeNotification = (e, notificationId) => {
    e.stopPropagation();
    props.markReadNotification(notificationId);
  };

  return (
    <Styled.Notification
      onClick={event =>
        removeNotification(event, props.notification.notificationId)
      }
    >
      <Styled.NotificationText>
        <h2>{props.notification.subject}</h2>
        <p>{props.notification.text}</p>
      </Styled.NotificationText>
      <Styled.NotificationTime>
        {moment(props.notification.sendDate).fromNow()}
      </Styled.NotificationTime>
    </Styled.Notification>
  );
}
export default connect(null, { markReadNotification })(NotificationBlock);
