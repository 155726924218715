import styled from "styled-components";
import { Button } from "reactstrap";

export default {
  MetersRow: styled.tr`
    display: ${(props) => (props.visible ? "table-row" : "none")};

    table {
      margin: 0 auto;
      border: 1px solid ${(props) => props.theme.colors[17]};
    }
  `,
  HoverElement: styled.div`
    &:hover {
      cursor: pointer;
    }
  `,
  PriceWrapper: styled.td`
    font-weight: bold;
    color: ${(props) =>
      props.negative
        ? props.theme.colors[25] + " !important"
        : props.theme.colors[23] + " !important"};
  `,
  InvoiceWrapper: styled.tr`
    cursor: ${(props) => (props.cursorVisible ? "pointer" : "auto")};

    .weight-medium {
      font-weight: 500;
      color: ${(props) => props.theme.colors[23]};
    }
  `,
  IsReversed: styled.td`
    color: ${(props) => props.theme.colors[25]}!important;
    width: 50px;
    min-width: 50px !important;

    span {
      margin-right: 10px;
    }
  `,
  PayButton: styled(Button)`
    background: white;
    border: none;
    margin: 0;
    padding: 10px 20px;

    &:hover,
    &:focus,
    &:active {
      background-color: white !important;
    }
  `,
  KeksPayLogo: styled.div`
    img {
      height: 24px;
      width: auto;
    }
  `,
};
