export const Types = {
  GET_PAYALL_SIGNATURE: "payAll/get_payall",
  GET_PAYALL_SIGNATURE_SUCCESS: "payAll/get_payall_success",
  GET_PAYALL_SIGNATURE_ERROR: "payAll/get_payall_error",
  GET_PAYALL_SIGNATURE_RESET: "payAll/get_payall_reset"
};

export const getPayAllSignature = payload => ({
  type: Types.GET_PAYALL_SIGNATURE,
  payload
});

export const getPayAllSignatureSuccess = payload => ({
  type: Types.GET_PAYALL_SIGNATURE_SUCCESS,
  payload
});

export const getPayAllSignatureError = payload => ({
  type: Types.GET_PAYALL_SIGNATURE_ERROR,
  payload
});

export const getPayAllSignatureReset = () => ({
  type: Types.GET_PAYALL_SIGNATURE_RESET
});
