import React from "react";
import ContactForm from "./_components/ContactForm";
import CallBlock from "./_components/CallBlock/CallBlock";

export default function Help() {
  return (
    <React.Fragment>
      <ContactForm />
      <CallBlock />
    </React.Fragment>
  );
}
