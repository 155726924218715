import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
import * as Styled from "./PasswordRecovery.styles";
import Text from "../../components/Text";
import { connect } from "react-redux";
import {
  passwordRecovery,
  passwordRecoveryReset,
  passwordRecoveryError
} from "../../redux/actions/user/passwordRecovery.actions";
import {
  LoginWrapper,
  FormWrapper,
  GlobalButton,
  GlobalLink
} from "../../components/LoginComponents/LoginGlobal.styles";
import LogoColumn from "../../components/LoginComponents/LogoColumn";
import validation from "./PasswordRecovery.validation";
import l10n from "../../localization";

function PasswordRecovery(props) {
  const [email, setEmail] = useState("");
  const [validate, setValidation] = useState({
    ...email
  });

  useEffect(() => {
    return () => {
      props.passwordRecoveryReset();
    };
    //eslint-disable-next-line
  }, []);

  const handleChange = e => {
    const target = e.target;
    setEmail(target.value);
  };

  const resetPassword = e => {
    e.preventDefault();

    const validateResponse = validation({ email: email });
    if (!validateResponse) {
      props.passwordRecovery(email);
      return;
    }
    setValidation(validateResponse);
  };

  const recovered = !!props.user.passwordRecovery.success;

  return (
    <LoginWrapper>
      <Container>
        <Row noGutters={true}>
          <Col md="6" xs="12">
            <LogoColumn></LogoColumn>
          </Col>
          <Col md="6" xs="12">
            <FormWrapper>
              {recovered && (
                <Styled.ConfirmationWrap>
                  <h2>{l10n.forgotPassword.resetPassword}</h2>
                    <Text>
                    <Styled.JustifiedText>
                      {l10n.forgotPassword.checkYourMail}.
                      </Styled.JustifiedText> 
                      <br /> <br />
                      {/* {l10n.forgotPassword.proceedTo}{" "} */}
                      <GlobalLink to={"/login"}>
                        {l10n.forgotPassword.login}
                      </GlobalLink>
                    </Text>
                </Styled.ConfirmationWrap>
              )}
              {!recovered && (
                <Styled.Form>
                  <h2>{l10n.forgotPassword.resetPassword}</h2>
                  <Text>{l10n.forgotPassword.instructions}</Text>
                  <Form onSubmit={resetPassword}>
                    <FormGroup>
                      <Label for="email">
                        <span className="d-none">
                          {l10n.forgotPassword.email}*
                        </span>
                        <i className="far fa-envelope"></i>
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder={l10n.forgotPassword.email}
                        invalid={!!validate.email}
                        value={email}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup align="right">
                      <GlobalButton
                        color="primary"
                        disabled={props.user.passwordRecovery.loading}
                      >
                        {l10n.forgotPassword.send}
                      </GlobalButton>
                    </FormGroup>
                    <FormGroup align="right">
                      <GlobalLink to="/login">
                      {l10n.forgotPassword.backTo}
                      </GlobalLink>
                    </FormGroup>
                  </Form>

                  {props.user.passwordRecovery.error && (
                    <Alert color="warning">
                      {props.user.passwordRecovery.error.map((err, index) => (
                        <span key={index}>{l10n.serverErrors[err]}</span>
                      ))}
                    </Alert>
                  )}
                </Styled.Form>
              )}
            </FormWrapper>
          </Col>
        </Row>
      </Container>
    </LoginWrapper>
  );
}

export default connect(({ user }) => ({ user }), {
  passwordRecovery,
  passwordRecoveryReset,
  passwordRecoveryError
})(PasswordRecovery);
