import React from "react";
import * as Styled from "./ProfileDropdown.styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import l10n from "../../../../../../localization";
import {
  IconTriangle,
  IconLogout,
  IconAccount,
  IconLock,
} from "../../../../../../components/Icons/Icons";

function ProfileDropdown(props) {
  const showPopup = props.visible;
  return (
    <React.Fragment>
      <Styled.ArrowTop visible={showPopup}>
        <IconTriangle />
      </Styled.ArrowTop>

      <Styled.ProfileDropdown visible={showPopup}>
        <Styled.UserBlock>
          <div title="user">{props.user.userData.data.name}</div>
          <div title="email">{props.user.userData.data.email}</div>
        </Styled.UserBlock>
        <Styled.Submenu>
          <li>
            <Link to="/profile">
              <IconAccount />
              <span>{l10n.profileDropdown.profile}</span>
            </Link>
          </li>
          <li>
            <Link to="/profile/change-password">
              <IconLock />
              <span>{l10n.profileDropdown.changePassword}</span>
            </Link>
          </li>
        </Styled.Submenu>
        <Styled.Devider />
        <Styled.Submenu>
          <li>
            <Link to="/logout">
              <IconLogout />
              <span>{l10n.profileDropdown.logout}</span>
            </Link>
          </li>
        </Styled.Submenu>
      </Styled.ProfileDropdown>
    </React.Fragment>
  );
}

export default connect(({ user }) => ({ user }))(ProfileDropdown);
