export const Types = {
  METER_ERROR: "meter/meter_error",
  GET_METERS: "meter/get_meters",
  GET_METERS_RESET: "meter/get_meters_reset",
  GET_METERS_SUCCESS: "meter/get_meters_success",
  NEW_METER_READING: "meter/new_meter_reading",
  NEW_METER_READING_SUCCESS: "meter/new_meter_reading_success",
  NEW_METER_READING_CLEAR: "meter/new_meter_reading_clear",
  ENABLE_METER_MANUAL_READING: "meter/enable_meter_manual_reading",
  METER_READINGS_BY_DAY: "meter/meter_readings_by_day",
  METER_READINGS_BY_DAY_SUCCESS: "meter/meter_readings_by_day_success",
  METER_READINGS_BY_DAY_ERROR: "meter/meter_readings_by_day_error",
  METER_ERROR_CLEAR: "meter/meter_error_clear",
};

export const getMeters = () => ({
  type: Types.GET_METERS,
});

export const getMetersReset = () => ({
  type: Types.GET_METERS_RESET,
});

export const getMetersSuccess = (meters) => ({
  type: Types.GET_METERS_SUCCESS,
  payload: meters,
});

export const newMeterReading = (newReading) => ({
  type: Types.NEW_METER_READING,
  payload: newReading,
});

export const newMeterReadingSuccess = () => ({
  type: Types.NEW_METER_READING_SUCCESS,
});

export const newMeterReadingClear = () => ({
  type: Types.NEW_METER_READING_CLEAR,
});

export const enableManualReading = (payload) => ({
  type: Types.ENABLE_METER_MANUAL_READING,
  payload,
});

export const meterError = (error) => ({
  type: Types.METER_ERROR,
  payload: error,
});

export const meterErrorClear = () => ({
  type: Types.METER_ERROR_CLEAR,
});

export const meterReadingsByDay = (payload) => ({
  type: Types.METER_READINGS_BY_DAY,
  payload,
});

export const meterReadingsByDaySuccess = (payload) => ({
  type: Types.METER_READINGS_BY_DAY_SUCCESS,
  payload,
});

export const meterReadingsByDayError = (error) => ({
  type: Types.METER_READINGS_BY_DAY_ERROR,
  error,
});
