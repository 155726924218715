import React from "react";
import PropTypes from "prop-types";
import Styled from "./InvoiceListDesktop.styles";
import { Col, Row } from "reactstrap";
import l10n from "../../../../localization";
import InvoiceEntry from "./_components/InvoiceEntry";

const InvoiceListDesktop = ({
  title,
  invoices,
  gatewayWSPAYForm,
  onOpenInvoice,
  openInvoices,
  show2dCode,
  onPayClicked,
  onKeksPayClicked,
}) => {
  return (
    <Styled.Card>
      <Styled.Header>
        <Row className="align-items-center">
          <Col md={8}>
            <Styled.Title>{title}</Styled.Title>
          </Col>
        </Row>
      </Styled.Header>
      <Styled.Table>
        <thead>
          <tr>
            <th></th>
            <th>{l10n.invoices.meterLocation}</th>
            <th>{l10n.invoices.invoiceNumber}</th>
            <th>{l10n.invoices.issueDate}</th>
            <th>{l10n.invoices.expirationDate}</th>
            <th>{l10n.invoices.invoiceValue}</th>
            <th>{l10n.invoices.invoiceDebt}</th>
            <th>{l10n.invoices.pdf}</th>
            <th>{l10n.invoices.barCode}</th>
            <th>{l10n.invoices.payOnline}</th>
          </tr>
        </thead>
        <tbody>
          {invoices.length === 0 && (
            <tr>
              <td colSpan="11">
                {openInvoices
                  ? l10n.invoices.noOpenInvoices
                  : l10n.invoices.noInvoices}
              </td>
            </tr>
          )}
          {(invoices || []).map((invoice, invoiceIndex) => (
            <InvoiceEntry
              key={`${invoice.invoiceId}_${invoiceIndex}`}
              invoice={invoice}
              gatewayWSPAYForm={gatewayWSPAYForm}
              show2dCode={show2dCode}
              onOpenInvoice={onOpenInvoice}
              onPayClicked={onPayClicked}
              onKeksPayClicked={onKeksPayClicked}
            />
          ))}
        </tbody>
      </Styled.Table>
    </Styled.Card>
  );
};

InvoiceListDesktop.propTypes = {
  openInvoices: PropTypes.bool,
};

InvoiceListDesktop.defaultProps = {
  openInvoices: false,
};

export default InvoiceListDesktop;
