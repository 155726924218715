import { call, fork, put, takeLatest, select } from "redux-saga/effects";
import {
  changePasswordSuccess,
  changePasswordError,
  Types
} from "../../actions/user/changePassword.actions";
import * as api from "../../api/user.api";

function* changePassword({ payload }) {
  try {
    const state = yield select();
    yield call(api.changePassword, {
      userId: state.user.userData.data.userId,
      oldPassword: payload.oldPassword,
      newPassword: payload.newPassword
    });
    yield put(changePasswordSuccess());
  } catch (err) {
    yield put(changePasswordError(err.response.data.Messages));
  }
}

function* watchChangePassword() {
  yield takeLatest(Types.CHANGE_PASSWORD, changePassword);
}

export default [fork(watchChangePassword)];
