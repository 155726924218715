import l10n from "../localization";

export default {
  formatNumber: (value) => {
    if (Number(value) % 1 === 0) {
      return Number(value);
    }

    return Number(value).toLocaleString(l10n.general.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },

  formatNumberDefault: (value) => {
    return Number(value).toLocaleString(l10n.general.locale, {});
  },

  formatPrice: (value) => {
    return Number(value).toLocaleString("hr-HR", {
      style: "currency",
      currency: l10n.general.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },

  formatPriceEur: (value) => {
    return Number(value).toLocaleString("hr-HR", {
      style: "currency",
      currency: "HRK",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },

  upperCase: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
};
