import { Types } from "../../actions/user/user.actions";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: null,
  data: null,
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_USER: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_USER_ERROR: {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    }
    case Types.GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: { ...state.data, ...action.payload },
      };
    }

    case Types.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        loading: true,
        success: false,
        data: { ...state.data, ...action.payload },
      };
    }

    case Types.USER_RESET: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
}
