import styled from "styled-components";

export const Notification = styled.div`
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: ${props => props.theme.colors[1]};
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 10px ${props => props.theme.colors[20]};
  &:not(last-of-type) {
    margin: 0 0 8px;
  }
`;

export const NotificationText = styled.div`
  max-width: calc(100% - 90px);

  > h2 {
    font-family: Poppins;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    font-weight: ${props => props.theme.font.bold.weight};
    color: ${props => props.theme.colors[18]};
    margin: 0 0 10px 0;
  }
  > p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${props => props.theme.colors[22]};
  }
`;

export const NotificationTime = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-size: 13px;
  font-weight: ${props => props.theme.font.light.weight};
  line-height: 19px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors[18]};
  opacity: 0.5;
  min-width: 80px;
  text-align: right;
  align-self: center;
  margin: 0 0 0 auto;
`;
