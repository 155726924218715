import styled from "styled-components";

export const LinkWrap = styled.div`
  align-items: center;
  display: flex;
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[19]};
`;
