export const Types = {
  RESEND_USER_ACTIVATION_EMAIL: "user/resend_activation_mail",
  RESEND_USER_ACTIVATION_EMAIL_SUCCESS: "user/resend_activation_mail_success",
  RESEND_USER_ACTIVATION_EMAIL_ERROR: "user/resend_activation_mail_error",
  RESEND_USER_ACTIVATION_EMAIL_RESET: "user/resend_activation_mail_reset"
};

export const resendUserActivationMail = payload => ({
  type: Types.RESEND_USER_ACTIVATION_EMAIL,
  payload
});

export const resendUserActivationMailSuccess = () => ({
  type: Types.RESEND_USER_ACTIVATION_EMAIL_SUCCESS
});

export const resendUserActivationMailError = error => ({
  type: Types.RESEND_USER_ACTIVATION_EMAIL_ERROR,
  payload: error
});

export const resendUserActivationMailReset = () => ({
  type: Types.RESEND_USER_ACTIVATION_EMAIL_RESET
});
