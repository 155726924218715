import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
  resetPasswordSuccess,
  resetPasswordError,
  Types
} from "../../actions/user/resetPassword.actions";
import * as api from "../../api/user.api";

function* resetPassword({ payload }) {
  try {
    yield call(api.resetPassword, payload);
    yield put(resetPasswordSuccess());
  } catch (err) {
    yield put(resetPasswordError(err.response.data.Messages));
  }
}

function* watchResetPassword() {
  yield takeLatest(Types.RESET_PASSWORD, resetPassword);
}

export default [fork(watchResetPassword)];
