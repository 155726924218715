import React, { useState, useEffect } from "react";
import { FormGroup, Label, Form, Input } from "reactstrap";
import * as Styled from "./PersonalDataForm.styles";
import { connect } from "react-redux";
import { updateProfile } from "../../../../redux/actions/user/userProfile.actions";
import l10n from "../../../../localization";

function PersonalDataForm(props) {
  const INITIAL_USER_VALIDATION = {
    name: "",
    telephone: "",
    email: "",
    vatId: "",
  };

  const [user, setUser] = useState({ ...INITIAL_USER_VALIDATION });

  useEffect(() => {
    setUser({
      name: props.user.userData.data.name,
      email: props.user.userData.data.email,
      vatId: props.user.userData.data.vatId,
      telephone: props.user.userData.data.telephone,
    });
  }, [props.user.userData]);

  return (
    <>
      <Styled.Card>
        <Styled.Header>
          <Styled.HeaderTitle>{l10n.profile.personalData}</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.Body>
          <Form>
            <FormGroup row className="d-flex align-items-baseline">
              <Styled.PersonalDataKeyCol>
                <Label for="name">{l10n.profile.name}</Label>
              </Styled.PersonalDataKeyCol>
              <Styled.PersonalDataValueCol>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={user.name}
                  disabled
                />
              </Styled.PersonalDataValueCol>
            </FormGroup>

            <FormGroup row className="d-flex align-items-baseline">
              <Styled.PersonalDataKeyCol>
                <Label for="vatId">{l10n.profile.vatId}</Label>
              </Styled.PersonalDataKeyCol>
              <Styled.PersonalDataValueCol>
                <Input
                  type="text"
                  name="vatId"
                  id="vatId"
                  value={user.vatId}
                  disabled
                />
              </Styled.PersonalDataValueCol>
            </FormGroup>
          </Form>
        </Styled.Body>
      </Styled.Card>
      <Styled.Card>
        <Styled.Header>
          <Styled.HeaderTitle>{l10n.profile.contactData}</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.Body>
          <Form>
            <FormGroup row className="d-flex align-items-baseline">
              <Styled.ContactDataKeyCol>
                <Label for="telephone">{l10n.profile.telephone}</Label>
              </Styled.ContactDataKeyCol>
              <Styled.ContactDataValueCol>
                <Input
                  type="text"
                  name="telephone"
                  id="telephone"
                  value={user.telephone}
                  disabled
                />
              </Styled.ContactDataValueCol>
            </FormGroup>

            <FormGroup row className="d-flex align-items-baseline">
              <Styled.ContactDataKeyCol>
                <Label for="email">{l10n.profile.email}</Label>
              </Styled.ContactDataKeyCol>
              <Styled.ContactDataValueCol>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={user.email}
                  disabled
                />
              </Styled.ContactDataValueCol>
            </FormGroup>
          </Form>
        </Styled.Body>
      </Styled.Card>
    </>
  );
}

export default connect(({ user }) => ({ user }), { updateProfile })(
  PersonalDataForm
);
