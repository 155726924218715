import styled from "styled-components";
import { Button as RButton } from "reactstrap";
import { Link as RLink } from "react-router-dom";
import Background from "../../assets/login-background.png";

export const LoginWrapper = styled.div`
  display: flex;
  justify-content: ${window.innerHeight > 880 ? "center" : "none"};
  align-items: center;
  flex-direction: column;
  height: 99vh;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors[7]};
  background-image: url("${Background}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (max-width: 991px) {
    padding: 15px 0;
  }
`;

export const FormWrapper = styled.div`
  background: ${(props) => props.theme.colors[19]};
  height: 100%;

  h2 {
    font-family: Oswald;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[0]};
  }

  p {
    font-family: Oswald;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[0]};
  }

  .form-group {
    position: relative;
    font-family: Oswald;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[0]};
  }

  .form-control {
    height: 40px;
    padding: 5px 20px 5px 30px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors[0]};
    background-color: transparent;
    border: none;
    border-bottom: 2px solid ${(props) => props.theme.colors[0]};
    border-radius: 0;
    &::placeholder {
      color: ${(props) => props.theme.colors[33]};
    }

    &#passwordConfirm,
    &#password {
      padding: 5px 50px 5px 30px;
    }

    &#name {
      padding: 5px 30px 5px 30px;
    }
  }

  .form-text {
    font-family: Oswald;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.colors[0]};
    margin: 2px 0;
  }

  label {
    &:not(.static) {
      position: absolute;
      top: 10px;
      margin: 0;
      font-size: 0;
      line-height: 0;
    }

    &.static {
      font-family: Oswald;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colors[0]};
    }

    img {
      opacity: 0.886;
    }
  }

  i {
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors[0]};
  }

  @media (max-width: 575px) {
    h2 {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

export const GlobalButton = styled(RButton)`
  background: ${(props) => props.theme.colors[36]};
  box-shadow: 0px 2px 2px ${(props) => props.theme.colors[34]},
    0px 0px 2px ${(props) => props.theme.colors[35]};
  border-radius: 4px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  color: ${(props) => props.theme.colors[19]};
  padding: 10px;
  border: none;

  &:hover {
    background-color: ${(props) => props.theme.colors[32]};
  }
`;

export const GlobalLink = styled(RLink)`
  font-family: Oswald;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[32]};
  cursor: pointer;
  align-self: center;

  &:hover {
    color: ${(props) => props.theme.colors[32]};
  }
`;
