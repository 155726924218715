import Login from "./pages/Login";
import Register from "./pages/Register";
import ActivationSent from "./pages/ActivationSent";
import Framework from "./pages/Framework";
import PasswordRecovery from "./pages/PasswordRecovery";
import ResetPassword from "./pages/ResetPassword";
import ActivateUser from "./pages/ActivateUser";

export default [
  {
    path: "/login",
    component: Login
  },
  {
    path: "/register",
    component: Register
  },
  {
    path: "/activation-sent/:email",
    component: ActivationSent
  },
  {
    path: "/password-recovery/:prpid",
    component: ResetPassword
  },
  {
    path: "/password-recovery",
    component: PasswordRecovery
  },
  {
    path: "/activate/:token",
    component: ActivateUser
  },
  {
    path: "/",
    component: Framework,
    protected: true
  }
];
