import React, { useState } from "react";
import * as Styled from "./OptionBox.styles";
import Loader from "../../components/Loader";
import theme from "../../theme/default";

export default function OptionBox(props) {
  const [opened, setOpened] = useState(false);

  const toggleStatus = () => {
    setOpened(!opened);
  };

  const matches = props.options.filter(option => option.slug === props.selected);

  return (
    <Styled.Wrapper>
      <Styled.Dropdown onClick={toggleStatus} opened={opened}>
        {props.loading && (
          <Loader inline={true} color={theme.colors[1]} size={20} />
        )}
        {!props.loading && props.options && props.selected && (
          <span>
            {
              matches.length > 0 ? matches[0].value : "null"
            }{" "}
            <i className="fas fa-caret-down" />
          </span>
        )}
      </Styled.Dropdown>
      {!props.loading && (
        <Styled.Options opened={opened}>
          {(props.options || []).map((option, index) => (
            <li key={index}>
              <span
                onClick={() => {
                  toggleStatus();
                  props.onSelect(option);
                }}
              >
                {option.value}
              </span>
            </li>
          ))}
        </Styled.Options>
      )}
    </Styled.Wrapper>
  );
}
