import { Button as RButton } from "reactstrap";
import styled from "styled-components";
import { Link as RLink } from "react-router-dom";

export const ConfirmationWrap = styled.div`
  padding: 95px 80px 40px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[0]};
    text-align: center;
    margin: 0 0 35px;
  }

  p {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[0]};
    text-align: center;
  }

  a {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    text-align: center;
  }
`;

export const Form = styled.div`
  padding: 95px 80px 40px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  h2 {
    margin: 0 0 35px;
  }

  p {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[0]};
  }

  button {
    min-width: 160px;
  }

  form {
    margin: 100px 0 0;
  }
`;

export const Button = styled(RButton)``;

export const Link = styled(RLink)`
  color: ${(props) => props.theme.colors[9]};
  &:hover {
    color: ${(props) => props.theme.colors[9]};
  }
`;

export const JustifiedText = styled.div`
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
`;
