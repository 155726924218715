import React, { useState, useEffect } from "react";
import OptionBox from "../../components/OptionBox/OptionBox";
import { connect } from "react-redux";
import {
  getMeters,
  meterReadingsByDay,
} from "../../redux/actions/meter.actions";
import { getInvoices } from "../../redux/actions/invoice.actions";
import Loader from "../../components/Loader";
import l10n from "../../localization";
import ChangeLayoutButton from "./_components/ChangeLayoutButton";
import moment from "moment";
import * as Styled from "./reports.styles";
import GraphList from "./_components/GraphList";
import TableList from "./_components/TableList";
import DatePicker from "react-datepicker";

function Reports(props) {
  const [currentDisplay, setCurrentDisplay] = useState("graph");
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "years").toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [meterSiteCode, setMeterSiteCode] = useState("all");

  useEffect(() => {
    if (!props.meters.loaded) props.getMeters();
    props.getInvoices(
      false,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.meters.loaded) {
      props.meterReadingsByDay({
        meterCode: meterSiteCode,
        dateFrom: moment(startDate).format("YYYY-MM-DD"),
        dateTo: moment(endDate).format("YYYY-MM-DD"),
      });
    }

    props.getInvoices(
      false,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    );

    //eslint-disable-next-line
  }, [meterSiteCode, startDate, endDate, props.meters.loaded]);

  const changeDisplay = (newDisplay) => {
    setCurrentDisplay(newDisplay);
  };

  if (props.meters.loading) return <Loader />;

  const generateMeters = () => {
    return props.meters.result.map((meter) => {
      return {
        slug: meter.meterSiteCode,
        value: `${meter.address} (${meter.meterSiteCode})`,
      };
    });
  };

  const generateReports = () => {
    const readings = props.meters.meterReadingsByDay.result;
    let newReadings = [];

    if (readings) {
      newReadings = readings.map((reading) => {
        const filteredInvoices = props.invoices.result.filter(
          (invoice) => invoice.meterCode === reading.meterCode
        );

        return {
          ...reading,
          invoices: filteredInvoices.reverse(),
        };
      });
    }

    return newReadings;
  };

  return (
    <div>
      <Styled.Filters>
        <Styled.FilterSwitch>
          <ChangeLayoutButton
            display="graph"
            label={l10n.reports.chart}
            onChangeDisplay={changeDisplay}
            currentDisplay={currentDisplay}
          />
          <Styled.FilterSpacer />
          <ChangeLayoutButton
            display="table"
            label={l10n.reports.table}
            onChangeDisplay={changeDisplay}
            currentDisplay={currentDisplay}
          />
        </Styled.FilterSwitch>
        <Styled.FilterOptions>
          <Styled.FilterItem>
            <span>{l10n.reports.meter}</span>
            <OptionBox
              options={[
                {
                  slug: "all",
                  value: l10n.reports.allMeters,
                },
                ...generateMeters(),
              ]}
              selected={meterSiteCode}
              onSelect={(option) => {
                setMeterSiteCode(option.slug);
              }}
            />
          </Styled.FilterItem>
          <Styled.RangeFilter>
            <Styled.FilterItem2>
              <span>{l10n.reports.period_from} </span>
              <DatePicker
                selected={startDate}
                dateFormat="dd.MM.yyyy"
                locale={l10n.general.locale}
                onChange={(date) => setStartDate(new Date(date))}
              />
            </Styled.FilterItem2>
            <Styled.FilterItem2>
              <span>{l10n.reports.perod_to} </span>
              <DatePicker
                selected={endDate}
                dateFormat="dd.MM.yyyy"
                locale={l10n.general.locale}
                onChange={(date) => setEndDate(new Date(date))}
              />
            </Styled.FilterItem2>
          </Styled.RangeFilter>
        </Styled.FilterOptions>
      </Styled.Filters>
      <>
        {props.meters.meterReadingsByDay.loading || props.invoices.loading ? (
          <Loader />
        ) : (
          <>
            {currentDisplay === "graph" && (
              <GraphList
                data={generateReports()}
                startDate={startDate}
                endDate={endDate}
              />
            )}

            {currentDisplay === "table" && (
              <TableList
                data={generateReports()}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          </>
        )}
      </>
    </div>
  );
}

export default connect(({ meters, invoices }) => ({ meters, invoices }), {
  getMeters,
  meterReadingsByDay,
  getInvoices,
})(Reports);
