import { call, fork, takeLatest, put, select } from "redux-saga/effects";
import * as actions from "../actions/notification.actions";
import { logoutUser } from "../actions//user/user.actions";
import * as api from "../api/notification.api";

function* getNotifications() {
  try {
    const state = yield select();
    const notifications = yield call(
      api.getNotifications,
      state.user.userData.data.userId
    );
    yield put(
      actions.getNotificationsSuccess(
        notifications.data.filter(notification => notification)
      )
    );
  } catch (e) {
    if (e.response.status === 401) yield put(logoutUser());
    yield put(
      actions.notificationError([
        "An error occurred when trying to get notifications"
      ])
    );
  }
}

function* watchGetNotificationsRequest() {
  yield takeLatest(actions.Types.GET_NOTIFICATIONS, getNotifications);
}

function* markReadNotification({ payload: notificationId }) {
  try {
    yield call(api.markReadNotification, notificationId);
    yield call(getNotifications);
  } catch (e) {
    if (e.response.status === 401) yield put(logoutUser());
    yield put(
      actions.notificationError([
        "An error occurred when trying to mark read notification"
      ])
    );
  }
}

function* watchMarkReadRequest() {
  yield takeLatest(actions.Types.MARK_READ, markReadNotification);
}

function* clearNotifications() {
  try {
    const state = yield select();
    yield call(api.markAllReadNotifications, state.user.userData.data.userId);
    yield call(getNotifications);
  } catch (e) {
    if (e.response.status === 401) yield put(logoutUser());
    yield put(
      actions.notificationError([
        "An error occurred when trying to mark read notification"
      ])
    );
  }
}

function* watchClearNotifications() {
  yield takeLatest(
    actions.Types.MARK_ALL_READ_NOTIFICATIONS,
    clearNotifications
  );
}

export default [
  fork(watchGetNotificationsRequest),
  fork(watchMarkReadRequest),
  fork(watchClearNotifications)
];
