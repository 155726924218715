import React, { useState, useEffect } from "react";
import { Alert, Button, Form, Input } from "reactstrap";
import { connect } from "react-redux";
import {
  getMeters,
  newMeterReading,
  newMeterReadingClear,
  meterErrorClear,
} from "../../redux/actions/meter.actions";
import moment from "moment";
//import Loader from "../../components/Loader";
import OptionBox from "../../components/OptionBox";
import validation from "./Send.validation";
import l10n from "../../localization";
import * as Styled from "./Send.styles";
import * as Joi from "joi-browser";
import DatePicker from "react-datepicker";

const Send = (props) => {
  const initialMeterState = {
    readingDate: null,
    value: null,
  };

  const [validate, setValidation] = useState({
    ...initialMeterState,
    readingDate: "",
  });
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [selectedUserMeter, setSelectedUserMeter] = useState(null);
  const [readingDate, setReadingDate] = useState(moment());
  const [readingValue, setReadingValue] = useState(null);
  const [readingValueAlertVisible, setReadingValueAlertVisible] =
    useState(false);

  let schema = Joi.object().keys({
    meterSiteCode: Joi.string().required(),
    meterCode: Joi.string().required(),
    readingDate: Joi.string().required(),
    value: Joi.number().required(),
  });

  useEffect(() => {
    setSelectedMeter(props.meters.result[0]);
    selectUserMeter(props.meters.result[0]);

    if (!props.meters.loaded) props.getMeters();
    return () => {
      props.newMeterReadingClear();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.meters.loaded && props.meters.result.length > 0) {
      setSelectedMeter(props.meters.result[0]);
      selectUserMeter(props.meters.result[0]);
    }
    //eslint-disable-next-line
  }, [props.meters.loaded]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (readingValue < selectedUserMeter.lastValue) {
      setReadingValueAlertVisible(true);
      return;
    }

    setReadingValueAlertVisible(false);

    const requestData = {
      meterSiteCode: selectedMeter.meterSiteCode,
      meterCode: selectedUserMeter.meterCode,
      readingDate: readingDate ? readingDate.format() : null,
      value: readingValue,
    };

    const validateResponse = validation(requestData, schema);

    if (!validateResponse) {
      props.newMeterReading(requestData);
      setValidation({ ...initialMeterState });
      return;
    }

    setValidation(validateResponse);
  };

  const handleSelectMeter = (item) => {
    const filteredMeters = props.meters.result.filter(
      (meter) => meter.meterSiteCode === item.slug
    );

    if (filteredMeters.length > 0) {
      const targetMeter = filteredMeters[0];
      setSelectedMeter(targetMeter);
      selectUserMeter(targetMeter);
      setReadingValueAlertVisible(false);
      props.meterErrorClear();
    }
  };

  const handleSelectUserMeter = (item) => {
    const filteredUserMeters = selectedMeter.userMeters.filter(
      (userMeter) => userMeter.meterCode === item.slug
    );

    if (filteredUserMeters.length > 0) {
      setSelectedUserMeter(filteredUserMeters[0]);
      props.newMeterReadingClear();
      setReadingValueAlertVisible(false);
      props.meterErrorClear();
      setReadingValue(0);
    }
  };

  const selectUserMeter = (meter) => {
    if (meter.userMeters && meter.userMeters.length > 0) {
      setSelectedUserMeter(meter.userMeters[0]);
      setReadingValue(0);
      props.newMeterReadingClear();
    }
  };

  // if (!props.meters.loaded) return <Loader />;

  function renderSubmissionSection() {
    const lastReadingDate = moment(selectedUserMeter.lastReadingDate);
    const currentDate = moment();

    // Date restrictions from 21st to 28th by default
    let enabledFromDay = 21;
    let enabledToDay = 28;

    // In case of February, offset a bit
    if (currentDate.month() === 1) {
      enabledFromDay = 19;
      enabledToDay = 26;
    }

    if (process.env.REACT_APP_MEASUREMENT_ALWAYS_ENABLE) {
      enabledFromDay = moment().startOf("month").date();
      enabledToDay = moment().endOf("month").date();
    }

    const startDate = moment(currentDate)
      .set("date", enabledFromDay)
      .startOf("day");
    const endDate = moment(currentDate).set("date", enabledToDay).endOf("day");

    if (selectedMeter && selectedMeter.isOnlyActivated === false) {
      return <Alert color="warning">{l10n.send.isOnlyActivated}</Alert>;
    }

    if (
      lastReadingDate.month() === moment().month() &&
      lastReadingDate.year() === moment().year()
    ) {
      return (
        <Alert color="warning" hidden={props.meters.newPositionSaved}>
          {l10n.send.alreadySubmittedManual}
          <br />
          {l10n.send.alreadySubmittedManual2}
        </Alert>
      );
    } else {
      if (moment().isBetween(startDate, endDate, "hour", "[]")) {
        return (
          <>
            <Alert color="success" hidden={!props.meters.newPositionSaved}>
              {l10n.send.sendSuccess}
            </Alert>
            {!props.meters.newPositionSaved && (
              <Button color="primary" type="submit">
                {l10n.send.sendButton}
              </Button>
            )}
          </>
        );
      } else {
        return (
          <Alert color="warning">
            {l10n.formatString(
              l10n.send.sendDateRestriction,
              enabledFromDay,
              enabledToDay
            )}
          </Alert>
        );
      }
    }
  }

  const filterDates = (date) => {
    const currentDate = moment(date);

    // Date restrictions from 21st to 28th by default
    let enabledFromDay = 21;
    let enabledToDay = 28;

    // In case of February, offset a bit
    if (currentDate.month() === 1) {
      enabledFromDay = 19;
      enabledToDay = 26;
    }

    if (process.env.REACT_APP_MEASUREMENT_ALWAYS_ENABLE) {
      enabledFromDay = moment().startOf("month").date();
      enabledToDay = moment().endOf("month").date();
    }

    const startDate = moment(currentDate)
      .set("date", enabledFromDay)
      .startOf("day");
    const endDate = moment(currentDate).set("date", enabledToDay).endOf("day");

    return currentDate.isBetween(startDate, endDate, "hour", "[]");
  };

  function renderMeterReadingView() {
    const lastReadingDate = moment(selectedUserMeter.lastReadingDate);
    const currentDate = moment();

    // Date restrictions from 21st to 28th by default
    let enabledFromDay = 21;
    let enabledToDay = 28;

    // In case of February, offset a bit
    if (currentDate.month() === 1) {
      enabledFromDay = 19;
      enabledToDay = 26;
    }

    if (process.env.REACT_APP_MEASUREMENT_ALWAYS_ENABLE) {
      enabledFromDay = moment().startOf("month").date();
      enabledToDay = moment().endOf("month").date();
    }

    const startDate = moment(currentDate)
      .set("date", enabledFromDay)
      .startOf("day");
    const endDate = moment(currentDate).set("date", enabledToDay).endOf("day");

    if (selectedMeter && selectedMeter.isOnlyActivated === false) {
      return null;
    }

    if (
      lastReadingDate.month() !== moment().month() ||
      lastReadingDate.year() !== moment().year()
    ) {
      if (currentDate.isBetween(startDate, endDate, "hour", "[]")) {
        return (
          <Styled.MeterReadingView>
            <Styled.MeterReadingRow>
              <Styled.MeterReadingKey>
                {l10n.send.dateOfPosition}
              </Styled.MeterReadingKey>
              <Styled.MeterReadingValue>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={readingDate.toDate()}
                  disabled={!selectedMeter.isOnlyActivated}
                  required
                  onChange={(newDate) => setReadingDate(moment(newDate))}
                  filterDate={filterDates}
                  locale={l10n.general.locale}
                />
              </Styled.MeterReadingValue>
            </Styled.MeterReadingRow>
            <Styled.MeterReadingRow>
              <Styled.MeterReadingKey>
                {l10n.send.reading}
              </Styled.MeterReadingKey>
              <Styled.MeterReadingValue>
                <Input
                  type="number"
                  name="readingValue"
                  id="readingValue"
                  value={readingValue || ""}
                  placeholder="unesite novo stanje vodomjera"
                  disabled={!selectedMeter.isOnlyActivated}
                  invalid={!!validate.value}
                  required
                  onChange={(e) => setReadingValue(e.target.value)}
                />
              </Styled.MeterReadingValue>
            </Styled.MeterReadingRow>
          </Styled.MeterReadingView>
        );
      }
    }

    return null;
  }

  return (
    <React.Fragment>
      <Styled.Header>
        <Styled.HeaderTitle>{l10n.send.sendDescription}</Styled.HeaderTitle>
        <Styled.HeaderText>
          {`${l10n.send.headerText} `}
          <Styled.HeaderLink href="https://www.google.com">
            {l10n.send.headerLink}
          </Styled.HeaderLink>
        </Styled.HeaderText>
      </Styled.Header>
      <Styled.Filters>
        <Styled.InputWrap>
          <Styled.OptionText>{l10n.send.measurementPoint}</Styled.OptionText>
          <OptionBox
            options={[
              ...props.meters.result.map((meter) => {
                return {
                  slug: meter.meterSiteCode,
                  value: `${meter.siteAddress}`,
                };
              }),
            ]}
            // loading={!props.meters.loaded || !selectedMeter}
            selected={selectedMeter ? selectedMeter.meterSiteCode : null}
            onSelect={handleSelectMeter}
          />
        </Styled.InputWrap>
        <Styled.InputWrap>
          <Styled.OptionText>{l10n.send.measurementMeter}</Styled.OptionText>
          <OptionBox
            options={
              selectedMeter && selectedMeter.userMeters
                ? [
                    ...selectedMeter.userMeters.map((userMeter) => {
                      return {
                        slug: userMeter.meterCode,
                        value: `${userMeter.meterCode}`,
                      };
                    }),
                  ]
                : []
            }
            // loading={!props.meters.loaded || !selectedUserMeter}
            selected={selectedUserMeter ? selectedUserMeter.meterCode : null}
            onSelect={handleSelectUserMeter}
          />
        </Styled.InputWrap>
      </Styled.Filters>

      {selectedUserMeter && (
        <Form onSubmit={handleSubmit}>
          {selectedMeter && selectedMeter.isOnlyActivated !== false && (
            <Styled.Card>
              <Styled.MeterDataView>
                <Styled.MeterDataRow>
                  <Styled.MeterDataKey>
                    {l10n.send.dateOfLastPosition}
                  </Styled.MeterDataKey>
                  <Styled.MeterDataValue>
                    {selectedUserMeter.lastReadingDate
                      ? moment(selectedUserMeter.lastReadingDate).format(
                          "DD.MM.YYYY"
                        )
                      : "-"}
                  </Styled.MeterDataValue>
                </Styled.MeterDataRow>
                <Styled.MeterDataRow>
                  <Styled.MeterDataKey>
                    {l10n.send.lastPosition}
                  </Styled.MeterDataKey>
                  <Styled.MeterDataValue>
                    {selectedUserMeter.lastValue}
                  </Styled.MeterDataValue>
                </Styled.MeterDataRow>
              </Styled.MeterDataView>

              {renderMeterReadingView()}
            </Styled.Card>
          )}

          <Styled.SubmitButtonView>
            {renderSubmissionSection()}
          </Styled.SubmitButtonView>
        </Form>
      )}

      <Alert
        color="danger"
        hidden={!props.meters.error}
        style={{ marginTop: "20px" }}
      >
        {l10n.serverErrors[props.meters.error] || props.meters.error}
      </Alert>

      <Alert
        color="danger"
        hidden={!readingValueAlertVisible}
        style={{ marginTop: "20px" }}
      >
        <Styled.LastPositionError>
          {l10n.send.lastPositionError}{" "}
          <Styled.Link to="/help">{l10n.send.lastPositionError2}</Styled.Link>{" "}
          {l10n.send.lastPositionError3}
        </Styled.LastPositionError>
      </Alert>
    </React.Fragment>
  );
};

export default connect(({ meters }) => ({ meters }), {
  getMeters,
  newMeterReading,
  newMeterReadingClear,
  meterErrorClear,
})(Send);
