import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import * as Styled from "./Framework.styles";

import ProtectedRoute from "../../components/ProtectedRoute";

import Sidebar from "../layout/Sidebar/Sidebar";
import Header from "../layout/Header";

import Dashboard from "../Dashboard";
import Invoices from "../Invoices";
import Send from "../Send";
import Reports from "../Reports";
import Help from "../Help";
import Guides from "../Guides";
import Profile from "../Profile";
import Logout from "../Logout";
import ChangePassword from "../ChangePassword";
import EInvoice from "../EInvoice";
import Requests from "../Requests";
import Meters from "../Meters";
import Info from "../Info";

function App() {
  const routes = [
    {
      path: "/",
      component: Dashboard,
      exact: true,
      protected: true,
    },
    {
      path: "/meters",
      component: Meters,
      exact: true,
      protected: true,
    },
    {
      path: "/invoices",
      component: Invoices,
      exact: true,
      protected: true,
    },
    {
      path: "/send",
      component: Send,
      exact: true,
      protected: true,
    },
    {
      path: "/reports",
      component: Reports,
      exact: true,
      protected: true,
    },
    {
      path: "/help",
      component: Help,
      exact: true,
      protected: true,
    },
    {
      path: "/info",
      component: Info,
      exact: true,
      protected: true,
    },
    {
      path: "/profile",
      component: Profile,
      exact: true,
      protected: true,
    },
    {
      path: "/profile/change-password",
      component: ChangePassword,
      protected: true,
    },
    {
      path: "/einvoice",
      component: EInvoice,
      protected: true,
    },
    {
      path: "/guides",
      component: Guides,
      exact: true,
    },
    {
      path: "/requests",
      component: Requests,
      protected: true,
    },
    {
      path: "/logout",
      component: Logout,
      exact: true,
    },
  ];

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const toggleMobileNavigation = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return (
    <React.Fragment>
      <Sidebar
        mobileOpen={mobileNavOpen}
        onNavigationChange={toggleMobileNavigation}
      />
      <Styled.Wrapper>
        <Header onMobileMenuChange={toggleMobileNavigation} />
        <Styled.Content>
          {routes.map((route, i) => {
            if (route.protected) {
              return (
                <ProtectedRoute
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            } else {
              return (
                <Route
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            }
          })}
          <Route exact path="/dashboard" render={() => <Redirect to="/" />} />
        </Styled.Content>
      </Styled.Wrapper>
    </React.Fragment>
  );
}

export default App;
