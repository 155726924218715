import axios from "axios";

export const getInvoices = (
  userId,
  { MeterSiteCode = null, dateFrom = null, dateTo = null }
) => {
  let query = "";

  if (MeterSiteCode !== null) {
    query += `${query !== "" ? "&" : "?"}MeterSiteCode=${MeterSiteCode}`;
  }

  if (dateFrom) {
    query += `${query !== "" ? "&" : "?"}DateFrom=${dateFrom}`;
  }

  if (dateTo) {
    query += `${query !== "" ? "&" : "?"}DateTo=${dateTo}`;
  }

  return axios.get(`/user/${userId}/invoice${query}`);
};

export const getInvoicePdf = (userId, invoiceId) => {
  return axios.get(`/user/${userId}/invoice/${invoiceId}/pdf`, {
    responseType: "blob",
  });
};

export const markInvoiceAsPayed = (userId, ShoppingCartId, WsPayOrderId) => {
  return axios.post(`/user/${userId}/invoice/pay-online`, {
    ShoppingCartId,
    WsPayOrderId,
  });
};

export const payAllSignature = ({ userId, meterCode }) => {
  return axios.post(`user/${userId}/invoice/pay-all-reference`, {
    meterCode,
  });
};

export const payAllOnline = (userId, shoppingCartId, wsPayOrderId) => {
  return axios.post(`user/${userId}/invoice/pay-all-online`, {
    shoppingCartId,
    wsPayOrderId,
  });
};

export const payAllReferenceKeks = (userId, meterCode) => {
  return axios.post(`user/${userId}/invoice/pay-all-reference-keks`, {
    MeterCode: meterCode,
  });
};

export const generateKeksQrCode = (userId, { invoiceId, invoiceValue }) => {
  const query = invoiceValue ? `?valueOfPayment=${invoiceValue}` : "";
  return axios.get(`user/${userId}/invoice/${invoiceId}/keks/qrcode${query}`);
};

export const checkTransactionStatus = (userId, invoiceId) => {
  return axios.get(`user/${userId}/invoice/${invoiceId}/status`);
};
