import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import InvoiceList from "../../components/InvoiceList";
import OptionBox from "../../components/OptionBox/OptionBox";
import { connect } from "react-redux";
import {
  getInvoices,
  getInvoicesReset,
} from "../../redux/actions/invoice.actions";
import { getMeters } from "../../redux/actions/meter.actions";
import l10n from "../../localization";
import * as Styled from "./Invoice.styles";

function Invoices(props) {
  const [invoices, setInvoices] = useState([]);
  const [siteAddressFilter, setSiteAddressFilter] = useState("all");

  useEffect(() => {
    props.getInvoices();
    if (!props.meters.loaded) props.getMeters();

    return function cleanup() {
      props.getInvoicesReset();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let results = props.invoices.result;

    if (siteAddressFilter !== "all")
      results = results.filter(
        (invoice) => invoice.meterCode === siteAddressFilter
      );

    setInvoices(results);
  }, [props.invoices.result, siteAddressFilter]);

  return (
    <div>
      <Row>
        <Col md={12}>
          <Styled.InputWrap>
            <Styled.OptionText>
              {l10n.invoices.measurementPoint}:
            </Styled.OptionText>
            <OptionBox
              options={[
                {
                  slug: "all",
                  value: l10n.invoices.all,
                },
                ...props.meters.result.map((meter) => {
                  return {
                    slug: meter.meterSiteCode,
                    value: `${meter.siteAddress}`,
                  };
                }),
              ]}
              loading={!props.meters.loaded}
              selected={siteAddressFilter}
              onSelect={(option) => setSiteAddressFilter(option.slug)}
            />
          </Styled.InputWrap>
        </Col>
      </Row>
    <InvoiceList
        title={l10n.invoices.invoices}
        invoices={invoices}
        loaded={props.invoices.loaded}
      />
    </div>
  );
}

export default connect(({ meters, invoices }) => ({ meters, invoices }), {
  getInvoices,
  getMeters,
  getInvoicesReset,
})(Invoices);
