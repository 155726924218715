import { Types } from "../actions/meter.actions";

const INITIAL_STATE = {
  result: [],
  error: null,
  newPositionSaved: false,
  loaded: false,
  loading: false,
  meterReadingsByDay: {},
};

export default function meters(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_METERS: {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }

    case Types.GET_METERS_SUCCESS: {
      return {
        ...state,
        result: action.payload,
        loaded: true,
        loading: false,
      };
    }

    case Types.METER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case Types.METER_ERROR_CLEAR: {
      return {
        ...state,
        error: null,
        loading: false,
      };
    }

    case Types.GET_METERS_RESET: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }

    case Types.NEW_METER_READING: {
      return {
        ...state,
        newPositionSaved: false,
        loading: true,
      };
    }

    case Types.NEW_METER_READING_SUCCESS: {
      return {
        ...state,
        newPositionSaved: true,
        loading: false,
      };
    }

    case Types.NEW_METER_READING_CLEAR: {
      return {
        ...state,
        newPositionSaved: false,
        loading: false,
      };
    }

    case Types.METER_READINGS_BY_DAY: {
      return {
        ...state,
        meterReadingsByDay: {
          ...state.meterReadingsByDay,
          result: [],
          loaded: false,
          loading: true,
        },
      };
    }

    case Types.METER_READINGS_BY_DAY_SUCCESS: {
      return {
        ...state,
        meterReadingsByDay: {
          ...state.meterReadingsByDay,
          result: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }

    case Types.METER_READINGS_BY_DAY_ERROR: {
      return {
        ...state,
        meterReadingsByDay: {
          ...state.meterReadingsByDay,
          result: [],
          loaded: true,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
}
