import styled from "styled-components";

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0 130px;
  > img {
    max-width: 100%;
  }

  @media (max-width: 991px) and (min-width: 576px) {
    margin: 0 0 60px;
  }

  @media (max-width: 575px) {
    margin: 0 0 30px;
  }

  h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[37]};
    margin: 0;
  }
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const Info = styled.div`
  background-color: ${(props) => props.theme.colors[0]};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 28px;
  text-align: center;
`;

export const Footer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-around;
  margin: 20px;
`;

export const FooterLogo = styled.img`
  max-width: 150px;
`;

export const FooterTitle = styled.h2`
  color: ${(props) => props.theme.colors[37]};
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 36px;
  margin: 0;
`;

export const Stores = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StoreItem = styled.div`
  height: 40px;
  width: 100%;

  a {
    img {
      object-fit: contain;
      height: 40px;
      width: auto;
    }
  }
`;