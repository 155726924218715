export const Types = {
  PAY_ALL_REFERENCE_KEKS_REQUEST: "keks/pay_all_reference_request",
  PAY_ALL_REFERENCE_KEKS_SUCCESS: "keks/pay_all_reference_success",
  PAY_ALL_REFERENCE_KEKS_ERROR: "keks/pay_all_reference_error",
  GENERATE_KEKS_QR_CODE_REQUEST: "keks/generate_qr_code_request",
  GENERATE_KEKS_QR_CODE_SUCCESS: "keks/generate_qr_code_success",
  GENERATE_KEKS_QR_CODE_ERROR: "keks/generate_qr_code_error",
  RESET_QR_CODE: "keks/reset_qr_code",
  CHECK_TRANSACTION_STATUS_REQUEST: "keks/check_transaction_status_request",
  CHECK_TRANSACTION_STATUS_SUCCESS: "keks/check_transaction_status_success",
  CHECK_TRANSACTION_STATUS_ERROR: "keks/check_transaction_status_error",
  CHECK_TRANSACTION_STATUS_RESET: "keks/check_transaction_status_reset",
};

export const payAllReferenceKeksRequest = (meterCode) => ({
  type: Types.PAY_ALL_REFERENCE_KEKS_REQUEST,
  payload: meterCode,
});

export const payAllReferenceKeksSuccess = (result) => ({
  type: Types.PAY_ALL_REFERENCE_KEKS_SUCCESS,
  payload: result,
});

export const payAllReferenceKeksError = (error) => ({
  type: Types.PAY_ALL_REFERENCE_KEKS_ERROR,
  payload: error,
});

export const generateKeksQrCodeRequest = (invoiceId, invoiceValue) => ({
  type: Types.GENERATE_KEKS_QR_CODE_REQUEST,
  payload: { invoiceId, invoiceValue },
});

export const generateKeksQrCodeSuccess = (result) => ({
  type: Types.GENERATE_KEKS_QR_CODE_SUCCESS,
  payload: result,
});

export const generateKeksQrCodeError = (error) => ({
  type: Types.GENERATE_KEKS_QR_CODE_ERROR,
  payload: error,
});

export const resetQrCode = () => ({
  type: Types.RESET_QR_CODE,
});

export const checkTransactionStatusRequest = (invoiceId) => ({
  type: Types.CHECK_TRANSACTION_STATUS_REQUEST,
  payload: invoiceId,
});

export const checkTransactionStatusSuccess = (result) => ({
  type: Types.CHECK_TRANSACTION_STATUS_SUCCESS,
  payload: result,
});

export const checkTransactionStatusError = (error) => ({
  type: Types.CHECK_TRANSACTION_STATUS_ERROR,
  payload: error,
});

export const checkTransactionStatusReset = () => ({
  type: Types.CHECK_TRANSACTION_STATUS_RESET,
});
