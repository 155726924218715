import styled from "styled-components";
import { Row } from "reactstrap";

export default {
  button: styled.button`
    background-color: transparent;
    padding: 0;
    text-align: center;
    border: 0;
    outline: none;
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  `,
  ItemLine: styled(Row)`
    border-bottom: 1px solid ${props => props.theme.colors[13]};
  `,
  CardHolder: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 25px 0 10px 0;
    img {
      margin: 0 10px 15px 10px;
    }
  `,
  logo: styled.img`
    max-width: 200px;
    margin: 0 0 15px;
  `,
  checkbox: styled.input`
    margin: 0 10px 0 0;
    cursor: pointer;
  `
};
