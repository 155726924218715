import axios from "axios/index";

export const registerUser = (requestData) => {
  return axios.post(`/user/register`, {
    email: requestData.email,
    firstName: requestData.firstName,
    lastName: requestData.lastName,
    vatId: requestData.vat,
    userCode: requestData.userCode,
    termsConfirmed: true,
    companyName: requestData.company,
    password: requestData.password,
    telephone: requestData.telephone,
  });
};

export const loginUser = (requestData) => {
  return axios.post(`/user/login`, {
    email: requestData.email,
    password: requestData.password,
    platform: "0"
  });
};

export const activateUser = (token) => {
  return axios.get(`/user/activate/${token}`);
};

export const passwordRecovery = (email) => {
  return axios.post(`/user/password-recovery`, {
    email,
  });
};

export const getUser = (userId) => {
  return axios.get(`/user/${userId}`);
};

export const sendEnquiry = ({ userId, message }) => {
  return axios.post(`/user/${userId}/enquiry`, { message });
};

export const updateProfile = ({ userId, ...postData }) => {
  return axios.put(`/user/${userId}`, {
    name: postData.name,
    companyName: postData.companyName,
    telephone: postData.telephone,
    onlineNotification: false,
  });
};

export const changePassword = ({ userId, oldPassword, newPassword }) => {
  return axios.post(`/user/${userId}/new-password`, {
    oldPassword,
    newPassword,
  });
};

export const resetPassword = ({ password, passwordRecoveryProcedureId }) => {
  return axios.post(`/user/password-change`, {
    password,
    passwordRecoveryProcedureId,
  });
};

export const resendUserActivationMail = ({ email }) => {
  return axios.post(`/user/send-activation-email`, {
    email,
  });
};
