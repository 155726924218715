import React, { useState, useEffect } from "react";
import * as Styled from "./HeaderNotification.styles";
import NotificationDropdown from "./_components/NotificationDropdown";
import { connect } from "react-redux";
import { getNotifications } from "../../../../../redux/actions/notification.actions";
import {
  IconNotificationActive,
  IconNotification
} from "../../../../../components/Icons/Icons";

function HeaderNotification(props) {
  const { isMobile } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    props.getNotifications();
    //eslint-disable-next-line
  }, []);



  return (
    <Styled.notificationWrap
      onMouseEnter={() => !isMobile? setVisible(true): console.log()}
      onMouseLeave={() => !isMobile? setVisible(false): console.log()}
      onClick={() => isMobile? setVisible(!visible) : console.log()}
    >
      {props.notification.result.length > 0 ? (
        <IconNotificationActive />
      ) : (
        <IconNotification />
      )}
      <NotificationDropdown
        visible={visible}
        notifications={props.notification.result}
        isMobile={isMobile}
      />
    </Styled.notificationWrap>
  );
}

export default connect(({ notification }) => ({ notification }), {
  getNotifications
})(HeaderNotification);
