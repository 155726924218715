import React, { useState, useEffect } from "react";
import { Alert, Button, Table } from "reactstrap";
import { connect } from "react-redux";
import { getMeters } from "../../redux/actions/meter.actions";
import Loader from "../../components/Loader";
import l10n from "../../localization";
import * as Styled from "./EInvoice.styles";
import Modal from "../../components/Modal";
import {
  onlineInvoice,
  onlineNotification,
} from "../../redux/actions/eInvoice.actions";

function EInvoice(props) {
  const [activationModalVisible, setActivationModalVisible] = useState(false);
  const [activationType, setActivationType] = useState(null);
  const [metersThatCanBeActivated, setMetersThatCanBeActivated] = useState([]);
  const [metersThatCantBeActivated, setMetersThatCantBeActivated] = useState([]);
  const [metersThatCantBeActivated2, setMetersThatCantBeActivated2] = useState([]);

  useEffect(() => {
    //if (!props.meters.loaded) props.getMeters();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.meters.result) {
      setMetersThatCanBeActivated(
        props.meters.result.filter(
          (meter) => meter.canActivateEInvoice === true
        )
      );
      setMetersThatCantBeActivated(
        props.meters.result.filter(
          (meter) => meter.canActivateEInvoice === false && meter.isDeliveryByEmail === false
        )
      );
      setMetersThatCantBeActivated2(
        props.meters.result.filter(
          (meter) => meter.canActivateEInvoice === false && meter.isDeliveryByEmail === true
        )
      );
    }
  }, [props.meters]);

  const openActivationModal = (type) => {
    setActivationType(type);
    setActivationModalVisible(true);
  };

  const closeActivationModal = () => {
    setActivationType(null);
    setActivationModalVisible(false);
  };

  const handleAccept = () => {
    if (activationType === "invoice") {
      props.onlineInvoice();
    } else if (activationType === "usage") {
      props.onlineNotification();
    }

    setActivationType(null);
    closeActivationModal();
  };

  if (!props.meters.loaded) return <Loader />;

  let userEmail = "";

  if (props.user && props.user.userData) {
    userEmail = props.user.userData.data.email || "";
  }

  return (
    <React.Fragment>
      {metersThatCanBeActivated.length > 0 &&
        props.user &&
        props.user.userData.data.onlineInvoice === false && (
          <>
            <Styled.Section>
              <Styled.Title>{l10n.einvoice.title1}</Styled.Title>
              <Styled.Description>
                <Styled.DescriptionText>
                  {l10n.einvoice.description1}
                </Styled.DescriptionText>
              </Styled.Description>

              <Styled.Card>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{l10n.einvoice.measurementPoint}</th>
                      <th>{l10n.einvoice.deliveryBill}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metersThatCanBeActivated.map((meter) => (
                      <tr key={meter.meterSiteCode}>
                        <td>{`${meter.address || ""} - ${
                          meter.meterSiteCode || ""
                        }`}</td>
                        <td>{meter.deliveredBy || ""}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Styled.Card>

              {props.eInvoice.onlineInvoiceError && (
                <Styled.ErrorSection>
                  <Alert color="danger">{l10n.einvoice.activationError}</Alert>
                </Styled.ErrorSection>
              )}
              
              {props.user &&
                props.user.userData.data.onlineInvoice === false && (
                  <Styled.ActivateSection>
                    <Button
                      color="primary"
                      onClick={() => openActivationModal("invoice")}
                      disabled={props.eInvoice.onlineInvoiceLoading}
                    >
                      {props.eInvoice.onlineInvoiceLoading ? (
                        <Loader inline={true} color="white" />
                      ) : (
                        l10n.einvoice.activateButton
                      )}
                    </Button>
                  </Styled.ActivateSection>
              )}

              {props.user &&
                props.user.userData.data.onlineInvoice === true && (
                  <Styled.CancelationTextTop>
                    {l10n.einvoice.notice5}{" "}
                    <Styled.Link to="/help">{l10n.einvoice.notice4}</Styled.Link>
                  </Styled.CancelationTextTop>
              )}
            </Styled.Section>
          </>
        )}

      {metersThatCantBeActivated.length > 0 && (
        <Styled.Section>
          <Styled.Title>{l10n.einvoice.title3}</Styled.Title>
          <Styled.Description>
            <Styled.DescriptionText>
              {l10n.einvoice.description3}
            </Styled.DescriptionText>
            <Styled.DescriptionEmail>
              <a
                href="http://www.kdvik-rijeka.hr/racun/e_racun"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.kdvik-rijeka.hr/racun/e_racun
              </a>
            </Styled.DescriptionEmail>
          </Styled.Description>

          <Styled.Card>
            <Table responsive>
              <thead>
                <tr>
                  <th>{l10n.einvoice.measurementPoint}</th>
                  <th>{l10n.einvoice.deliveryBill}</th>
                </tr>
              </thead>
              <tbody>
                {metersThatCantBeActivated.map((meter) => (
                  <tr key={meter.meterSiteCode}>
                    <td>{`${meter.address || ""} - ${
                      meter.meterSiteCode || ""
                    }`}</td>
                    <td>{meter.deliveredBy || ""}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Styled.Card>
          <Styled.CancelationTextTop>
            {l10n.einvoice.notice6}{" "}
            <Styled.Link to="/help">{l10n.einvoice.notice4}</Styled.Link>
          </Styled.CancelationTextTop>
        </Styled.Section>
      )}

      {metersThatCantBeActivated2.length > 0 && (
        <Styled.Section>
          <Styled.Title>{l10n.einvoice.title1Activated}</Styled.Title>
          <Styled.Description>
            <Styled.DescriptionText>
              {l10n.einvoice.description1Activated}
            </Styled.DescriptionText>
            <Styled.DescriptionEmail>{userEmail}</Styled.DescriptionEmail>
          </Styled.Description>

          <Styled.Card>
            <Table responsive>
              <thead>
                <tr>
                  <th>{l10n.einvoice.measurementPoint}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {metersThatCantBeActivated2.map((meter) => (
                  <tr key={meter.meterSiteCode}>
                    <td>{`${meter.address || ""} - ${
                      meter.meterSiteCode || ""
                    }`}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Styled.Card>
          <Styled.CancelationTextTop>
            {l10n.einvoice.notice5}{" "}
            <Styled.Link to="/help">{l10n.einvoice.notice4}</Styled.Link>
          </Styled.CancelationTextTop>
        </Styled.Section>
      )}

      <Styled.Section>
        <Styled.Title>
          {props.user && props.user.userData.data.onlineNotification === false
            ? l10n.einvoice.title2
            : l10n.einvoice.title2Activated}
        </Styled.Title>
        <Styled.Description>
          {props.user &&
          props.user.userData.data.onlineNotification === false ? (
            l10n.einvoice.description2
          ) : (
            <>
              <Styled.DescriptionText>
                {l10n.einvoice.description2Activated}
              </Styled.DescriptionText>
              <Styled.DescriptionEmail>{userEmail}</Styled.DescriptionEmail>
            </>
          )}
        </Styled.Description>

        <Styled.Card>
          <Table responsive>
            <thead>
              <tr>
                <th>{l10n.einvoice.measurementPoint}</th>
                <th>{props.user && props.user.userData.data.onlineNotification === false ? l10n.einvoice.deliveryAddress : ""}</th>
              </tr>
            </thead>
            <tbody>
              {(props.meters.result || []).map((meter) => (
                <tr key={meter.meterSiteCode}>
                  <td>{`${meter.address || ""} - ${
                    meter.meterSiteCode || ""
                  }`}</td>
                  {props.user &&
                    props.user.userData.data.onlineNotification === false && (
                      <td>{meter.deliveryAddress || ""}</td>
                    )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Styled.Card>

        {props.eInvoice.onlineNotificationError && (
          <Styled.ErrorSection>
            <Alert color="danger">{l10n.einvoice.activationError}</Alert>
          </Styled.ErrorSection>
        )}

        <Styled.ActivateSection>
          {props.user && props.user.userData.data.onlineNotification === false && (
            <Button
              color="primary"
              onClick={() => openActivationModal("usage")}
              disabled={props.eInvoice.onlineNotificationLoading}
            >
              {props.eInvoice.onlineNotificationLoading ? (
                <Loader inline={true} color="white" />
              ) : (
                l10n.einvoice.activateButton
              )}
            </Button>
          )}
        </Styled.ActivateSection>
      </Styled.Section>

      {props.user && props.user.userData.data.onlineNotification === true && (
        <Styled.CancelationText>
          {l10n.einvoice.notice3}{" "}
          <Styled.Link to="/help">{l10n.einvoice.notice4}</Styled.Link>
        </Styled.CancelationText>
      )}

      <Styled.Modal>
        <Modal
          open={activationModalVisible}
          title={
            activationType && activationType === "invoice"
              ? l10n.einvoice.modalInvoiceTitle
              : l10n.einvoice.modalUsageTitle
          }
          footer={true}
          closeButtonText={l10n.einvoice.closeButtonText}
          onAccept={handleAccept}
          onClose={closeActivationModal}
        >
          {activationType && activationType === "invoice"
            ? l10n.einvoice.modalInvoiceMessage
            : l10n.einvoice.modalUsageMessage}
        </Modal>
      </Styled.Modal>
    </React.Fragment>
  );
}

export default connect(
  ({ meters, eInvoice, user }) => ({ meters, eInvoice, user }),
  {
    getMeters,
    onlineInvoice,
    onlineNotification,
  }
)(EInvoice);
