import { call, fork, put, takeLatest, select } from "redux-saga/effects";
import {
  Types,
  sendEnquirySuccess,
  sendEnquiryError
} from "../../actions/contact/sendEnquiry.actions";
import * as api from "../../api/user.api";

function* sendEnquiry({ payload }) {
  try {
    const state = yield select();
    yield call(api.sendEnquiry, {
      userId: state.user.userData.data.userId,
      message: payload.message
    });
    yield put(sendEnquirySuccess());
  } catch (err) {
    yield put(sendEnquiryError(err.response.data.Messages));
  }
}

function* watchSendEnquiry() {
  yield takeLatest(Types.SEND_ENQUIRY, sendEnquiry);
}

export default [fork(watchSendEnquiry)];
