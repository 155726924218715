import React from "react";
import moment from "moment";
import l10n from "../../../../../../localization";
import Helpers from "../../../../../../helpers/helpers";
import * as Styled from "./mobileMeterRow.styles";

const MobileMeterRow = ({ reading }) => {
  return (
    <Styled.Wrapper>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>{l10n.reports.date}</Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>
          {`${moment(reading.dateFrom).format("DD.MM.YYYY")} - ${moment(
            reading.dateTo
          ).format("DD.MM.YYYY")}`}
        </Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>
          {l10n.reports.consumption}
        </Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>
          {Helpers.formatNumber(reading.consumption)}
        </Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
    </Styled.Wrapper>
  );
};

export default MobileMeterRow;
