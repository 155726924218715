import React from "react";
import { CardBody, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";
import l10n from "../../../../../../localization";
import Helpers from "../../../../../../helpers/helpers";
import InvoicePdf from "../../../InvoicePdf";
import { IconBarCode } from "../../../../../Icons/Icons";
import * as Styled from "./mobileInvoiceEntry.styles";
import kekspayLogo from "../../../../../../assets/kekspay_logo.svg";

const MobileInvoiceEntry = (props) => {
  const { invoice } = props;
  if (!invoice) return;

  return (
    <Styled.Card>
      <CardHeader>
        <Row>
          <Col>
            <Styled.Month>
              {moment(invoice.invoiceIssueDate).format("MMMM YYYY")}
            </Styled.Month>
          </Col>
          <Col align={"right"}>
            <Styled.PriceTag status={invoice.isPayed}>
              <strong>
                {Helpers.formatPrice(
                  invoice.invoiceDebt > 0
                    ? invoice.invoiceDebt
                    : invoice.invoiceValue
                )}
              </strong>
            </Styled.PriceTag>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Styled.InvoiceRow>
          <Col>
            <Styled.InvoiceRowKey>
              {l10n.invoices.meterLocation}
            </Styled.InvoiceRowKey>
            <Styled.InvoiceRowValue>{invoice.meterCode}</Styled.InvoiceRowValue>
          </Col>
          <Col>
            <Styled.RightWrapper>
              <InvoicePdf
                handleOpenInvoice={() => props.onOpenInvoice(invoice.invoiceId)}
                downloading={invoice.downloadingPdf}
              />
            </Styled.RightWrapper>
          </Col>
        </Styled.InvoiceRow>
        <Styled.InvoiceRow>
          <Col>
            <Styled.InvoiceRowKey>
              {l10n.invoices.invoiceNumber}
            </Styled.InvoiceRowKey>
            <Styled.InvoiceRowValue>
              {invoice.invoiceNumber}
            </Styled.InvoiceRowValue>
          </Col>
          <Col>
            {
             invoice.isPayed && invoice.isPayed === "Open" && 
             <Styled.RightWrapper
                onClick={() => props.show2dCode(invoice.invoiceId)}
              >
                {invoice.isPayed === "Open" && <IconBarCode />}
              </Styled.RightWrapper>
            }
          </Col>
        </Styled.InvoiceRow>
        <Styled.InvoiceRow>
          <Col>
            <Styled.InvoiceRowKey>
              {l10n.invoices.issueDate}
            </Styled.InvoiceRowKey>
            <Styled.InvoiceRowValue>
              {invoice.invoiceExpirationDate
                ? moment(invoice.invoiceIssueDate).format("DD.MM.YYYY")
                : "-"}
            </Styled.InvoiceRowValue>
          </Col>

          <Col>
            {
             invoice.isPayed && invoice.isPayed === "Open" && 
             <Styled.RightWrapper
                onClick={() => props.onKeksPayClicked(invoice)}
              >
                <Styled.KeksPayLogo>
                  <img src={kekspayLogo} alt="KEKS Pay logo" />
                </Styled.KeksPayLogo>
              </Styled.RightWrapper>
            }
          </Col>

        </Styled.InvoiceRow>
        {invoice.isReversed && (
          <Styled.InvoiceRow>
            <Col>
              <Styled.InvoiceRowStatus>
                {l10n.einvoice.isReversed}
              </Styled.InvoiceRowStatus>
              <Styled.InvoiceRowValue></Styled.InvoiceRowValue>
            </Col>
          </Styled.InvoiceRow>
        )}

        {invoice.isEnforcement && (
          <Styled.InvoiceRow>
            <Col>
              <Styled.InvoiceRowStatus>
                {l10n.einvoice.isEnforcement}
              </Styled.InvoiceRowStatus>
              <Styled.InvoiceRowValue></Styled.InvoiceRowValue>
            </Col>
          </Styled.InvoiceRow>
        )}
      </CardBody>
    </Styled.Card>
  );
};

export default MobileInvoiceEntry;
