import styled from "styled-components";

export default {
  Card: styled.div`
    margin: 0 0 20px 0;
    background-color: ${(props) => props.theme.colors[0]};
    border-radius: 4px;
    box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};

    label {
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colors[27]};
    }

    input {
      background-color: transparent;
      color: ${(props) => props.theme.colors[23]};
      border: none;
      border-radius: 0;
      outline: none;

      &:not(:disabled) {
        border-bottom: 1px solid ${(props) => props.theme.colors[17]};
      }

      &:focus {
        background-color: transparent;
        box-shadow: none;
      }

      &:disabled {
        background-color: transparent;
      }
    }

    textarea.form-control {
      min-height: 200px;
    }

    button {
      padding: 4px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.colors[0]};
      background-color: ${(props) => props.theme.colors[18]};
      transition: 0.2s background-color ease-in;

      &:hover,
      &:active,
      &:focus {
        background-color: ${(props) => props.theme.colors[14]} !important;
      }
    }

    .form-group {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `,
  Body: styled.div`
    padding: 20px 24px;

    p {
      font-size: 13px;
      line-height: 19px;
      margin: 0;
    }
  `,
  HeaderWrapper: styled.div`
    padding: 25px 0 10px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors[17]};
  `,
  Title: styled.h1`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[23]};
    margin: 0 0 10px 25px;
  `,
  Header: styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[28]};
    margin: 0 0 10px 25px;
  `,
};
