import styled from "styled-components";
import { Button as RButton, Card as RCard, Table as RTable } from "reactstrap";

export const Table = styled(RTable)`
  td,
  th {
    vertical-align: middle;
  }
`;

export const Card = styled(RCard)`
  margin: 0 0 25px 0;
`;

export const OverDue = styled.div`
  color: ${props => props.theme.colors[1]};
  font-size: 17px;
  > span {
    color: ${props => props.theme.colors[8]};
    font-weight: ${props => props.theme.font.bold.weight};
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const DownloadLink = styled(RButton)`
  color: ${props => props.theme.colors[2]};
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.25rem;
  background-color: ${props => props.theme.colors[3]};
  padding: 5px 15px;

  > i {
    font-size: 21px;
    position: relative;
    top: 2px;
    margin: 0 10px 0 0;
    color: ${props => props.theme.colors[12]};
  }

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors[12]};
    background: none;
    > i {
      color: ${props => props.theme.colors[12]};
    }
  }
`;

export const Month = styled.p`
  margin: 0;
  padding: 0;
  text-transform: capitalize;
`;

export const noOpenInvoices = styled.span`
  color: ${props => props.theme.colors[2]};
  font-weight: ${props => props.theme.font.bold.weight};
  i {
    margin: 0 0 0 5px;
  }
`;
