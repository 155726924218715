import React from "react";
import { GlobalStyle } from "./theme/default";
import ProtectedRoute from "./components/ProtectedRoute";
import DefaultTheme from "./theme/default";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import routes from "./routes";
import { connect } from "react-redux";

const App = props => {
  return (
    <BrowserRouter>
      <React.Fragment>
        <GlobalStyle />
        <ThemeProvider theme={DefaultTheme}>
          <Switch>
            {routes.map((route, i) => {
              if (route.protected) {
                return (
                  <ProtectedRoute
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                );
              } else {
                return (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                );
              }
            })}
          </Switch>
        </ThemeProvider>
      </React.Fragment>
    </BrowserRouter>
  );
};

export default connect(
  ({ user }) => ({ user }),
  null
)(App);
