export const Types = {
  UPDATE_PROFILE: "user/update_profile",
  UPDATE_PROFILE_SUCCESS: "user/update_profile_success",
  UPDATE_PROFILE_ERROR: "user/update_profile_error",
  UPDATE_PROFILE_RESET: "user/update_profile_reset"
};

export const updateProfile = postData => ({
  type: Types.UPDATE_PROFILE,
  payload: postData
});

export const updateProfileSuccess = () => ({
  type: Types.UPDATE_PROFILE_SUCCESS
});

export const updateProfileError = () => ({
  type: Types.UPDATE_PROFILE_ERROR
});

export const updateProfileReset = () => ({
  type: Types.UPDATE_PROFILE_RESET
});
