import React from "react";
import MobileMeterRow from "../MobileMeterRow/MobileMeterRow";

const MobileMeterRows = ({ readings }) => {
  if (!readings) return null;
  if (readings.length <= 0) return null;

  return readings.map((meterReading, index) => (
    <MobileMeterRow key={`${meterReading}_${index}`} reading={meterReading} />
  ));
};

export default MobileMeterRows;
