import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import InvoiceList from "../../components/InvoiceList";
import Text from "../../components/Text";
import {
  getInvoices,
  getInvoicesReset,
} from "../../redux/actions/invoice.actions";
import { getMeters } from "../../redux/actions/meter.actions";
import l10n from "../../localization";
import * as Styled from "./Dashboard.styled";
import { connect } from "react-redux";
import DashboardWidget from "../../components/DashboardWidget";
import PayPopup from "../../components/PayPopup";
import KeksPayModal from "../../components/KeksPayModal/KeksPayModal";

const Dashboard = ({
  invoices,
  user,
  meters,
  getInvoices,
  getInvoicesReset,
  getMeters,
}) => {
  const [selectedMeterCode, setSelectedMeterCode] = useState(null);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [payPopupOpen, setPayPopupOpen] = useState(false);
  const [keksPayModalOpened, setKeksPayModalOpened] = useState(false);
  const [billValue, setBillValue] = useState(null);
  const [billValueEur, setBillValueEur] = useState(null);

  useEffect(() => {
    getInvoices(false);
    getMeters();

    return function cleanup() {
      getInvoicesReset();
    };
    //eslint-disable-next-line
  }, []);

  if (user && user.data && !user.data.userSynced) {
    return (
      <Row>
        <Col md={12}>
          <Styled.WelcomeHeader>
            <Text type={"h1"}>{l10n.dashboard.dashboardWelcomeTitle}</Text>
            <Text>{l10n.dashboard.dashboardWelcomeMessage}</Text>
          </Styled.WelcomeHeader>
        </Col>
      </Row>
    );
  }

  const renderWidgets = () => {
    const { result, loading } = meters;
    if (loading || !result || result.length === 0) return null;

    return (
      <Row>
        {result.map((meter) => (
          <Col xl={{ size: 2 }} md={4} sm={6} key={meter.meterId}>
            <DashboardWidget
              title={l10n.widget.balanceOrdinaryDebt}
              code={meter.meterSiteCode}
              value={meter.invoiceTotalBalanceDue}
              valueEur={meter.invoiceTotalBalanceDueEur}
              formatPrice
              onPayClicked={(meterCode) => onPayPopupOpen(meterCode, meter)}
            />
          </Col>
        ))}
      </Row>
    );
  };

  const onPayPopupOpen = (meterCode, meter) => {
    const filteredInvoices = filterInvoices().filter(
      (invoice) => invoice.meterCode === meterCode
    );

    setSelectedInvoices(filteredInvoices);
    setSelectedMeterCode(
      filteredInvoices && filteredInvoices.length > 0 ? meterCode : null
    );
    setKeksPayModalOpened(filteredInvoices && filteredInvoices.length > 0);
    setBillValue(meter.invoiceTotalBalanceDue);
    setBillValueEur(meter.invoiceTotalBalanceDueEur);
  };

  const onPayPopupClose = () => {
    setPayPopupOpen(false);
    setSelectedMeterCode(null);
    setSelectedInvoices([]);
    setBillValue(null);
    setBillValueEur(null);
  };

  const filterInvoices = () => {
    let notPayedInvoices = [];
    if (invoices && invoices.result) {
      notPayedInvoices = invoices.result.filter(
        (invoice) => invoice.isPayed === "Open"
      );
    }

    return notPayedInvoices;
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Styled.Header>
            <Text type={"h1"}>{l10n.dashboard.welcomeTitle}</Text>
            <Text>{l10n.dashboard.welcomeMessage}</Text>
            <Styled.DetailedHeader>
              Izmjene korisničkih podataka kupaca u portalu su dostupne s
              vremenskim odmakom od najviše 24 h radi dohvaćanja podataka iz
              poslovnog sustava. Uplate kupaca po računima su vidljive ovisno o
              vrsti, načinu i mjestu izvršenja uplate u roku 1-3 radna dana."
            </Styled.DetailedHeader>
          </Styled.Header>
        </Col>
      </Row>

      {renderWidgets()}

      <InvoiceList
        title={l10n.dashboard.outstandingInvoices}
        openInvoices={true}
        invoices={filterInvoices()}
        loaded={invoices.loaded}
      />

      <PayPopup
        invoices={selectedInvoices}
        popupOpen={payPopupOpen}
        meterCode={selectedMeterCode}
        buttonLabel={l10n.invoices.pay}
        onClose={onPayPopupClose}
      />

      <KeksPayModal
        popupOpen={keksPayModalOpened}
        meterCode={selectedMeterCode}
        invoices={selectedInvoices}
        billValue={billValue}
        billValueEur={billValueEur}
        buttonLabel={l10n.invoices.pay}
        onClose={(refresh) => {
          setKeksPayModalOpened(false);
          setSelectedMeterCode(null);
          setSelectedInvoices([]);
          setBillValue(null);
          setBillValueEur(null);

          if (refresh) {
            getInvoices();
          }
        }}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInvoices: (isReversed) => dispatch(getInvoices(isReversed)),
    getInvoicesReset: () => dispatch(getInvoicesReset()),
    getMeters: () => dispatch(getMeters()),
  };
};

export default connect(
  ({ invoices, user: { userData }, meters }) => ({
    invoices,
    user: userData,
    meters,
  }),
  mapDispatchToProps
)(Dashboard);
