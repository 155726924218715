import * as Joi from "joi-browser";

// categorySelected === 1 for Normal User, 2 for Company
const schema = (categorySelected) => {
  console.log(categorySelected);

  return Joi.object().keys({
    firstName:
      categorySelected === 1 ? Joi.string().required() : Joi.string().allow(""),
    lastName:
      categorySelected === 1 ? Joi.string().required() : Joi.string().allow(""),
    company:
      categorySelected === 2 ? Joi.string().required() : Joi.string().allow(""),
    telephone: Joi.string().allow(""),
    vat: Joi.string(),
    userCode: Joi.string().required(),
    email: Joi.string().email({ minDomainSegments: 2 }).required(),
    password: Joi.string().min(6).required(),
    passwordConfirm: Joi.string().valid(Joi.ref("password")).required(),
    policy: Joi.bool().invalid(false).required(),
  });
};

export default (formData, categorySelected) =>
  Joi.validate(
    formData,
    schema(categorySelected),
    { abortEarly: false },
    (err) => {
      if (!err) return null;
      let validationData = {
        firstName: null,
        lastName: null,
        company: null,
        telephone: null,
        vat: null,
        userCode: null,
        email: null,
        password: null,
        passwordConfirm: null,
        policy: null,
      };

      err.details.map(
        (error) => (validationData[error.path[0]] = error.message)
      );

      return validationData;
    }
  );
