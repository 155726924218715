import { Types } from "../actions/invoice.actions";

const INITIAL_STATE = {
  result: [],
  invoice: {},
  error: "",
  loaded: false,
  types: {
    result: [],
    loaded: false,
  },
};

export default function invoices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_INVOICES: {
      return {
        ...state,
        result: [],
        loaded: false,
      };
    }

    case Types.GET_INVOICES_SUCCESS: {
      let invoices = action.payload.map((invoice) => {
        invoice.downloadingPdf = false;
        return invoice;
      });
      return {
        ...state,
        result: invoices,
        loaded: true,
      };
    }

    case Types.GET_INVOICES_RESET: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }

    case Types.GET_INVOICE_PDF: {
      let invoices = [...state.result];
      const downloadingInvoiceIndex = invoices.findIndex(
        (invoice) => invoice.invoiceId === action.payload.invoiceId
      );
      invoices[downloadingInvoiceIndex].downloadingPdf = true;
      return {
        ...state,
        result: invoices,
      };
    }

    case Types.GET_INVOICE_PDF_SUCCESS: {
      let invoices = [...state.result];
      const downloadingInvoiceId = invoices.findIndex(
        (invoice) => invoice.invoiceId === action.payload.invoiceId
      );
      invoices[downloadingInvoiceId].downloadingPdf = false;

      return {
        ...state,
        result: invoices,
      };
    }

    case Types.GET_INVOICE_PDF_ERROR: {
      let invoices = [...state.result];
      const downloadingInvoiceId = invoices.findIndex(
        (invoice) => invoice.invoiceId === action.payload.invoiceId
      );
      invoices[downloadingInvoiceId].downloadingPdf = false;

      return {
        ...state,
        result: invoices,
      };
    }

    case Types.GET_INVOICE_TYPES: {
      return {
        ...state,
        types: {
          loaded: false,
          result: [],
        },
      };
    }

    case Types.GET_INVOICE_TYPES_SUCCESS: {
      return {
        ...state,
        types: {
          result: action.payload,
          loaded: true,
        },
      };
    }

    case Types.INVOICE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    default:
      return state;
  }
}
