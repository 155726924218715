import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  background: ${(props) => props.theme.colors[0]};
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 13px 10px;
  min-height: 100px;
  margin: 0 0 20px;
  height: calc(100% - 20px);
`;

export const Title = styled.p`
  color: ${(props) => props.theme.colors[19]};
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
`;

export const DataRow = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const DataRow2 = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 5px 0;
`;

export const DataKey = styled.p`
  color: ${(props) => props.theme.colors[22]};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin: 5px 0;
  margin-right: 8px;
  padding: 0;
`;

export const DataValue = styled.div`
  color: ${(props) => props.theme.colors[11]};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 0;
`;

export const DataCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PriceRow = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Price = styled.p`
  color: ${(props) => props.theme.colors[25]};
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
`;

export const PriceButton = styled.div`
  display: flex;
  flex: 1;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 25px;

  button {
    height: 40px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors[0]};
    background-color: ${(props) => props.theme.colors[18]};
    transition: 0.2s background-color ease-in;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[1]} !important;
    }
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors[17]};
  color: ${(props) => props.theme.colors[18]};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
  padding-bottom: 10px;
  text-align: center;
`;

export const ModalText = styled.p`
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  text-align: center;
`;

export const ModalAddress = styled.p`
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  text-align: center;
`;

export const ModalEmail = styled.p`
  color: ${(props) => props.theme.colors[18]};
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  text-align: center;
  width: 100%;
`;

export const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const ModalFooterText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin: 0 10px;
  padding: 0;
`;

export const ModalNoButton = styled.div`
  button {
    height: 40px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors[0]};
    background-color: ${(props) => props.theme.colors[31]};
    transition: 0.2s background-color ease-in;
    width: 100px;
    margin: 0 10px;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[1]} !important;
    }
  }
`;

export const ModalYesButton = styled.div`
  button {
    height: 40px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors[0]};
    background-color: ${(props) => props.theme.colors[18]};
    transition: 0.2s background-color ease-in;
    width: 100px;
    margin: 0 10px;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[1]} !important;
    }
  }
`;
