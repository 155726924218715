import styled from "styled-components";

export const Filters = styled.div`
  align-items: baseline;
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

export const FilterOptions = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 575px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FilterItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0;

  span,
  p,
  a {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
  }

  > span {
    color: ${(props) => props.theme.colors[27]};
    margin-right: 5px;
  }

  > div > div {
    padding: 0 20px 0 3px;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const FilterItem2 = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0;
  width: 200px;

  span,
  p,
  a {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
  }

  > span {
    color: ${(props) => props.theme.colors[27]};
    margin-right: 5px;
  }

  > div > div {
    padding: 0 20px 0 3px;
  }

  .react-datepicker__input-container {
    padding: 0;
  }

  .react-datepicker-wrapper {
    padding: 0;
    margin: 0;

    input {
      background-color: transparent;
      color: ${(props) => props.theme.colors[43]};
      cursor: pointer;
      border: none;
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 3px;
      text-align: center;
      width: 90px;
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;

    .react-datepicker-wrapper {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }
`;

export const FilterSwitch = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  width: 225px;

  @media (max-width: 575px) {
    margin-bottom: 20px;
    margin-right: 0;
    width: 100%;
  }
`;

export const FilterSpacer = styled.div`
  width: 10px;
`;

export const RangeFilter = styled.div`
  display: flex;

  @media (max-width: 575px) {
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
  }
`;
