import axios from "axios";

export const Types = {
  GET_USER: "user/get_user",
  GET_USER_SUCCESS: "user/get_user_success",
  GET_USER_ERROR: "user/get_user_error",
  GET_USER_RESET: "user/get_user_reset",
  LOGIN_USER: "user/login_user",
  LOGIN_USER_SUCCESS: "user/login_user_success",
  LOGIN_USER_ERROR: "user/login_user_error",
  USER_RESET: "user/get_user_reset",
};

export const getUserInfo = () => ({
  type: Types.GET_USER,
});

export const getUserSuccess = (user) => ({
  type: Types.GET_USER_SUCCESS,
  payload: user,
});

export const getUserError = (error) => ({
  type: Types.GET_USER_ERROR,
  payload: error,
});

export const getUserReset = (error) => ({
  type: Types.GET_USER_RESET,
  payload: error,
});

export const loginUser = (credentials) => ({
  type: Types.LOGIN_USER,
  payload: credentials,
});

export const userLoggedIn = ({ token, userId }) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  localStorage.setItem("auth", JSON.stringify({ token, userId }));
  return {
    type: Types.LOGIN_USER_SUCCESS,
    payload: { token, userId },
  };
};

export const logoutUser = () => ({
  type: Types.USER_RESET,
});

export const resetUser = () => ({
  type: Types.USER_RESET,
});
