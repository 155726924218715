import styled from "styled-components";
import { Link as RLink } from "react-router-dom";

export const Link = styled(RLink)`
  color: ${(props) => props.theme.colors[8]};
  &:hover {
    color: ${(props) => props.theme.colors[8]};
  }
`;

export const minimumNewPosition = styled.span`
  color: ${(props) => props.theme.colors[8]};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[23]};
`;

export const HeaderText = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[27]};
`;

export const HeaderLink = styled.a``;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const OptionText = styled.span`
  color: ${(props) => props.theme.colors[27]};
`;

export const InputWrap = styled.div`
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: middle;

  &:last-of-type {
    margin-bottom: 0;
  }

  span {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    display: inline-block;
  }
`;

export const Text = styled.div`
  margin: 10px 0;
`;

export const MeterDataView = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors[17]};
  display: flex;
  flex-direction: column;
  padding: 10px 10px 15px;
`;

export const MeterDataRow = styled.div`
  display: flex;
`;

export const MeterDataKey = styled.p`
  margin: 0 10px 0 0;
  padding: 0;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.theme.colors[27]};
`;

export const MeterDataValue = styled.p`
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.theme.colors[27]};
`;

export const MeterReadingView = styled.div`
  padding: 10px 10px 15px;
`;

export const MeterReadingRow = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 5px;
`;

export const MeterReadingKey = styled.p`
  margin: 0 10px 0 0;
  padding: 0;
  min-width: 150px;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.theme.colors[27]};
`;

export const MeterReadingValue = styled.div`
  input {
    font-size: 13px;
    line-height: 19px;
    border: none;
    background-color: transparent;
    border-radius: 0;
    height: 35px;
    border-bottom: 1px solid ${(props) => props.theme.colors[31]};
    padding: 0;
    max-width: 100%;
    min-width: 300px;
  }

  @media (max-width: 1024px) {
    input {
      min-width: 0;
    }
  }
`;

export const SubmitButtonView = styled.div`
  margin-top: 25px;

  button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors[0]};
    background-color: ${(props) => props.theme.colors[21]};
    transition: 0.2s background-color ease-in;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[29]} !important;
    }
  }
`;

export const Card = styled.div`
  margin: 0 0 25px 0;
  background-color: ${(props) => props.theme.colors[0]};
  border-radius: 4px;
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
`;

export const LastPositionError = styled.span`
  display: inline;

  p {
    margin: 0;
    padding: 0;
  }
`;
