export const Types = {
  ACTIVATE_USER: "user/activate_user",
  ACTIVATE_USER_SUCCESS: "user/activate_user_success",
  ACTIVATE_USER_ERROR: "user/activate_user_error",
  ACTIVATE_USER_RESET: "user/activate_user_reset"
};

export const activateUser = token => ({
  type: Types.ACTIVATE_USER,
  payload: { token }
});

export const activateUserSuccess = () => ({
  type: Types.ACTIVATE_USER_SUCCESS
});

export const activateUserError = error => ({
  type: Types.ACTIVATE_USER_ERROR,
  payload: error
});

export const activateUserReset = () => ({
  type: Types.ACTIVATE_USER_RESET
});
