import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getInvoices,
  getInvoicePdf,
  markInvoiceAsPayed,
  payAllOnline,
} from "../../redux/actions/invoice.actions";
import l10n from "../../localization";
import * as qs from "query-string";
import Modal from "../Modal";
import { isBrowser } from "react-device-detect";
import InvoiceListMobile from "./_components/InvoiceListMobile";
import InvoiceListDesktop from "./_components/InvoiceListDesktop";
import Loader from "../../components/Loader";
import { get2dCode } from "../../redux/actions/TwoDCode.actions";
import PayPopup from "../../components/PayPopup";
import KeksPayModal from "../KeksPayModal/KeksPayModal";

function InvoiceList(props) {
  const [openSum, setOpenSum] = useState(0);
  const [transactionResponse, setTransactionResponse] = useState({});
  const [twoDCodeModal, setTwoDCodeModal] = useState(false);
  const [twoDCode, setTwoDCode] = useState(false);
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [selectedMeterCode, setSelectedMeterCode] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [keksPayModalOpened, setKeksPayModalOpened] = useState(false);

  useEffect(() => {
    // parse WSPay transaction
    const queryString = qs.parse(props.location.search);
    if (queryString.transaction) {
      let transactionMessage = "";
      switch (queryString.transaction) {
        case "error":
          transactionMessage =
            l10n.invoices.paymentError + queryString.ErrorMessage;
          break;
        case "success":
          transactionMessage = generateSuccessPayedMessage();
          break;
        case "canceled":
          transactionMessage = l10n.invoices.paymentCanceled;
          break;
        default:
          transactionMessage = l10n.invoices.paymentUnknown;
      }
      setTransactionResponse({
        visible: true,
        message: transactionMessage,
      });

      if (queryString.ShoppingCartID && queryString.WsPayOrderId) {
        if (queryString.All === "1") {
          props.payAllOnline(
            queryString.ShoppingCartID,
            queryString.WsPayOrderId
          );
        } else {
          props.markInvoiceAsPayed(
            queryString.ShoppingCartID,
            queryString.WsPayOrderId
          );
        }
      }
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let newSum = 0;
    (props.invoices || []).forEach((invoice) => {
      newSum += invoice.invoiceDebt;
    });

    setOpenSum(newSum.toFixed(2));
  }, [props.invoices]);

  useEffect(() => {
    if (props.twoDCode.success) setTwoDCode(props.twoDCode.data);
  }, [props.twoDCode]);

  const openPDFInvoice = (invoiceId) => {
    props.getInvoicePdf(invoiceId);
  };

  const show2dCode = (invoiceId) => {
    props.get2dCode(invoiceId);
    setTwoDCodeModal(true);
  };

  // Payment

  const onPayClicked = (invoice) => {
    setSelectedMeterCode(invoice.meterCode);
    setSelectedInvoice(invoice);
    setPayModalOpen(true);
  };

  const onKeksPayClicked = (invoice) => {
    setSelectedMeterCode(invoice.meterCode);
    setSelectedInvoice(invoice);
    setKeksPayModalOpened(true);
  };

  const generateSuccessPayedMessage = () => {
    return (
      <div>
        <p style={{ textAlign: "center", color: "#28a745" }}>
          <strong>{l10n.invoices.paymentSuccessTitle}</strong>
        </p>
      </div>
    );
  };

  if (!props.loaded) return <Loader />;

  return (
    <React.Fragment>
      {!isBrowser && (
        <InvoiceListMobile
          title={props.title}
          invoices={props.invoices}
          openInvoices={props.openInvoices}
          openSum={openSum}
          onOpenInvoice={openPDFInvoice}
          show2dCode={show2dCode}
          onKeksPayClicked={onKeksPayClicked}
        />
      )}
      {isBrowser && (
        <InvoiceListDesktop
          title={props.title}
          invoices={props.invoices}
          openInvoices={props.openInvoices}
          openSum={openSum}
          onOpenInvoice={openPDFInvoice}
          show2dCode={show2dCode}
          onPayClicked={onPayClicked}
          onKeksPayClicked={onKeksPayClicked}
        />
      )}
      <Modal
        open={transactionResponse.visible}
        title={l10n.invoices.paymentModalTitle}
        footer={true}
        onClose={() => setTransactionResponse({})}
      >
        {transactionResponse.message}
      </Modal>

      <Modal
        open={twoDCodeModal}
        title={l10n.invoices.twodcode}
        footer={true}
        onClose={() => {
          setTwoDCode(null);
          setTwoDCodeModal(false);
        }}
      >
        {l10n.invoices.twodcodeText}
        <br />
        <div className="text-center">
          {!twoDCode ? (
            <Loader inline />
          ) : (
            <img
              style={{
                width:
                  window.innerWidth > 450
                    ? "402px"
                    : window.innerWidth * 0.8 + "px",
                height: window.innerWidth > 450 ? "132px" : "auto",
              }}
              src={twoDCode}
              alt="2d-code"
            />
          )}
        </div>
      </Modal>

      <PayPopup
        popupOpen={payModalOpen}
        meterCode={selectedMeterCode}
        invoices={[selectedInvoice]}
        buttonLabel={l10n.invoices.pay}
        onClose={() => {
          setSelectedMeterCode(null);
          setSelectedInvoice(null);
          setPayModalOpen(false);
        }}
      />

      <KeksPayModal
        popupOpen={keksPayModalOpened}
        meterCode={selectedMeterCode}
        invoices={[selectedInvoice]}
        buttonLabel={l10n.invoices.pay}
        onClose={(refresh) => {
          setKeksPayModalOpened(false);
          setSelectedMeterCode(null);
          setSelectedInvoice(null);

          if (refresh) {
            props.getInvoices();
          }
        }}
      />
    </React.Fragment>
  );
}

InvoiceList.propTypes = {
  openInvoices: PropTypes.bool,
};

InvoiceList.defaultProps = {
  openInvoices: false,
};

export default connect(({ twoDCode }) => ({ twoDCode }), {
  getInvoicePdf,
  markInvoiceAsPayed,
  payAllOnline,
  get2dCode,
  getInvoices,
})(withRouter(InvoiceList));
