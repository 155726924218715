export const Types = {
  NOTIFICATION_ERROR: "notification/notification_error",
  GET_NOTIFICATIONS: "notification/get_notifications",
  GET_NOTIFICATIONS_SUCCESS: "notification/get_notifications_success",
  MARK_READ: "notification/mark_read",
  MARK_ALL_READ_NOTIFICATIONS: "notification/read_all"
};

export const getNotifications = () => ({
  type: Types.GET_NOTIFICATIONS
});

export const markReadNotification = notificationId => ({
  type: Types.MARK_READ,
  payload: notificationId
});

export const getNotificationsSuccess = notifications => ({
  type: Types.GET_NOTIFICATIONS_SUCCESS,
  payload: notifications
});

export const markAllReadNotifications = () => ({
  type: Types.MARK_ALL_READ_NOTIFICATIONS
});

export const notificationError = error => ({
  type: Types.NOTIFICATION_ERROR,
  payload: error
});
