import styled from "styled-components";

export default {
  Card: styled.div`
    background: ${(props) => props.theme.colors[0]};
    box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
    border-radius: 4px;
    padding: 0 0 12px;
    margin: 0 0 25px 0;
    overflow-y: auto;

    @media (min-width: 1200px) {
      td {
        min-width: 105px;
      }
    }
  `,
  Header: styled.div`
    margin: 0;
    padding: 12px 24px;
    border-bottom: 1px solid ${(props) => props.theme.colors[17]};
    button {
      padding: 4px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.colors[0]};
      background-color: ${(props) => props.theme.colors[21]};
      transition: 0.2s background-color ease-in;

      &:hover,
      &:active,
      &:focus {
        background-color: ${(props) => props.theme.colors[29]} !important;
      }
    }
  `,
  Table: styled.table`
    width: 100%;

    tr {
      border-collapse: collapse;
    }

    thead {
      tr {
        border-bottom: 1px solid ${(props) => props.theme.colors[17]};
      }
    }

    tbody {
      tr:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.colors[17]};
      }
    }

    tr > th:first-of-type {
      padding-left: 19px;
    }

    tr > th:last-of-type {
      padding-right: 19px;
    }

    tr > td:first-of-type {
      padding-left: 19px;
    }

    tr > td:last-of-type {
      padding-right: 19px;
    }

    td:not(:first-of-type) {
      text-align: center;
    }

    th:not(:first-of-type) {
      text-align: center;
    }

    th:first-of-type {
      width: 50px;
    }

    th,
    td {
      font-size: 13px;
      line-height: 19px;
      padding: 22px 5px;
      vertical-align: middle;
    }

    th {
      color: ${(props) => props.theme.colors[18]};
      opacity: 0.5;
    }

    td {
      color: ${(props) => props.theme.colors[22]};
    }
  `,
  Title: styled.h2`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[23]};
    margin: 0;
  `,
};
