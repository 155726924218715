import { createGlobalStyle } from "styled-components";

const DefaultTheme = {
  id: "HeaderNotification",
  colors: [
    "#FFFFFF", //0
    "#808080", //1
    "#00ad5f", //2
    "#f7f7f7", //3
    "#f2f2f2", //4
    "#f5f5f5", //5
    "#555555", //6
    "#e5e5e5", //7
    "#ff4b5a", //8
    "#a9b3c9", //9
    "#000000", //10
    "#333333", //11
    "#4272d7", //12
    "rgba(0, 0, 0, 0.1)", //13
    "#647882", //14
    "#ff7800", //15
    "rgba(0, 0, 0, 0.06)", //16
    "#ebeff2", //17
    "#334d6e", //18
    "#305379", //19
    "rgba(0, 0, 0, 0.08)", //20
    "#F7685B", //21
    "#707683", //22
    "#323C47", //23
    "#f5f6f8", //24
    "#ff0000", //25
    "#2ED47A", //26
    "#6A707E", //27
    "#192A3E", //28
    "#f54a3a", //29
    "#004cff", //30
    "#D3D8DD", //31
    "#999999", //32
    "rgba(255, 255, 255, 0.6)", //33
    "rgba(255, 255, 255, 0.24)", //34
    "rgba(255, 255, 255, 0.12)", //35
    "#e3f2fd", //36
    "#999999", //37
    "#dddddd", //38
    "rgba(25, 42, 62, 0.6)", // 39,
    "#FFA500", // 40
    "#ffdb99", // 41,
    "#1B5A9A", // 42,
    "#0D9DDB", // 43
  ],
  text: {
    h1: {
      fontSize: "25px",
      margin: "0 0 25px 0",
    },
    h2: {
      fontSize: "22px",
      margin: "0 0 25px 0",
    },
    h3: {
      fontSize: "20px",
      margin: "0 0 25px 0",
    },
    h4: {
      fontSize: "18px",
      margin: "0 0 25px 0",
    },
    h5: {
      fontSize: "16px",
      margin: "0 0 25px 0",
    },
    p: {
      fontSize: "13px",
      lineHeight: "19px",
      margin: "0 0 15px 0",
    },
  },
  font: {
    light: {
      weight: 300,
    },
    regular: {
      weight: 400,
    },
    bold: {
      weight: 600,
    },
  },
};

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Oswald:400,500|Poppins:400,500,700|Roboto&subset=latin,latin-ext');
  body{
    margin:0;
    padding:0;
  }

  *{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  a{
    color:#4272d7;
  }
`;

export default DefaultTheme;
