import React from "react";
import PropTypes from "prop-types";
import Styled from "./DashboardWidget.styled";
import Helpers from "../../helpers/helpers";
import { Button } from "reactstrap";
import l10n from "../../localization";

const DashboardWidget = ({
  title,
  code,
  value,
  valueEur,
  negative,
  formatPrice,
  onPayClicked,
}) => {
  return (
    <Styled.Widget>
      <Styled.Title>
        {`${title} `}
        <b>{code}</b>
      </Styled.Title>
      <Styled.Footer>
        <Styled.WidgetNumber negative={value > 0 || negative === true}>
          {formatPrice ? Helpers.formatPrice(value) : value}{" "}
        </Styled.WidgetNumber>

        {value > 0 && (
          <Styled.WidgetButton>
            <Button onClick={() => onPayClicked(code)}>
              {l10n.widget.pay}
            </Button>
          </Styled.WidgetButton>
        )}

      </Styled.Footer>
    </Styled.Widget>
  );
};

DashboardWidget.propTypes = {
  negative: PropTypes.bool,
  formatPrice: PropTypes.bool,
};

DashboardWidget.defaultProps = {
  negative: null,
  formatPrice: false,
};

export default DashboardWidget;
