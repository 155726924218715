import React from "react";
import PersonalDataForm from "./_components/PersonalDataForm";
import * as Styled from "./profile.styles";
import { connect } from "react-redux";
import { getUserInfo } from "../../redux/actions/user/user.actions";
import l10n from "../../localization";

const Profile = (props) => {
  return (
    <React.Fragment>
      <Styled.Header>
        <Styled.Title type="h1">{l10n.profile.profile}</Styled.Title>
      </Styled.Header>

      <PersonalDataForm />
    </React.Fragment>
  );
};

export default connect(({ user }) => ({ user }), { getUserInfo })(Profile);
