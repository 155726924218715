import React from "react";
import * as Styled from "./Logo.styles";
import LogoImage from "../../../assets/logo-vodovod-split.png";

export default function Logo() {
  return (
    <Styled.Logo background-color={"#ffffff"}>
      <img src={LogoImage} alt="Vodovod Split" />
    </Styled.Logo>
  );
}
