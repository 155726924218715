import React from "react";
import Styled from "./info.styles";

const Info = () => {
  return (
    <>
      <Styled.Card>
        <Styled.Body>
          <div>
            Primanje računa na mail -{" "}
            <a
              href="https://www.vik-split.hr/eracun"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.vik-split.hr/eracun
            </a>
          </div>
          <div>
            Najčešće postavljena pitanja -
            <a
              href="https://www.vik-split.hr/centar-za-potrosace/najcesce-postavljena-pitanja"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.vik-split.hr/centar-za-potrosace/najcesce-postavljena-pitanja
            </a>
          </div>
        </Styled.Body>
      </Styled.Card>
    </>
  );
};

export default Info;
