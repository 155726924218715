import styled from "styled-components";

export const LinkHref = styled.a`
  color: ${props => props.theme.colors[8]};
  cursor: pointer;
  font-weight: bold;
  &:hover {
    color: ${props => props.theme.colors[8]};
  }
`;
