import styled from "styled-components";

export const notificationWrap = styled.div`
  font-size: 25px;
  color: ${props => props.theme.colors[14]};
  position: relative;
  margin: 0 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    position: absolute;
    top: 19px;
    right: -10px;
    font-size: 12px;
    color: ${props => props.theme.colors[0]};
    background: ${props => props.theme.colors[8]};
    border-radius: 50%;
    min-width: 15px;
    padding: 0 5px;
    height: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;
