import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userLoggedIn, getUserInfo } from "../redux/actions/user/user.actions";

const ProtectedRoute = (props) => {
  useEffect(() => {
    if (!props.user.userData.success && !props.user.userData.loading) {
      const existingAuth = JSON.parse(localStorage.getItem("auth"));
      if (existingAuth) {
        props.userLoggedIn(existingAuth);
        props.getUserInfo();
      }
    }
    //eslint-disable-next-line
  }, [props.user.userData]);

  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(rest) =>
        props.user.userData.success ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: rest.location },
            }}
          />
        )
      }
    />
  );
};

export default connect(({ user }) => ({ user }), { userLoggedIn, getUserInfo })(
  ProtectedRoute
);
