import { Types } from "../../actions/user/resendUserActivationMail.actions";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: null
};

export default function resendUserActivationMail(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case Types.RESEND_USER_ACTIVATION_EMAIL: {
      return {
        ...state,
        ...INITIAL_STATE,
        loading: true
      };
    }

    case Types.RESEND_USER_ACTIVATION_EMAIL_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }

    case Types.RESEND_USER_ACTIVATION_EMAIL_ERROR: {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    }

    case Types.RESEND_USER_ACTIVATION_EMAIL_RESET: {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
}
