import styled from "styled-components";

export const Header = styled.div`
  display: flex;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors[42]};
  flex-grow: 1;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin: 0 0 10px;
`;

export const Code = styled.div`
  align-items: baseline;
  display: flex;
`;

export const CodeText = styled.p`
  color: ${(props) => props.theme.colors[23]};
  font-family: Poppins;
  font-style: normal;
  font-size: 14px;
  margin: 0;
  margin-right: 5px;
  padding: 0;
`;

export const CodeValue = styled.p`
  color: ${(props) => props.theme.colors[23]};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;
