import styled from "styled-components";

export const DownloadLink = styled.span`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > svg {
    height: 26px;
    width: 26px;
  }
`;
