import styled from "styled-components";
import { Link as RLink } from "react-router-dom";

export const Link = styled(RLink)`
  color: ${(props) => props.theme.colors[8]};
  &:hover {
    color: ${(props) => props.theme.colors[8]};
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[23]};
  margin: 0 0 25px;
`;

export const Description = styled.span`
  color: ${(props) => props.theme.colors[27]};
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin: 0 0 20px;
  padding: 0;
`;

export const ActivateSection = styled.div`
  display: flex;
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors[0]};
    background-color: ${(props) => props.theme.colors[21]};
    transition: 0.2s background-color ease-in;
    min-width: 130px;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[29]} !important;
    }

    &:disabled {
      background-color: grey !important;
    }
  }
`;

export const CancelationTextTop = styled.p`
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin: 0 0 30px;
  padding: 0;
`;

export const CancelationText = styled.p`
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin: 0 0 80px;
  padding: 0;
`;

export const ErrorSection = styled.div``;

export const DescriptionText = styled.p`
  margin: 0;
  margin-right: 5px;
  padding: 0;
`;

export const DescriptionEmail = styled.p`
  font-weight: bold;
  margin: 0;
  padding: 0;

  a {
    color: ${(props) => props.theme.colors[8]};
    &:hover {
      color: ${(props) => props.theme.colors[8]};
    }
  }
`;

export const AlreadyActivated = styled.p`
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
  ${(props) => props.theme.colors[23]};
`;

export const Card = styled.div`
  margin: 0 0 20px 0;
  background-color: ${(props) => props.theme.colors[0]};
  border-radius: 4px;
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};

  table {
    th {
      border-top: none;
      border-bottom: 1px solid ${(props) => props.theme.colors[17]};
      padding: 20px;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colors[18]};
      vertical-align: middle;
      width: 50%;

      &:first-of-type {
        padding-left: 24px;
      }

      &:last-of-type {
        padding-left: 0;
      }
    }

    td {
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colors[22]};
      padding-left: 0;

      &:first-of-type {
        padding-left: 24px;
      }
    }

    thead {
    }

    tbody {
      tr:not(:last-of-type) {
        td {
          border-bottom: 1px solid ${(props) => props.theme.colors[17]};
        }
      }
    }
  }
`;

export const Modal = styled.div`
  .modal-header {
    border-bottom: 1px solid ${(props) => props.theme.colors[17]};
    padding: 20px;
  }
  .modal-title {
    font-size: 15px;
    line-height: 22px;
    color: ${(props) => props.theme.colors[23]};
  }
  .modal-body {
    font-size: 13px;
    line-height: 19px;
    color: ${(props) => props.theme.colors[27]};
    padding: 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors[17]};
  }
  .modal-footer {
    .btn-primary {
      background-color: ${(props) => props.theme.colors[21]};
      border: none;
    }
    .btn-secondary {
      border: none;
    }
  }
`;
