import React from "react";
import { CardBody } from "reactstrap";
import { Bar } from "react-chartjs-2";
import * as Styled from "./barGraph.styles";
import moment from "moment/moment";
import l10n from "../../localization";
import theme from "../../theme/default";
import Helpers from "../../helpers/helpers";

const BarGraph = ({ readings, type }) => {
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, "rgba(13, 157, 219, 0.5)");
    gradient.addColorStop(1, "rgba(13, 157, 219, 0)");

    const dates = readings
      ? readings.map((reading) => {
          return moment(
            type === "readings" ? reading.dateTo : reading.invoiceIssueDate
          ).format("MM/YY");
        })
      : [];

    return {
      labels: dates,
      datasets: [
        {
          label: type === "readings" ? "Consumptions" : "Invoices",
          backgroundColor: theme.colors[18],
          data: readings
            ? readings.map((reading) => {
                return type === "readings"
                  ? reading.consumption
                  : reading.invoiceValue;
              })
            : [],
          pointBorderColor: "#109CF1",
          pointBackgroundColor: "#109CF1",
          pointBorderWidth: 2,
          pointHitRadius: 15,
          pointHoverBackgroundColor: "#109CF1",
          pointHoverBorderWidth: 4,
        },
      ],
    };
  };

  return (
    <Styled.Card>
      <CardBody style={{ padding: "5px" }}>
        <Bar
          options={{
            tooltips: {
              displayColors: false,
              backgroundColor: "rgba(25, 42, 62, 0.75)",
              enabled: true,
              mode: "label",
              bodyFontSize: 13,
              bodyFontColor: "white",
              yPadding: 6,
              xPadding: 8,
              cornerRadius: 12,
              callbacks: {
                title: (tooltipItems) => {
                  return tooltipItems[0].xLabel;
                },
                afterLabel: (tooltipItems) => {
                  if (readings.length > tooltipItems.index) {
                    const data = readings[tooltipItems.index];

                    if (data.dateTo) {
                      return `${l10n.reports.perod_to} ${moment(
                        data.dateTo
                      ).format("DD.MM.YYYY")}`;
                    }
                  }
                  return "";
                },
                beforeLabel: (tooltipItems) => {
                  let value = tooltipItems.yLabel;

                  if (!isNaN(value)) {
                    value = Helpers.formatNumber(value);
                  }

                  if (type === "readings") {
                    return `${l10n.reports.accountedConsumption}: ${value} m3`;
                  }

                  return `${l10n.reports.accountedConsumption}: ${value} ${l10n.general.currency}`;
                },
                label: (tooltipItems) => {
                  if (readings.length > tooltipItems.index) {
                    const data = readings[tooltipItems.index];

                    if (type === "readings") {
                      if (data.dateFrom) {
                        return `${l10n.reports.period_from} ${moment(
                          data.dateFrom || ""
                        ).format("DD.MM.YYYY")}`;
                      }
                    } else {
                      return `${l10n.invoices.issueDate}: ${moment(
                        data.invoiceIssueDate
                      ).format("DD.MM.YYYY")}`;
                    }
                  }
                  return "";
                },
              },
            },
            legend: {
              display: false,
            },
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                    borderDash: [10, 10],
                    color: "#c4c4c4",
                    zeroLineBorderDash: [10, 10],
                    zeroLineColor: "#c4c4c4",
                  },
                  ticks: {
                    fontColor: "#4C5862",
                    fontSize: 12,
                    min: 0,
                    padding: 8,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false,
                    min: 0,
                  },
                  ticks: {
                    fontColor: "#4C5862",
                    fontSize: 12,
                    autoSkip: false,
                    maxRotation: 45,
                    minRotation: 45,
                    min: 0,
                    autoSkipPadding: 0,
                    padding: 0,
                  },
                },
              ],
            },
            maintainAspectRatio: false,
            animation: false,
          }}
          data={data}
        />
      </CardBody>
    </Styled.Card>
  );
};

export default BarGraph;
