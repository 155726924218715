import React, { useState } from "react";
import Helpers from "../../../../../../helpers/helpers";
import moment from "moment";
import Styled from "./InvoiceEntry.styles";
import l10n from "../../../../../../localization";
import {
  IconBarCode,
  //IconPayBill,
  IconPdf,
} from "../../../../../../components/Icons/Icons";
import { Tooltip } from "reactstrap";
import Loader from "../../../../../Loader";
import kekspayLogo from "../../../../../../assets/kekspay_logo.svg";


export default ({ invoice, onOpenInvoice, show2dCode, onKeksPayClicked }) => {
  const [reversedTooltipOpened, setReversedTooltipOpened] = useState(false);
  const [enforcementTooltipOpened, setEnforcementTooltipOpened] =
    useState(false);

  return (
    <React.Fragment>
      <Styled.InvoiceWrapper key={invoice.invoiceId}>
        <Styled.IsReversed>
          {invoice.isReversed && (
            <>
              <span id={`reversedLabel_${invoice.invoiceId}`}>S</span>
              <Tooltip
                placement="top"
                isOpen={reversedTooltipOpened}
                target={`reversedLabel_${invoice.invoiceId}`}
                toggle={() => setReversedTooltipOpened(!reversedTooltipOpened)}
              >
                {l10n.einvoice.isReversed}
              </Tooltip>
            </>
          )}
          {invoice.isEnforcement && (
            <>
              <span id={`enforcementLabel_${invoice.invoiceId}`}>O</span>
              <Tooltip
                placement="top"
                isOpen={enforcementTooltipOpened}
                target={`enforcementLabel_${invoice.invoiceId}`}
                toggle={() =>
                  setEnforcementTooltipOpened(!enforcementTooltipOpened)
                }
              >
                {l10n.einvoice.isEnforcement}
              </Tooltip>
            </>
          )}
        </Styled.IsReversed>

        <td>{invoice.meterCode}</td>
        <td className="weight-medium">{invoice.invoiceNumber}</td>
        <td>{moment(invoice.invoiceIssueDate).format("DD.MM.YYYY")}</td>
        <td>
          {invoice.invoiceExpirationDate
            ? moment(invoice.invoiceExpirationDate).format("DD.MM.YYYY")
            : "-"}
        </td>
        <td>
          <strong>{Helpers.formatPrice(invoice.invoiceValue)}</strong>
        </td>
        <Styled.PriceWrapper negative={invoice.invoiceDebt !== 0}>
          <strong>{Helpers.formatPrice(invoice.invoiceDebt)}</strong>
        </Styled.PriceWrapper>
        <td>
          {invoice.downloadingPdf ? (
            <div style={{ paddingLeft: "20px", width: "100%" }}>
              <Loader inline={true} size={24} />
            </div>
          ) : (
            <Styled.HoverElement
              onClick={(e) => {
                e.stopPropagation();
                onOpenInvoice(invoice.invoiceId);
              }}
            >
              <IconPdf></IconPdf>
            </Styled.HoverElement>
          )}
        </td>
        <td>
          {invoice.isPayed && invoice.isPayed === "Open" && (
            <Styled.HoverElement
              onClick={(e) => {
                e.stopPropagation();
                show2dCode(invoice.invoiceId);
              }}
            >
              <IconBarCode />
            </Styled.HoverElement>
          )}
        </td>

        <td>
          {invoice.isPayed && invoice.isPayed === "Open" && (
            <Styled.PayButton
              color="success"
              onClick={() => onKeksPayClicked(invoice)}
            >
              <Styled.KeksPayLogo>
                <img src={kekspayLogo} alt="KEKS Pay logo" />
              </Styled.KeksPayLogo>
            </Styled.PayButton>
          )}
        </td>

      </Styled.InvoiceWrapper>
    </React.Fragment>
  );
};
