import styled from "styled-components";

export const Logo = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors[0]};
  box-shadow: 0 2px 5px 0 ${(props) => props.theme.colors[13]};
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  width: 100%;

  img {
    height: auto;
    max-width: 150px;
    padding: 0 5px;
  }

  @media (max-width: 1024px) {
    box-shadow: none;
    height: auto;
  }
`;

export const Title = styled.p`
  color: ${(props) => props.theme.colors[19]};
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: 0.01em;
  margin: 0;
  padding: 0;
`;
