import React, { useEffect, useState } from "react";
import * as Styled from "./meters.styles";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Helpers from "../../helpers/helpers";
import l10n from "../../localization";
import { getMeters, getMetersReset } from "../../redux/actions/meter.actions";

const Meters = (props) => {
  const [metersModalOpened, setMetersModalOpened] = useState(false);
  //const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    if (!props.meters.loaded) props.getMeters();
    //eslint-disable-next-line
  }, []);

  const renderResults = () => {
    if (props.meters.loading) return <Loader />;
    if (props.meters.result.length === 0) return l10n.meters.noResult;

    return props.meters.result.map((meter) => {
      return (
        <Col key={meter.meterSiteCode} xs={12} md={6} lg={4}>
          <Styled.Item>
            <Styled.Title>
              {l10n.formatString(l10n.meters.locationCode, meter.meterSiteCode)}
            </Styled.Title>
            <Styled.DataRow>
              <Styled.DataKey>{l10n.meters.client}</Styled.DataKey>
              <Styled.DataValue>{meter.partyName || "-"}</Styled.DataValue>
            </Styled.DataRow>
            <Styled.DataRow>
              <Styled.DataKey>{l10n.meters.vatId}</Styled.DataKey>
              <Styled.DataValue>{meter.vatId || "-"}</Styled.DataValue>
            </Styled.DataRow>
            <Styled.DataRow2>
              <Styled.DataCol className="mr-1">
                <Styled.DataKey>{l10n.meters.location}</Styled.DataKey>
                <Styled.DataCol>
                  <Styled.DataValue>{meter.address}</Styled.DataValue>
                </Styled.DataCol>
              </Styled.DataCol>
              <Styled.DataCol className="ml-1">
                <Styled.DataKey>{l10n.meters.invoiceAddress}</Styled.DataKey>
                <Styled.DataCol>
                  <Styled.DataValue>{meter.deliveredBy}</Styled.DataValue>
                </Styled.DataCol>
              </Styled.DataCol>
            </Styled.DataRow2>
            <Styled.Footer>
              <Styled.PriceRow>
                <Styled.DataKey>{l10n.meters.toPay}</Styled.DataKey>
                <Styled.Price>
                  {Helpers.formatPrice(meter.invoiceTotalBalanceDue)}
                </Styled.Price>
              </Styled.PriceRow>
              {/* <Styled.PriceButton>
                <Button
                  color="primary"
                  onClick={() => setMetersModalOpened(true)}
                >
                  {l10n.meters.changeAddress}
                </Button>
              </Styled.PriceButton> */}
            </Styled.Footer>
          </Styled.Item>
        </Col>
      );
    });
  };

  return (
    <Styled.Wrapper>
      <Row>{renderResults()}</Row>
      <Modal open={metersModalOpened} footer={false}>
        <Styled.ModalWrapper>
          <Styled.ModalTitle>
            {l10n.meters.changeAddressModalTitle}
          </Styled.ModalTitle>
          <Styled.ModalText>
            {l10n.formatString(l10n.meters.changeAddressModalMessage, 9999)}
          </Styled.ModalText>
          <Styled.ModalAddress>Split 1, 1234 Split</Styled.ModalAddress>
          <Styled.ModalText>
            {l10n.meters.changeAddressModalEmail}
          </Styled.ModalText>
          <Styled.ModalEmail>test@test.com</Styled.ModalEmail>
          <Styled.ModalFooter>
            <Styled.ModalFooterText>
              {l10n.meters.changeAddressModalFooter}
            </Styled.ModalFooterText>
            <Styled.ModalNoButton>
              <Button onClick={() => setMetersModalOpened(false)}>
                {l10n.meters.changeAddressModalNo}
              </Button>
            </Styled.ModalNoButton>
            <Styled.ModalYesButton>
              <Button onClick={() => setMetersModalOpened(false)}>
                {l10n.meters.changeAddressModalYes}
              </Button>
            </Styled.ModalYesButton>
          </Styled.ModalFooter>
        </Styled.ModalWrapper>
      </Modal>
    </Styled.Wrapper>
  );
};

export default connect(({ meters }) => ({ meters }), {
  getMeters,
  getMetersReset,
})(Meters);
