import styled from "styled-components";

export const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.colors[7]};
  border-radius: 4px;
  margin-top: 15px;
  padding: 10px;
`;

export const MeterInvoiceRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const MeterInvoiceKey = styled.p`
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

export const MeterInvoiceValue = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
`;
