import styled, { css } from "styled-components";

export default {
  changeLayout: styled.span`
    color: gray;
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    ${(props) =>
      props.active
        ? css`
            color: ${props.theme.colors[43]};
            font-weight: 600;
          `
        : ""}
  `,
};
