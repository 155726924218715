import styled from "styled-components";

export default {
  Widget: styled.div`
    justify-content: space-between;
    background: ${(props) => props.theme.colors[0]};
    box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 13px 10px;
    min-height: 100px;
    margin: 0 0 20px;
    height: calc(100% - 20px);

    button {
      padding: 8px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      text-align: center;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.colors[0]};
      background-color: ${(props) => props.theme.colors[21]};
      transition: 0.2s background-color ease-in;

      &:hover,
      &:active,
      &:focus {
        background-color: ${(props) => props.theme.colors[29]} !important;
      }
    }
  `,

  Title: styled.p`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[19]};
    margin: 0;
    padding: 0;
  `,

  Footer: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  `,

  WidgetNumber: styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${(props) =>
      props.negative ? props.theme.colors[25] : props.theme.colors[26]};
  `,

  WidgetButton: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
  `,
};
