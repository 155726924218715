import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import * as Styled from "./activateUser.styles";
import { connect } from "react-redux";
import { activateUser } from "../../redux/actions/user/activateUser.actions";
import l10n from "../../localization";
import LogoColumn from "../../components/LoginComponents/LogoColumn";
import Text from "../../components/Text";
import {
  LoginWrapper,
  FormWrapper,
} from "../../components/LoginComponents/LoginGlobal.styles";
import { isMobile } from "react-device-detect";

function ActivateUser(props) {
  useEffect(() => {
    props.activateUser(props.match.params.token);
    //eslint-disable-next-line
  }, []);

  const openLink = () => {
    if (isMobile) {
      window.location.href = "mojvodovodspapp://home";
      setTimeout(function () {
        window.location.href = "/login";
      }, 1000);
    } else {
      window.location.href = "/login";
    }
  };

  return (
    <LoginWrapper>
      <Container>
        <Row noGutters={true}>
          <Col md="6" xs="12">
            <LogoColumn></LogoColumn>
          </Col>
          <Col md="6" xs="12">
            <FormWrapper>
              {props.user.activateUser.loading && (
                <Styled.ContentWrap>
                  <Styled.ContentInner>
                    <Row>
                      <Col align="center">
                        <Styled.Icon className="far fa-tasks" name="loading" />
                      </Col>
                    </Row>
                    <Row>
                      <Col align="center" name="title">
                        <Text>{l10n.activateUser.inProgress}</Text>
                      </Col>
                    </Row>
                  </Styled.ContentInner>
                </Styled.ContentWrap>
              )}

              {props.user.activateUser.error && (
                <Styled.ContentWrap>
                  <Styled.ContentInner>
                    <Row>
                      <Col align="center">
                        <Styled.Icon
                          className="far fa-times-circle"
                          name="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col align="center" name="title">
                        <Text>{l10n.activateUser.error}</Text>
                      </Col>
                    </Row>
                  </Styled.ContentInner>
                </Styled.ContentWrap>
              )}

              {props.user.activateUser.success && (
                <Styled.ContentWrap>
                  <Styled.ContentInner>
                    <Row>
                      <Col align="center">
                        <Styled.Icon
                          className="far fa-check-circle"
                          name="success"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col align="center">
                        <Text>
                          {l10n.activateUser.success}
                          <br />
                          <Styled.Link onClick={() => openLink()}>
                            {l10n.activateUser.success2}
                          </Styled.Link>
                        </Text>
                      </Col>
                    </Row>
                  </Styled.ContentInner>
                </Styled.ContentWrap>
              )}
            </FormWrapper>
          </Col>
        </Row>
      </Container>
    </LoginWrapper>
  );
}

export default connect(({ user }) => ({ user }), { activateUser })(
  ActivateUser
);
