import styled from "styled-components";

export const Header = styled.div`
  background: ${(props) => props.theme.colors[0]};
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 13px 24px;
  min-height: 100px;
  margin: 0 0 20px;

  h1 {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[23]};
    margin: 0 0 5px;
  }

  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[18]};
  }
`;

export const WelcomeHeader = styled.div`
  background: ${(props) => props.theme.colors[0]};
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
  border-radius: 4px;
  padding: 13px 24px;
  margin: 0 0 20px;

  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[19]};
    margin: 0;
    padding: 0;
  }

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.colors[25]};
  }
`;

export const DetailedHeader = styled.div`
  font-size: 9px;
  text-align: left;
  margin-left: 50%;
`;
