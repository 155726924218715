import axios from "axios/index";

export const getUserMeters = (userId) => {
  return axios.get(`/user/${userId}/meter`);
};

export const newMeterReading = ({
  userId,
  meterSiteCode,
  meterCode,
  readingDate,
  value,
}) => {
  return axios.post(`/user/${userId}/meter-reading`, {
    meterSiteCode,
    meterCode,
    readingDate,
    value,
  });
};

export const meterReadingsByDay = ({ userId, meterCode, dateFrom, dateTo }) => {
  let baseUrl = `/user/${userId}/meter-readings-by-day?`;

  if (meterCode !== "all") {
    baseUrl += `MeterCode=${meterCode}&`;
  }

  return axios.get(`${baseUrl}DateFrom=${dateFrom}&DateTo=${dateTo}`);
};

export const enableMeterManualReading = ({ userId, meterId }) => {
  return axios.post(`/user/${userId}/meter/${meterId}/enable-manual-reading`, {
    enabled: true,
  });
};
