export const Types = {
  REGISTER_USER: "user/register_user",
  REGISTER_USER_SUCCESS: "user/register_user_success",
  REGISTER_USER_ERROR: "user/register_user_error",
  REGISTER_USER_RESET: "user/register_user_reset"
};

export const registerUser = data => ({
  type: Types.REGISTER_USER,
  payload: data
});

export const registerUserSuccess = () => ({
  type: Types.REGISTER_USER_SUCCESS
});

export const registerUserError = error => ({
  type: Types.REGISTER_USER_ERROR,
  payload: error
});

export const registerUserReset = () => ({
  type: Types.REGISTER_USER_RESET
});
