import React, { useState, useEffect } from "react";
import Styled from "./keksPayModal.styles";
import l10n from "../../localization";
import Modal from "../Modal";
import { Alert, Row, Col } from "reactstrap";
import Helpers from "../../helpers/helpers";
import {
  generateKeksQrCodeRequest,
  resetQrCode,
  payAllReferenceKeksRequest,
  checkTransactionStatusRequest,
  checkTransactionStatusReset,
} from "../../redux/actions/keks.actions";
import { getInvoices } from "../../redux/actions/invoice.actions";
import { connect } from "react-redux";

import logo from "../../assets/logo-vodovod-split.png";
import kekspayLogo from "../../assets/keks_logo.png";
import Loader from "../Loader";

const KeksPayModal = ({
  popupOpen,
  buttonLabel,
  meterCode,
  invoices,
  onClose,
  keks,
  keksTransaction,
  resetQrCode,
  billValue,
  billValueEur,
  ...props
}) => {
  // eslint-disable-next-line
  const [signatureReady, setSignatureReady] = useState(false);
  // eslint-disable-next-line
  const [invoice, setInvoice] = useState({});
  const [invoicesSum, setInvoicesSum] = useState(0);
  const [invoicesSumEur, setInvoicesSumEur] = useState(0);
  // eslint-disable-next-line
  const [all, setAll] = useState(false);
  const [transactionCheckInterval, setTransactionCheckInterval] =
    useState(null);
  const [paymentExecuted, setPaymentExecuted] = useState(null);

  useEffect(() => {
    const { success, checking } = keksTransaction;

    if (!checking) {
      setPaymentExecuted(success);
    }
    // eslint-disable-next-line
  }, [keksTransaction]);

  useEffect(() => {
    if (popupOpen) {
      if (invoices.length > 1) {
        setInvoicesSum(billValue);
        setInvoicesSumEur(billValueEur);
        props.payAllReferenceKeksRequest(meterCode);
      } else {
        props.generateKeksQrCodeRequest(invoices[0].invoiceId);
        setSignatureReady(true);
        setAll(false);
        setInvoice({
          invoiceId: invoices[0].invoiceId,
          invoiceValue: invoices[0].invoiceDebt,
          isPayed: invoices[0].isPayed,
          wsPaySignature: invoices[0].wsPaySignature,
        });
        checkPaymentStatus(invoices[0].invoiceId);

        if (invoices) {
          setInvoicesSum(invoices[0].invoiceDebt);
          setInvoicesSumEur(invoices[0].invoiceDebtEur);
        }
      }
    } else {
      clearInterval(transactionCheckInterval);
      setTransactionCheckInterval(null);
      setSignatureReady(false);
      resetQrCode();
    }

    //eslint-disable-next-line
  }, [popupOpen, invoices]);

  useEffect(() => {
    if (
      invoices.length > 1 &&
      keks.payAllResponse &&
      keks.payAllResponse.payReference &&
      billValue &&
      !keks.loading
    ) {
      props.generateKeksQrCodeRequest(
        keks.payAllResponse.payReference,
        billValue
      );
      checkPaymentStatus(keks.payAllResponse.payReference);
    }

    //eslint-disable-next-line
  }, [keks.payAllResponse]);

  const checkPaymentStatus = (invoiceId) => {
    clearInterval(transactionCheckInterval);
    setTransactionCheckInterval(
      setInterval(() => {
        props.checkTransactionStatusRequest(invoiceId);
      }, 1000)
    );
  };

  const closeModal = () => {
    clearInterval(transactionCheckInterval);
    setTransactionCheckInterval(null);
    props.checkTransactionStatusReset();
    onClose(paymentExecuted !== null);
  };

  return (
    <>
      {popupOpen && (
        <Modal
          open={popupOpen}
          title={l10n.payPopup.modalTitle}
          footer={true}
          closeButtonText={l10n.payPopup.closeButton}
          onClose={() => closeModal()}
        >
          {paymentExecuted === true ? (
            <Alert color="success">
              <b>{l10n.invoices.paymentSuccess}</b>
            </Alert>
          ) : (
            paymentExecuted === false && (
              <Alert color="danger">
                <b>{l10n.invoices.paymentError}</b>
              </Alert>
            )
          )}
          <>
            {process.env.REACT_APP_PAYMENT === "0" && (
              <Row>
                <Col>
                  <strong>
                    Poštovani korisnici aplikacije, izravno plaćanje računa
                    trenutno nije moguće.
                    <br />
                    Usluga će biti dostupna u najkraćem roku, o čemu ćemo Vas
                    izvjestiti porukom.
                  </strong>
                </Col>
              </Row>
            )}

            {process.env.REACT_APP_PAYMENT === "1" && (
              <>
                <Styled.ItemLine>
                  <Col>{l10n.payPopup.invoiceNumber}</Col>
                  <Col align="right">{l10n.payPopup.total}</Col>
                </Styled.ItemLine>
                {invoices &&
                  invoices.map((invoiceRow) => (
                    <Styled.ItemLine key={invoiceRow.invoiceId}>
                      <Col>{invoiceRow.invoiceId}</Col>
                      <Col align="right">
                        {Helpers.formatPrice(invoiceRow.invoiceDebt)}
                      </Col>
                    </Styled.ItemLine>
                  ))}
                <Row>
                  <Col />
                  <Col align="right">
                    {l10n.payPopup.total2}:{" "}
                    <strong>{Helpers.formatPrice(invoicesSum)}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Styled.KeksPayWrapper>
                      <Styled.KeksPayTextWrapper>
                        <Styled.KeksPayTitle>KEKS Pay</Styled.KeksPayTitle>
                        <Styled.KeksPaySubtitle>
                          Najbrže i bez naknada putem KEKS Pay aplikacije!
                        </Styled.KeksPaySubtitle>
                      </Styled.KeksPayTextWrapper>
                      <Styled.KeksPayLogo>
                        <img src={kekspayLogo} alt="KEKS Pay logo" />
                      </Styled.KeksPayLogo>
                    </Styled.KeksPayWrapper>

                    {keks.loading ? (
                      <Styled.KeksCode>
                        <Loader inline={true} />
                      </Styled.KeksCode>
                    ) : (
                      keks.loaded &&
                      keks.qrCode && (
                        <img
                          style={{ height: "auto", width: "175px" }}
                          src={keks.qrCode}
                          alt="KEKS Pay QR code"
                        />
                      )
                    )}
                  </Col>
                </Row>
                <p>
                  {l10n.payPopup.agree}{" "}
                  <a
                    href="https://www.vik-split.hr/Portals/0/docs/Uvjeti_portal/UVJETI_KORISTENJA_APLIKACIJE_v1_040521.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {l10n.payPopup.agreeTerms}
                  </a>{" "}
                  {l10n.payPopup.company}.
                </p>
                <hr />
                <Styled.logo src={logo} alt="Vodovod Split" />
                <Row>
                  <Col>
                    <p>
                      Vodovod i kanalizacija d.o.o. Split
                      <br />
                      OIB: 56826138353
                      <br />
                      MBS: 060160338
                      <br />
                      IBAN HR91 2407 0001 5003 2787 3
                    </p>
                  </Col>
                  <Col>
                    <p>
                      E-mail:{" "}
                      <a href="mailto:info@vik-split.hr">info@vik-split.hr</a>
                      <br />
                      {l10n.payPopup.phone}:{" "}
                      <a href="tel:021 545 900">021 545 900</a>
                    </p>
                  </Col>
                </Row>
              </>
            )}
          </>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    keks: state.keks,
    keksTransaction: state.keksTransaction,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generateKeksQrCodeRequest: (invoiceId, invoiceValue) =>
      dispatch(generateKeksQrCodeRequest(invoiceId, invoiceValue)),
    resetQrCode: () => dispatch(resetQrCode()),
    payAllReferenceKeksRequest: (meterCode) =>
      dispatch(payAllReferenceKeksRequest(meterCode)),
    checkTransactionStatusRequest: (invoiceId) =>
      dispatch(checkTransactionStatusRequest(invoiceId)),
    checkTransactionStatusReset: (invoiceId) =>
      dispatch(checkTransactionStatusReset(invoiceId)),
    getInvoices: () => dispatch(getInvoices()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KeksPayModal);
