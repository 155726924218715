import React from "react";
import { connect } from "react-redux";
import Styled from "./TableList.styles";
import MeterRow from "./_components/MeterRow";
import l10n from "../../../../localization";
import { isBrowser } from "react-device-detect";
import MobileMeterRows from "./_components/MobileMeterRows/MobileMeterRows";

const TableList = ({ meters, data, startDate, endDate }) => {
  const getMeter = (meterCode) => {
    if (!meters) return null;
    if (!meters.result) return null;

    const filtered = meters.result.filter(
      (meter) => meter.meterSiteCode === meterCode
    );

    return filtered.length > 0 ? filtered[0] : null;
  };

  const renderTable = (meter) => {
    if (isBrowser) {
      return (
        <Styled.Table>
          <thead>
            <tr border="1">
              <th>{l10n.reports.date}</th>
              <th>{l10n.reports.consumption}</th>
            </tr>
          </thead>
          <tbody>
            <MeterRow
              readings={meter.readings.slice().reverse()}
              startDate={startDate}
              endDate={endDate}
            />
          </tbody>
        </Styled.Table>
      );
    } else {
      return (
        <Styled.CardMobileTable>
          <MobileMeterRows
            readings={meter.readings.slice().reverse()}
            startDate={startDate}
            endDate={endDate}
          />
        </Styled.CardMobileTable>
      );
    }
  };

  return (
    <>
      {data && data.length > 0 ? (
        data.map((item, meterIndex) => (
          <Styled.Card key={`${item.meterCode}_${meterIndex}`}>
            <Styled.HeaderWrapper>
              <Styled.Header>
                <Styled.HeaderRow>
                  <Styled.HeaderTitle>
                    {l10n.reports.site_address}
                  </Styled.HeaderTitle>
                  <Styled.HeaderContent>
                    {getMeter(item.meterCode).siteAddress || "---"}
                  </Styled.HeaderContent>
                </Styled.HeaderRow>
                <Styled.HeaderRow>
                  <Styled.HeaderTitle>
                    {l10n.reports.meterCode}
                  </Styled.HeaderTitle>
                  <Styled.HeaderContent>{item.meterCode}</Styled.HeaderContent>
                </Styled.HeaderRow>
              </Styled.Header>
            </Styled.HeaderWrapper>

            {renderTable(item)}
          </Styled.Card>
        ))
      ) : (
        <p>{l10n.reports.noResults}</p>
      )}
    </>
  );
};

export default connect(({ meters }) => ({ meters }), null)(TableList);
