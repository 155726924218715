export const Types = {
  INVOICE_ERROR: "invoice/invoice_error",
  GET_INVOICES: "invoice/get_invoices",
  GET_INVOICES_SUCCESS: "invoice/get_invoices_success",
  GET_INVOICES_RESET: "invoice/get_invoices_reset",
  GET_INVOICE_PDF: "invoice/get_invoice_pdf",
  GET_INVOICE_PDF_SUCCESS: "invoice/get_invoice_pdf_success",
  GET_INVOICE_PDF_ERROR: "invoice/get_invoice_pdf_error",
  MARK_INVOICE_PAYED: "invoice/mark_invoice_payed",
  PAY_ALL_ONLINE: "invoices/pay_all_online",
};

export const getInvoicePdf = (invoiceId) => ({
  type: Types.GET_INVOICE_PDF,
  payload: { invoiceId },
});

export const getInvoicePdfSuccess = (invoiceId) => ({
  type: Types.GET_INVOICE_PDF_SUCCESS,
  payload: { invoiceId },
});

export const getInvoicePdfError = (invoiceId, error) => ({
  type: Types.GET_INVOICE_PDF_ERROR,
  payload: { invoiceId, error },
});

export const getInvoices = (isReversed, dateFrom, dateTo) => ({
  type: Types.GET_INVOICES,
  payload: { isReversed, dateFrom, dateTo },
});

export const getInvoicesSuccess = (invoices) => ({
  type: Types.GET_INVOICES_SUCCESS,
  payload: invoices,
});

export const getInvoicesReset = () => ({
  type: Types.GET_INVOICES_RESET,
});

export const markInvoiceAsPayed = (ShoppingCartId, WsPayOrderId) => ({
  type: Types.MARK_INVOICE_PAYED,
  payload: { ShoppingCartId, WsPayOrderId },
});

export const payAllOnline = (ShoppingCartId, WsPayOrderId) => ({
  type: Types.PAY_ALL_ONLINE,
  payload: { ShoppingCartId, WsPayOrderId },
});

export const invoiceError = ({ error }) => ({
  type: Types.INVOICE_ERROR,
  payload: {
    error,
  },
});
