import styled from "styled-components";
import { Modal, ModalBody } from "reactstrap";

export default {
  ModalHolder: styled(Modal)`
    max-width: 70vw;
    @media (max-width: 800px) {
      max-width: 95vw;
    }
  `,
  ModalB: styled(ModalBody)`
    flex-grow: 1;
    max-height: 70vh;
    overflow-y: scroll;
  `,
};
