import React from "react";
import * as Styled from "./ActivationSent.styles";
import { Container, Row, Col } from "reactstrap";
import Text from "../../components/Text";
import l10n from "../../localization";
import { Redirect } from "react-router-dom";
import {
  LoginWrapper,
  FormWrapper,
  GlobalLink,
} from "../../components/LoginComponents/LoginGlobal.styles";
import LogoColumn from "../../components/LoginComponents/LogoColumn";

function ActivationSent(props) {
  const email = props.match.params.email;
  if (!email) return <Redirect to="/login" />;

  return (
    <LoginWrapper>
      <Container>
        <Row noGutters={true}>
          <Col md="6" xs="12">
            <LogoColumn></LogoColumn>
          </Col>
          <Col md="6" xs="12">
            <FormWrapper>
              <Styled.ContentWrap>
                <Text type={"h2"}>{l10n.activationSent.activationTitle}</Text>
                <Text>
                  {l10n.activationSent.activationText}
                  <br />
                  <br />
                  <strong>{email}</strong>
                  <br />
                  <br />
                  {l10n.activationSent.activationText2}
                </Text>
                <GlobalLink to={"/login"}>
                  {l10n.activationSent.proceedToLogin}
                </GlobalLink>
              </Styled.ContentWrap>
            </FormWrapper>
          </Col>
        </Row>
      </Container>
    </LoginWrapper>
  );
}

export default ActivationSent;
