import React from "react";
import * as Styled from "./CallBlock.styles";
import l10n from "../../../../localization";
import { Row } from "reactstrap";

export default function CallBlock() {
  return (
    <Styled.Card>
      {/* <Styled.Header>{l10n.help.phoneContact}</Styled.Header> */}
      <Styled.Body>
        <Row>
          <Styled.BlockCol>
            <Styled.Title>{l10n.help.callBlock1}</Styled.Title>
            <Styled.link href="tel:+385(21)545900">
              Tel: (021) 545 900
            </Styled.link>
          </Styled.BlockCol>
        </Row>
      </Styled.Body>
    </Styled.Card>
  );
}