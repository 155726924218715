import React from "react";
import * as Styled from "./SideNavigation.styles";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import l10n from "../../../../../localization";
import moment from "moment/moment";
import renderIcon from "../../../../../components/Icons/Icons";

const SideNavigation = (props) => {
  const navigation = [
    {
      link: "/",
      icon: "IconDashboard",
      name: l10n.sideNavigation.dashboard,
      allowWithoutData: true,
      showAlways: false
    },
    {
      link: "/meters",
      icon: "IconBarometer",
      name: l10n.sideNavigation.meters,
      showAlways: false
    },
    {
      link: "/invoices",
      icon: "IconTable",
      name: l10n.sideNavigation.invoices,
      showAlways: false
    },
    {
      link: "/reports",
      icon: "IconChart",
      name: l10n.sideNavigation.reports,
      showAlways: false
    },
    // {
    //   link: "/send",
    //   icon: "IconAutoReader",
    //   name: l10n.sideNavigation.send,
    //   activeStroke: true
    // },
    {
      link: "/help",
      icon: "IconEmailSidebar",
      name: l10n.sideNavigation.help,
      showAlways: false
    },
    {
      link: "/info",
      icon: "IconHelpCircle",
      name: l10n.sideNavigation.info,
      showAlways: false
    },
    {
      link: "/profile",
      icon: "IconUser",
      name: l10n.sideNavigation.profile,
      showDivider: true,
      mobileOnly: true,
      showAlways: true
    },
    {
      link: "/profile/change-password",
      icon: "IconPassword",
      name: l10n.sideNavigation.changePassword,
      mobileOnly: true,
      showAlways: true
    },
    {
      link: "/logout",
      icon: "IconLogout",
      name: l10n.sideNavigation.logout,
      mobileOnly: true,
      showAlways: true
    },
  ];

  const renderItem = (item, index) => {
    return (
      <li key={index} className={item.mobileOnly ? "mobileOnly" : ""}>
        <NavLink
          to={item.link}
          exact
          onClick={props.onNavigationChange}
          className={item.showDivider ? "divider" : ""}
        >
          {renderIcon(item.icon)} <span>{item.name}</span>
        </NavLink>
      </li>
    );
  };

  return (
    <Styled.Nav>
      <ul>
        {navigation.map((navigationItem, index) => {
          if (
            (props.user.userData.data &&
              !props.user.userData.data.userSynced &&
              navigationItem.allowWithoutData === true) ||
            (props.user.userData.data && props.user.userData.data.userSynced) || (navigationItem.showAlways)
          ) {
            return renderItem(navigationItem, index);
          }

          return null;
        })}
      </ul>
      <Styled.Author>
        &copy; {moment().format("YYYY")} Vodovod Split
        <span>v{process.env.REACT_APP_VERSION}</span>
      </Styled.Author>
    </Styled.Nav>
  );
};

export default connect(({ user }) => ({ user }), {})(SideNavigation);
