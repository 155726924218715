import styled from "styled-components";
import { Col } from "reactstrap";

export const link = styled.a`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors[27]};

  &:hover {
    color: ${props => props.theme.colors[27]};
  }
`;

export const Title = styled.p`
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors[27]};
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors[27]};
`;

export const Header = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors[28]};
  padding: 20px 24px;
  border-bottom: 1px solid ${props => props.theme.colors[17]};
`;

export const Body = styled.div`
  padding: 20px 24px;
`;

export const Card = styled.div`
  margin: 0 0 20px 0;
  background-color: ${props => props.theme.colors[0]};
  border-radius: 4px;
  box-shadow: 0 6px 18px 0 ${props => props.theme.colors[16]};
`;

export const BlockCol = styled(Col).attrs({
  sm: 6,
  md: 3
})`
  margin-bottom: 20px;
`;
