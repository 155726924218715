import styled from "styled-components";

export const Form = styled.form`
  padding: 95px 80px 40px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  h2 {
    margin: 0 0 35px;
  }
`;

export const ConfirmationWrap = styled.div`
  padding: 95px 80px 40px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[0]};
    text-align: center;
    margin: 0 0 35px;
  }

  p {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[0]};
    text-align: center;
  }

  a {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    text-align: center;
  }
`;

export const Instructions = styled.p`
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[0]};
  padding: 0 0 0 15px;
`;

export const Link = styled.div`
  font-family: Oswald;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[32]};
  cursor: pointer;
  align-self: center;

  &:hover {
    color: ${(props) => props.theme.colors[32]};
  }
`;
