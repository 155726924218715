import React from "react";
import moment from "moment";

export default ({ readings }) => {
  if (!readings) return null;
  if (readings.length <= 0) return null;

  return readings.map((meterReading, index) => (
    <tr key={`${meterReading.date}_${index}`}>
      <td>{`${moment(meterReading.dateFrom).format("DD.MM.YYYY")} - ${moment(
        meterReading.dateTo
      ).format("DD.MM.YYYY")}`}</td>
      <td>{meterReading.consumption}</td>
    </tr>
  ));
};
