import React, { useState, useEffect } from "react";
import Styled from "./PayPopup.styles";
import l10n from "../../localization";
import Modal from "../Modal";
import { Row, Col } from "reactstrap";
import Helpers from "../../helpers/helpers";
import { getPayAllSignature } from "../../redux/actions/payAll.actions";
import { connect } from "react-redux";

import maestro from "../../assets/maestro50.gif";
import mastercard from "../../assets/MasterCard50.gif";
import verifiedvisa from "../../assets/visa-secure.png";
import visa from "../../assets/Visa50.gif";
import wspaylogo from "../../assets/wsPayWebSecureLogo-118x50-transparent.png";
import mastercardsecure from "../../assets/mastercard-identity-check.png";
import wspay from "../../assets/wspay.png";
import logo from "../../assets/logo-vodovod-split.png";

const PayPopup = ({
  popupOpen,
  buttonLabel,
  meterCode,
  invoices,
  onClose,
  ...props
}) => {
  // eslint-disable-next-line
  const [paymentDisabled, setPaymentDisabled] = useState(true);
  // eslint-disable-next-line
  const [signatureReady, setSignatureReady] = useState(false);
  // eslint-disable-next-line
  const [invoice, setInvoice] = useState({});
  const [invoicesSum, setInvoicesSum] = useState(0);
  // eslint-disable-next-line
  const [all, setAll] = useState(false);

  useEffect(() => {
    if (popupOpen) {
      if (invoices.length > 1) {
        props.getPayAllSignature(meterCode);
      } else {
        setSignatureReady(true);
        setAll(false);
        setInvoice({
          invoiceId: invoices[0].invoiceId,
          invoiceValue: invoices[0].invoiceDebt,
          isPayed: invoices[0].isPayed,
          wsPaySignature: invoices[0].wsPaySignature,
        });
      }

      if (invoices) {
        setInvoicesSum(
          invoices.reduce(function (a, b) {
            return a + b["invoiceDebt"];
          }, 0)
        );
      }
    } else {
      setSignatureReady(false);
    }

    //eslint-disable-next-line
  }, [popupOpen, invoices]);

  useEffect(() => {
    if (props.payAll.success) {
      setSignatureReady(true);
      setAll(true);
      setInvoice({
        invoiceId: props.payAll.data.shoppingCartId,
        invoiceValue: props.payAll.data.value,
        isPayed: "Open",
        wsPaySignature: props.payAll.data.wsPaySignature,
      });
    }
    //eslint-disable-next-line
  }, [props.payAll]);

  const acceptConditions = (e) => {
    setPaymentDisabled(!e.target.checked);
  };

  return (
    <>
      {popupOpen && (
        <Modal
          open={popupOpen}
          title={l10n.payPopup.modalTitle}
          footer={true}
          closeButtonText={l10n.payPopup.closeButton}
          onClose={() => onClose()}
        >
          {process.env.REACT_APP_PAYMENT === "0" && (
            <Row>
              <Col>
                <strong>
                  Poštovani korisnici aplikacije, izravno plaćanje računa
                  trenutno nije moguće.
                  <br />
                  Usluga će biti dostupna u najkraćem roku, o čemu ćemo Vas
                  izvjestiti porukom.
                </strong>
              </Col>
            </Row>
          )}
          {process.env.REACT_APP_PAYMENT === "1" && (
            <>
              <Styled.ItemLine>
                <Col>{l10n.payPopup.invoiceNumber}</Col>
                <Col align="right">{l10n.payPopup.total}</Col>
              </Styled.ItemLine>
              {invoices &&
                invoices.map((invoiceRow) => (
                  <Styled.ItemLine key={invoiceRow.invoiceId}>
                    <Col>{invoiceRow.invoiceId}</Col>
                    <Col align="right">
                      {Helpers.formatPrice(invoiceRow.invoiceDebt)}
                    </Col>
                  </Styled.ItemLine>
                ))}
              <Row>
                <Col />
                <Col align="right">
                  {l10n.payPopup.total2}:{" "}
                  <strong>{Helpers.formatPrice(invoicesSum)}</strong>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Styled.checkbox
                    type="checkbox"
                    name="termsAndConditions"
                    id="termsAndConditions"
                    onClick={acceptConditions}
                  />

                  <label htmlFor="termsAndConditions">
                    {l10n.payPopup.iAgree}{" "}
                    <a
                      href="https://www.google.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {l10n.payPopup.terms}
                    </a>
                  </label>
                </Col>
              </Row>

              {
                //Commented out the PAY button -> should be enabled later on
              }
              {/* <GatewayWspayForm
                loading={props.payAll.loading}
                invoice={invoice}
                disabled={paymentDisabled || !signatureReady}
                all={all}
              /> */}

              <div
                dangerouslySetInnerHTML={{ __html: l10n.payPopup.withClick }}
              />
              <Row>
                <Col>
                  <Styled.CardHolder>
                    <a
                      href="https://www.visa.com.hr/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={visa} alt="visa" />
                    </a>
                    <a
                      href="https://www.mastercard.com/hr/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={mastercard} alt="mastercard" />
                    </a>
                    <a
                      href="https://www.maestrocard.com/hr/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={maestro} alt="maestro" />
                    </a>
                    <a
                      href="https://www.wspay.info/mastercard-securecode.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={mastercardsecure} alt="mastercard secure" />
                    </a>
                    <a
                      href="https://www.wspay.info/verified-by-visa.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={verifiedvisa} alt="visa" />
                    </a>
                    <a
                      href="https://www.wspay.info"
                      title="WSpay - Web Secure Payment Gateway"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="WSpay - Web Secure Payment Gateway"
                        src={wspaylogo}
                        border="0"
                      />
                    </a>
                  </Styled.CardHolder>
                </Col>
              </Row>
              <p>
                {l10n.payPopup.agree}{" "}
                <a
                  href="https://www.google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {l10n.payPopup.agreeTerms}
                </a>{" "}
                {l10n.payPopup.company}.
              </p>
              <p style={{ margin: "0 0 10px 0" }}>
                <b>{l10n.payPopup.payCardsTitle}</b>
              </p>
              <p>{l10n.payPopup.payCardsDescription}</p>
              <p style={{ margin: "0 0 10px 0" }}>
                <b>{l10n.payPopup.payCardsTitleExtra}</b>
              </p>
              <p>{l10n.payPopup.payCardsDescriptionExtra}</p>
              <Row>
                <Col align="right">
                  <a
                    href="https://www.wspay.si"
                    title="WSPay - Web Secure Payment Gateway"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={wspay} alt="wspay" />
                  </a>
                </Col>
              </Row>
              <hr />
              <Styled.logo src={logo} alt="Vodovod Split" />
              <p>{l10n.payPopup.disclaimer}</p>
              <Row>
                <Col>
                  <p>
                    Vodovod i kanalizacija d.o.o. Split
                    <br />
                    OIB: 56826138353
                    <br />
                    MBS: 060160338
                    <br />
                    IBAN HR91 2407 0001 5003 2787 3
                  </p>
                </Col>
                <Col>
                  <p>
                    E-mail:{" "}
                    <a href="mailto:info@vik-split.hr">info@vik-split.hr</a>
                    <br />
                    {l10n.payPopup.phone}:{" "}
                    <a href="tel:021 546 100">021 546 100</a>
                  </p>
                </Col>
              </Row>
            </>
          )}
        </Modal>
      )}
    </>
  );
};
export default connect(({ payAll }) => ({ payAll }), { getPayAllSignature })(
  PayPopup
);
