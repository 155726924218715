import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
  activateUserSuccess,
  activateUserError,
  Types
} from "../../actions/user/activateUser.actions";

import * as api from "../../api/user.api";

function* activateUser({ payload }) {
  try {
    yield call(api.activateUser, payload.token);
    yield put(activateUserSuccess());
  } catch (err) {
    yield put(activateUserError(err.response.data.Messages));
  }
}

function* watchActivateUser() {
  yield takeLatest(Types.ACTIVATE_USER, activateUser);
}

export default [fork(watchActivateUser)];
