import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { logoutUser } from "../../redux/actions/user/user.actions";
import { connect } from "react-redux";

function Logout(props) {
  useEffect(() => {
    props.logoutUser();
  });

  return <Redirect to={"/login"} />;
}

export default connect(
  null,
  { logoutUser }
)(Logout);
