import React from "react";
import * as Styled from "./LogoColumn.styles";
import LogoImage from "../../assets/logo-vodovod-split.jpg";
import LogoImage2 from "../../assets/logo-vodovod-split.png";
import GPlayStoreImage from "../../assets/gplay_store.png";

import l10n from "../../localization";

function LogoColumn() {
  return (
    <Styled.Info>
      <Styled.Logo>
        <img src={LogoImage} alt="Vodovod i kanalizacija d.o.o. Split" />
      </Styled.Logo>

      <Styled.Spacer />

      <Styled.Footer>
        <Styled.FooterLogo
          src={LogoImage2}
          alt="Vodovod i kanalizacija d.o.o. Split"
        />
        <Styled.FooterTitle>{l10n.login.infoTitle}</Styled.FooterTitle>
      </Styled.Footer>

      <Styled.Stores>
          <Styled.StoreItem>
            <a
              href="https://play.google.com/store/apps/details?id=com.splitvodovodimobile"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GPlayStoreImage} alt="Google Play Store" />
            </a>
          </Styled.StoreItem>
        </Styled.Stores>
        
    </Styled.Info>
  );
}

export default LogoColumn;
