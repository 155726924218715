import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
  registerUserSuccess,
  registerUserError,
  Types,
} from "../../actions/user/registerUser.actions";
import * as api from "../../api/user.api";

function* registerUser({ payload }) {
  try {
    yield call(api.registerUser, payload);
    yield put(registerUserSuccess());
  } catch (err) {
    yield put(registerUserError(err.response.data.Messages));
  }
}

function* watchRegisterRequest() {
  yield takeLatest(Types.REGISTER_USER, registerUser);
}

export default [fork(watchRegisterRequest)];
