import React from "react";
import * as Styled from "./HeaderLink.styles";
import l10n from "../../../../../localization";
import { withRouter } from "react-router-dom";

const HeaderLink = (props) => {
  const pathnames = props.location.pathname.split("/");
  let pathname = "";

  if (pathnames.length > 1) {
    pathname = pathnames[pathnames.length - 1];
    switch (pathname) {
      case "":
        pathname = "dashboard";
        break;
      case "change-password":
        pathname = "changePassword";
        break;
      default: break;
    }
  }

  return (
    <Styled.LinkWrap>
      {l10n.sideNavigation[pathname]}
    </Styled.LinkWrap>
  );
}

export default withRouter(HeaderLink);
