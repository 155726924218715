import styled, { css } from "styled-components";
import { Card as RCard, Row } from "reactstrap";

export const InvoiceRow = styled(Row).attrs({})`
  margin-bottom: 15px;

  button {
    padding: 8px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors[0]};
    background-color: ${(props) => props.theme.colors[21]};
    transition: 0.2s background-color ease-in;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[29]} !important;
    }
  }
`;

export const InvoiceRowKey = styled.p`
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const InvoiceRowStatus = styled.p`
  color: ${(props) => props.theme.colors[25]};
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const InvoiceRowValue = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const RightWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;

  > svg {
    height: 26px;
    width: 26px;
  }
`;

export const MoreInfoWrapper = styled.div`
  align-items: center;
  display: flex;

  p {
    margin: 0;
    margin-right: 10px;
  }
`;

export const Card = styled(RCard)`
  margin: 0 0 25px 0;
`;

export const Month = styled.p`
  margin: 0;
  padding: 0;
  text-transform: capitalize;
`;

export const ActionHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const PriceTag = styled.span`
  color: ${(props) => props.theme.colors[2]};
  ${(props) =>
    props.status === "Open" &&
    css`
      color: ${props.theme.colors[8]};
    `};
`;

export const KeksPayLogo = styled.div`
  img {
    height: 26px;
    width: auto;
    margin-right: 2px;
  }
`;
